import { useContext, useState, useEffect } from 'react'
import { baseURL } from '../config'
import axios from 'axios'
import AuthContext from '../Context/AuthContext'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router';
import { MachineOrderFormValidation } from '../utils/MachineOrderFormValidation'
import UseEditTemplate from '../Components/UseEditTemplate'

function MachineOrderEdit() {
  const [fetchingTemplateDetails, setFetchingTemplateDetails] = useState(true)
  const [templateDetails, setTemplateDetails] = useState([])
  const [originalTemplateDetails, setOriginalTemplateDetails] = useState([])
  const { token } = useContext(AuthContext);
  const [templateModelDesc, setTemplateModelDesc] = useState('');
  const [selectedAssembly, setSelectedAssembly] = useState([])
  const [originalSelectedAssembly, setOriginalSelectedAssembly] = useState([])
  const [originalCustomerData, setOriginalCustomerData] = useState({
    account_number: '',
    name: '',
    company_name: '',
    primary_contact: '',
    primary_email: '',
    accounting_contact: '',
    accounting_email: '',
    bill_to: '',
    ship_to: '',
    others: '',
    shipment_date: '',
  })
  const [originalModsData, setOriginalModsData] = useState({
    sales_order_number: '',
    serial_number: '',
    customer_po: '',
  })
  const { assembly_id } = useParams();
  const [assignedQR, setAssignedQR] = useState()
  const [qr, setQR] = useState('')
  const [accountNumberOptions, setAccountNumberOptions] = useState([])
  const [savingMachineOrders, setSavingMachineOrders] = useState(false)
  const navigate = useNavigate()
  const [mods_data, setMods_data] = useState({
    sales_order_number: '',
    serial_number: '',
    customer_po: '',
  });
  const [customer_data, setCustomer_data] = useState({
    account_number: '',
    name: '',
    company_name: '',
    primary_contact: '',
    primary_email: '',
    accounting_contact: '',
    accounting_email: '',
    bill_to: '',
    ship_to: '',
    others: '',
    shipment_date: '',
  });
  const [validateFields, setValidateFields] = useState();

  const saveEditMachineOrder = () => {
    let accountNumberError
    if (!originalCustomerData['account_number']) {
      accountNumberError = false
    } else if (customer_data['account_number'] && accountNumberOptions.includes(customer_data['account_number'])) {
      accountNumberError = false
    } else {
      accountNumberError = true
    }
    const [isAnyEmptyFields, validateFieldsCopy] = MachineOrderFormValidation(qr, mods_data, customer_data, validateFields, accountNumberError);
    setValidateFields(validateFieldsCopy)
    if (isAnyEmptyFields) {
      const tableElement = document.getElementById('OrderForn');
      tableElement.scrollIntoView({ behavior: "smooth" });
    } else {
      const createMODS = async () => {
        try {
          setSavingMachineOrders(true)
          let modifiedExistingCustomer = null;

          if (originalCustomerData['account_number'] && (originalCustomerData['account_number'] !== customer_data['account_number'])) {
            modifiedExistingCustomer = customer_data['_id'];
            delete customer_data._id;
          }

          // Construct the payload dynamically
          let payload;

          if (modifiedExistingCustomer) {
            payload = {
              mods_data: mods_data,
              customer_data: customer_data,
              assembly_list: selectedAssembly,
              customer_id: modifiedExistingCustomer
            }
          } else {
            payload = {
              mods_data: mods_data,
              customer_data: customer_data,
              assembly_list: selectedAssembly,
            }
          }

          const response = await axios.put(`${baseURL}/mods/modifyMods/${assembly_id}`,
            payload,
            {headers: {
              "ngrok-skip-browser-warning":"any",
              "x-access-token": token
              }
            }
          );
          if (response.status === 200){
            toast.success('Machine Order Created Successfully')
            navigate('/machine_orders')
          }
        } catch (error) {
          toast.error('Something Went Wrong, Template Not Updated')
        } finally {
          setSavingMachineOrders(false)
        }
      }
      createMODS()
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingTemplateDetails(true);
        const response = await axios.get(`${baseURL}/mods/getModsById/${assembly_id}`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "Content-Type": "multipart/form-data",
            "x-access-token": token
          },
          params: {
            mods_id: assembly_id
          }
        });
        // delete response.data.data.customerData._id;
        delete response.data.data.customerData.__v;
        delete response.data.data.customerData.created_at;
        delete response.data.data.customerData.updated_at;
        setCustomer_data(response.data.data.customerData);
        delete response.data.data.modsData.model_number
        delete response.data.data.modsData.created_at
        setMods_data(response.data.data.modsData);
        setOriginalCustomerData(response.data.data.customerData)
        setOriginalModsData(response.data.data.modsData)
        setAssignedQR(response.data.data.qrData)
        setQR(response.data.data.qrData._id);
        let _assemblyData = response.data.data.assemblyData
        setTemplateDetails(_assemblyData);
        setOriginalTemplateDetails(_assemblyData);
        setTemplateModelDesc(response.data.data.templateData);
        const ids = Object.keys(_assemblyData).flatMap(key => (_assemblyData[key]).map((item) => (item._id)));
        setSelectedAssembly(ids)
        setOriginalSelectedAssembly(ids)
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setFetchingTemplateDetails(false);
      }
    };

    fetchData();
  }, [assembly_id, token]);

  useEffect(() => {
    if (!fetchingTemplateDetails) {
        setValidateFields(prev => {
            const result = MachineOrderFormValidation(qr, mods_data, customer_data, prev);
            return result[1];
        });
    }
  }, [qr, mods_data, customer_data, fetchingTemplateDetails]);

  return (
    <UseEditTemplate
      savingMachineOrders = {savingMachineOrders}
      originalSelectedAssembly = {originalSelectedAssembly}
      templateModelDesc = {templateModelDesc}
      templateDetails={templateDetails}
      setTemplateDetails={setTemplateDetails}
      originalTemplateDetails = {originalTemplateDetails}
      fetchingTemplateDetails = {fetchingTemplateDetails}
      accountNumberOptions = {accountNumberOptions}
      setAccountNumberOptions = {setAccountNumberOptions}
      mods_data = {mods_data}
      setMods_data = {setMods_data}
      customer_data = {customer_data}
      setCustomer_data = {setCustomer_data}
      assignedQR = {assignedQR}
      setAssignedQR = {setAssignedQR}
      validateFields = {validateFields}
      saveChanges = {saveEditMachineOrder}
      selectedAssembly = {selectedAssembly}
      setSelectedAssembly = {setSelectedAssembly}
      originalCustomerData={originalCustomerData}
      originalModsData={originalModsData}
      qr = {qr}
      setQR = {setQR}
      editOrder = {true}
    />
  )
}

export default MachineOrderEdit