import LoadingAnimation from "./LoadingAnimation";

const ButtonOutline = ({ buttonText, type = "button", isLoading = false, disabled = false, onClick, className, loadingText }) => {
  return (
    <button
      type={type}
      disabled={isLoading || disabled}
      onClick={onClick}
      className={`min-w-[150px] h-10 md:h-12 px-2 md:px-4 py-2 md:py-3 text-sm md:text-base rounded-md font-medium text-orange-400 ring-1 ring-inset ring-orange-400 hover:bg-gray-50 disabled:bg-gray-200 whitespace-nowrap ${(isLoading || disabled) ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
    >
      {isLoading ? <LoadingAnimation loadingText={loadingText}/> : buttonText}
    </button>
  );
};

export default ButtonOutline;