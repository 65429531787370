import { useContext, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { NotificationIcon, ProfileCircle } from '../Components/Icons';
import { ProfileCircle } from '../Components/Icons';
import AuthContext from '../Context/AuthContext';

function Navbar() {
  const [profileClick, setProfileClick] = useState(false);
  const { logout, setCreateUserPopup } = useContext(AuthContext);

  return (
    <nav className='bg-white shadow-sm z-[10000]'>
      <div className="px-2 sm:px-6">
        <div className="justify-end relative flex h-[65px] md:h-[80px] items-center ml:justify-between">
          <div className="hidden text-xl font-semibold text-gray-500 cursor-pointer ml:block">
            Welcome Admin
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* <button
              type="button"
              className="relative rounded-full p-1 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">View notifications</span>
              <NotificationIcon />
            </button> */}
            <div className='ml-3'
              tabIndex={0}
              onClick={() => {setProfileClick(!profileClick)}}
            >
              <button 
                className="flex rounded-full focus:ring focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <ProfileCircle />
              </button>
              {profileClick &&
              <>
                <div className='fixed inset-0' onClick={() => {setProfileClick(false)}}/>
                <ul className='absolute h-auto right-0 mt-2 z-[1000] w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  {/* <li>
                      <Link to="/"
                        onClick={() => setProfileClick(false)}
                        className= 'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200'
                      >
                        Your Profile
                      </Link>
                  </li> */}
                  <li>
                      <button
                        className= 'block px-4 py-2 text-sm w-full text-start text-gray-700 cursor-pointer hover:bg-gray-200'
                        onClick={() => setCreateUserPopup(true)}
                      >
                        Create User
                      </button>
                  </li>
                  <li>
                      <button
                        className= 'block px-4 py-2 text-sm w-full text-start text-gray-700 cursor-pointer hover:bg-gray-200'
                        onClick={logout}
                      >
                        Sign out
                      </button>
                  </li>
                </ul>
              </>}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;