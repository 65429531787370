import InputField from "../Components/InputField";
import TextField from "../Components/TextField";
import ButtonOutline from "./ButtonOutline";
import ButtonSolid from "./ButtonSolid";
import { useContext, useEffect, useState } from "react";
import { baseURL } from "../config";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import LoadingAnimation from "./LoadingAnimation";
import Popup from "./Popup";
import InputWithSuggestion from "./InputWithSuggestion";
import toast from "react-hot-toast";

function CustomerDetailForm({
  loadingData,
  mods_data,
  customer_data,
  setCustomer_data,
  accountNumberOptions,
  setAccountNumberOptions,
  setMods_data,
  validateFields,
  editOrder,
  setDisableReset,
  setAssignedQR,
  assignedQR,
  setQR,
}) {
  const [listedQR, setListedQR] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token, logout } = useContext(AuthContext);
  const [assignQRpopup, setAssignQRpopup] = useState(false);
  const [createQRpopup, setCreateQRpopup] = useState(false);
  const [selectedQR, setSelectedQR] = useState();

  const [accountNumberSelected, setAccountNumberSelected] = useState("");
  const [customerIdSelected, setCustomerIdSelected] = useState("");

  const [fetchingQR, setFetchingQR] = useState(false);
  const [customerNameOption, setCustomerNameOption] = useState([]);
  const [companyNameOption, setCompanyNameOption] = useState([]);
  const [generatingQR, setGeneratingQR] = useState(false);
  const today = new Date().toISOString().slice(0, 10);
  const [manualSerialNoInput, setManualSerialNoInput] = useState("");
  const [manualSerialNoValue, setManualSerialNoValue] = useState("000000");
  const [serialNoError, setSerialNoError] = useState("");
  const [generatingQRfromSiNo, setGeneratingQRfromSiNo] = useState(false);
  const [autoFillUsed, setAutoFillUsed] = useState();

  const handleModsDetail = (e) => {
    const { name, value } = e.target;
    setMods_data({ ...mods_data, [name]: value });
    setDisableReset(false);
  };

  const handleManualSerialNo = (event) => {
    const userInput = event.target.value;
    const digitsOnly = userInput.replace(/\D/g, '');
    if (digitsOnly.length <= 6) {
      setSerialNoError("");
      setManualSerialNoInput(digitsOnly);
      const newValue = digitsOnly.padStart(6, '0');
      setManualSerialNoValue(newValue);
    }
  };

  const handleCustomerDetail = (e) => {
    const { name, value } = e.target;
    setCustomer_data({ ...customer_data, [name]: value });
    setDisableReset(false);
  };

  const handleAssignQR = () => {
    setAssignQRpopup(false);
    setAssignedQR(selectedQR);
    setQR(selectedQR?.qr_id);
    const { name, value } = {
      name: "serial_number",
      value: selectedQR?.qrString,
    };
    setMods_data({ ...mods_data, [name]: value });
  };

  const handleChangeQR = () => {
    setSelectedQR();
    setAssignedQR();
    setQR();
    const { name, value } = { name: "serial_number", value: "" };
    setMods_data({ ...mods_data, [name]: value });
  };

  const closeAsignQRpopup = () => {
    setSelectedQR(undefined);
    setAssignQRpopup(false);
  };

  const generateNewQR = () => {
    if (!manualSerialNoInput || manualSerialNoInput <= 0) {
      setSerialNoError("Add a valid serial number");
    } else {
      const GenerateQRfromSiNo = async () => {
        try {
          setGeneratingQRfromSiNo(true);
          const response = await axios.post(
            `${baseURL}/qr/generateQRWithNumber`,
            { serial_number: manualSerialNoValue }, // Pass serial_number in the request body
            {
              headers: {
                "ngrok-skip-browser-warning": "any",
                "Content-Type": "application/json", // Use application/json for JSON data
                "x-access-token": token,
              },
            }
          );
          if (response.status === 200) {
            setSelectedQR(response.data);
            setAssignedQR(response.data);
            setQR(response?.data?.qr_id);
            const { name, value } = {
              name: "serial_number",
              value: response?.data?.qrString,
            };
            setMods_data({ ...mods_data, [name]: value });
            cancelSerialNoInput();
          }
        } catch (error) {
          if (error.response && error.response.status === 400) {
            setSerialNoError(
              "QR with this serial number has already been assigned."
            );
          } else {
            toast.error(`Error: ${error.message}`);
          }
        } finally {
          setGeneratingQRfromSiNo(false);
        }
      };

      GenerateQRfromSiNo();
    }
  };

  const fetchQRlist = async () => {
    try {
      setFetchingQR(true);
      const response = await axios.get(`${baseURL}/qr/getQR`, {
        headers: {
          "ngrok-skip-browser-warning": "any",
          "x-access-token": token,
        },
        params: {
          page: 1,
          limit: 30,
          filter: "Unassigned",
          search: "",
        },
      });
      if (response?.status === 204) {
        generateQR();
        setListedQR("no data");
      } else {
        const Qr = response.data.data?.qrCodesByDate;
        const QRList = Qr.flatMap((dateObj) => dateObj.qrCodes);
        setListedQR(QRList);
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response?.status === 401) {
        logout();
      }
    } finally {
      setFetchingQR(false);
    }
  };

  const handleAssignQRpopup = () => {
    setAssignQRpopup(true);
    fetchQRlist();
  };

  const cancelSerialNoInput = () => {
    setManualSerialNoInput("");
    setManualSerialNoValue("");
    setSerialNoError("");
    setCreateQRpopup(false);
  };

  useEffect(() => {
    const fetchAccountNumber = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseURL}/mods/getAccountNumbers`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "x-access-token": token,
          },
        });

        setAccountNumberOptions(response.data.data);
        if (response?.status === 204) {
          setAccountNumberOptions([]);
        }
      } catch (error) {
        console.error("Error:", error);
        if (error.response?.status === 401) {
          logout();
        }
        if (!error.response) {
          toast.error("Network error:", error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    const fetchCustomerName = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseURL}/mods/getCustomerNames`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "x-access-token": token,
          },
        });
        setCustomerNameOption(response.data.data);
      } catch (error) {
        console.error("Error:", error);
        if (!error.response) {
          toast.error("Network error:", error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    const fetchCompanyName = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseURL}/mods/getCompanyNames`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "x-access-token": token,
          },
        });
        setCompanyNameOption(response.data.data.companyData);
      } catch (error) {
        console.error("Error:", error);
        if (!error.response) {
          toast.error("Network error:", error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAccountNumber();
    fetchCustomerName();
    fetchCompanyName();
  }, [logout, setAccountNumberOptions, token]);

  const generateQR = async (e) => {
    try {
      setGeneratingQR(true);
      const response = await axios.post(
        `${baseURL}/qr/generateQR`,
        { count: 1 },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      if (response.status === 200) {
        setSelectedQR(response.data.qrCodes["0"]);
        fetchQRlist();
        // setAssignedQR(response.data.qrCodes['0'])
        // setQR(response.data.qrCodes['0'].qr_id);
        // const { name, value } = { name: 'serial_number', value: response.data.qrCodes['0'].qrString };
        // setMods_data({ ...mods_data, [name]: value });
      }
    } catch (error) {
      toast.error("Error", error);
    } finally {
      setGeneratingQR(false);
    }
  };

  const resetCustomerDetails = () => {
    setCustomer_data({
      account_number: "",
      name: "",
      company_name: "",
      primary_contact: "",
      primary_email: "",
      accounting_contact: "",
      accounting_email: "",
      bill_to: "",
      ship_to: "",
      others: "",
      shipment_date: "",
    });
    setDisableReset(false);
  };

  useEffect(() => {
    const fetchCustomerDataForAccountNumber = async (accountNumberSelected) => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseURL}/mods/getCustomerInfoByAccountNumber`,
          {
            headers: {
              "ngrok-skip-browser-warning": "any",
              "x-access-token": token,
            },
            params: {
              account_number: accountNumberSelected,
            },
          }
        );
        const customerData = response.data.data.customerData;
        // Delete keys if they exist
        // delete customerData._id;
        delete customerData.__v;
        delete customerData.created_at;
        delete customerData.updated_at;
        delete customerData.shipment_date;

        setCustomer_data(customerData);
        setDisableReset(false);
      } catch (error) {
        console.error("Error:", error);
        if (error.response?.status === 401) {
          logout();
        }
      } finally {
        setLoading(false);
      }
    };

    if (accountNumberSelected) {
      fetchCustomerDataForAccountNumber(accountNumberSelected);
    }
  }, [accountNumberSelected, logout, token, setCustomer_data, setDisableReset]);

  useEffect(() => {
    const fetchCustomerDataForCustomerName = async (customerId) => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${baseURL}/mods/getCustomerInfoById/customer_id=${customerId}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "any",
              "x-access-token": token,
            },
            params: {
              customer_id: customerId,
            },
          }
        );
        const customerData = response.data.data.customerData;
        // Delete keys if they exist
        // delete customerData._id;
        delete customerData.__v;
        delete customerData.created_at;
        delete customerData.updated_at;
        delete customerData.shipment_date;

        setCustomer_data(customerData);
        setDisableReset(false);
      } catch (error) {
        console.error("Error:", error);
        if (error.response?.status === 401) {
          logout();
        }
      } finally {
        setLoading(false);
      }
    };

    if (customerIdSelected) {
      fetchCustomerDataForCustomerName(customerIdSelected["_id"]);
    }
  }, [customerIdSelected, logout, token, setCustomer_data, setDisableReset]);

  return (
    <div>
      <form className="bg-white relative shadow-md rounded-lg mb-5 w-full overflow-hidden pt-6 px-2 md:pl-6 md:pr-4 pb-3">
        <div className="flex items-center w-full">
          {assignedQR ? (
            <div className="flex flex-col h-[200px] mb-3">
              <div
                className={`min-w-[171px] min-h-[171px] border ${
                  editOrder ? "cursor-not-allowed" : "cursor-default"
                } border-gray-300 bg-gray-100 rounded-lg p-2 flex flex-col justify-center items-center`}
              >
                <img
                  src={`data:image/png;base64,${assignedQR.base64_string}`}
                  alt={`Assigned QR Code`}
                  width="150px"
                  height="150px"
                />
              </div>
              {editOrder ? (
                <></>
              ) : (
                <p
                  onClick={() => {
                    handleChangeQR();
                  }}
                  className="text-center text-orange-400 mt-2 cursor-pointer"
                >
                  Change QR Code
                </p>
              )}
            </div>
          ) : (
            <div>
              <div className=" w-fit h-[200px] mb-3">
                <div className="min-w-[171px] min-h-[171px] border border-gray-300 bg-gray-100 rounded-lg p-2 flex flex-col justify-center items-center">
                  <ButtonOutline
                    disabled={loadingData || editOrder}
                    onClick={() => setCreateQRpopup(true)}
                    isLoading={generatingQR}
                    loadingText={"Creating"}
                    buttonText="Create QR code"
                  />
                  <p className="text-center py-2">Or</p>
                  <ButtonSolid
                    disabled={loadingData || editOrder || generatingQR}
                    buttonText="Assign QR code"
                    isLoading={fetchingQR}
                    loadingText={"Fetching"}
                    onClick={handleAssignQRpopup}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="pl-2 md:pl-0 w-full">
            <p className="md:pl-6 font-medium text-lg mb-3">MODs Details</p>
            <div className="md:pl-4">
              <div className="sm:flex w-full mb-3 border-b border-dashed">
                <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
                  <InputField
                    disabled={true}
                    placeholder="Assign or Create QR"
                    labelText="Serial #"
                    name="serial_number"
                    value={mods_data?.serial_number}
                    readOnly={true}
                    errorMessage={validateFields?.qr}
                  />
                </div>
                <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
                  <InputField
                    disabled={loadingData}
                    placeholder="Enter Sales Order Number"
                    className={"uppercase"}
                    labelText="Sales Order #"
                    name="sales_order_number"
                    value={mods_data?.sales_order_number}
                    onChange={handleModsDetail}
                    errorMessage={validateFields?.sales_order_number}
                  />
                </div>
                <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
                  <InputField
                    disabled={loadingData}
                    placeholder="Enter Customer PO #"
                    labelText="Customer PO #"
                    className={"uppercase"}
                    name="customer_po"
                    value={mods_data?.customer_po}
                    onChange={handleModsDetail}
                    errorMessage={validateFields?.customer_po}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-0 ml:ml-[187px]">
          <p className="pl-2 font-medium text-lg md:-mt-6 mb-3 text-center ml:text-left">
            Customer Details
          </p>
          <div className="sm:flex w-full">
            <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
              <InputWithSuggestion
                autoFillUsed={autoFillUsed}
                setAutoFillUsed={setAutoFillUsed}
                autoFillUsing="CustomerName"
                resetCustomerDetails={resetCustomerDetails}
                disabled={loadingData || loading}
                placeholder="Enter Customer Name"
                labelText="Customer Name"
                name="name"
                type={"text"}
                value={customer_data?.name}
                onChange={handleCustomerDetail}
                isLoading={loading}
                errorMessage={validateFields?.name}
                options={customerNameOption}
                setSelected={setCustomerIdSelected}
                selected={customerIdSelected}
              />
            </div>
            <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
              <InputWithSuggestion
                autoFillUsed={autoFillUsed}
                setAutoFillUsed={setAutoFillUsed}
                autoFillUsing="CompanyName"
                resetCustomerDetails={resetCustomerDetails}
                disabled={loadingData || loading}
                placeholder="Enter Company Name"
                labelText="Company Name"
                name="company_name"
                type={"text"}
                value={customer_data?.company_name}
                onChange={handleCustomerDetail}
                isLoading={loading}
                errorMessage={validateFields?.company_name}
                options={companyNameOption}
                setSelected={setCustomerIdSelected}
                selected={customerIdSelected}
              />
            </div>
            <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
              <InputWithSuggestion
                autoFillUsed={autoFillUsed}
                setAutoFillUsed={setAutoFillUsed}
                autoFillUsing="AccountNumber"
                resetCustomerDetails={resetCustomerDetails}
                disabled={loadingData || loading}
                placeholder="Enter Account Number"
                labelText="Account Number"
                // readOnly = {(editOrder && originalCustomerData?.account_number) ? true : false}
                name="account_number"
                type={"text"}
                value={customer_data?.account_number}
                onChange={handleCustomerDetail}
                isLoading={loading}
                errorMessage={validateFields?.account_number}
                options={accountNumberOptions}
                setSelected={setAccountNumberSelected}
                selected={accountNumberSelected}
              />
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData || loading}
                placeholder="Enter Primary Contact Number"
                type={"text"}
                labelText="Primary Phone #"
                name="primary_contact"
                value={customer_data?.primary_contact}
                onChange={handleCustomerDetail}
                errorMessage={validateFields?.primary_contact}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData || loading}
                placeholder="Enter Accounting Contact Number"
                type={"text"}
                labelText="Accounting Phone #"
                name="accounting_contact"
                value={customer_data?.accounting_contact}
                onChange={handleCustomerDetail}
                errorMessage={validateFields?.accounting_contact}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData || loading}
                placeholder="Enter Primary Email"
                labelText="Primary Email"
                name="primary_email"
                value={customer_data?.primary_email}
                onChange={handleCustomerDetail}
                errorMessage={validateFields?.primary_email}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData || loading}
                placeholder="Enter Accounting Email"
                labelText="Accounting Email"
                name="accounting_email"
                value={customer_data?.accounting_email}
                onChange={handleCustomerDetail}
                errorMessage={validateFields?.accounting_email}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <TextField
                disabled={loadingData || loading}
                placeholder="Enter Bill To Address "
                labelText="Bill To"
                name="bill_to"
                value={customer_data?.bill_to}
                onChange={handleCustomerDetail}
                errorMessage={validateFields?.bill_to}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <TextField
                disabled={loadingData || loading}
                placeholder="Enter Ship To Address "
                labelText="Ship To"
                name="ship_to"
                value={customer_data?.ship_to}
                onChange={handleCustomerDetail}
                errorMessage={validateFields?.ship_to}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData || loading}
                placeholder="Enter Date of Shipment"
                labelText="Date of Shipment"
                name="shipment_date"
                type="date"
                value={
                  customer_data?.shipment_date
                    ? customer_data?.shipment_date?.split("T")[0]
                    : ""
                }
                onChange={handleCustomerDetail}
                errorMessage={validateFields?.shipment_date}
                min={editOrder ? "" : today}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                onFocus={handleCustomerDetail}
                disabled={loadingData || loading}
                placeholder="Others"
                labelText="Others"
                name="others"
                value={customer_data?.others}
                onChange={handleCustomerDetail}
              />
            </div>
          </div>
        </div>
      </form>
      {createQRpopup && (
        <Popup>
          <div className="flex gap-3 mb-3">
            <p>Serial #</p>
            <p className="text-gray-500">{manualSerialNoValue}</p>
          </div>
          <InputField
            value={manualSerialNoInput}
            disabled={generatingQRfromSiNo}
            onChange={handleManualSerialNo}
            errorMessage={serialNoError}
            type="text"
            maxLength={6}
          />
          <div className="bg-white sticky -bottom-5 left-0 right-0 flex justify-center gap-4 pt-2 pb-4">
            <ButtonOutline
              onClick={() => cancelSerialNoInput()}
              buttonText={"Cancel"}
            />
            <ButtonSolid
              onClick={() => generateNewQR()}
              isLoading={generatingQRfromSiNo}
              buttonText={"Generate"}
            />
          </div>
        </Popup>
      )}
      {assignQRpopup && (
        <Popup>
          {fetchingQR ? (
            <div className="p-4">
              <LoadingAnimation />
            </div>
          ) : listedQR === "no data" ? (
            <p className="p-4 my-6">
              No unassigned QR code found, generating a new...
            </p>
          ) : (
            <div>
              <p className="ml-6 mt-4 text-lg font-medium">Assign QR Code</p>
              <div className="flex pb-6 flex-wrap">
                {listedQR?.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    onClick={() => setSelectedQR(item)}
                    className={`p-1 pb-4 hover:bg-gray-300 cursor-pointer border-2 hover:border-gray-400 ${
                      selectedQR?.qr_id === item?.qr_id
                        ? "border-gray-400"
                        : "border-transparent"
                    }`}
                  >
                    <img
                      src={`data:image/png;base64,${item.base64_string}`}
                      alt={`QR Code ${itemIndex + 1}`}
                      width="150px"
                      height="150px"
                    />
                    <div className="flex flex-col justify-center">
                      <p className="mx-6 mb-1 text-center font-medium">
                        {item.qrString}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="bg-white sticky -bottom-5 left-0 right-0 flex justify-center gap-4 pt-2 pb-4">
            <ButtonOutline
              onClick={() => closeAsignQRpopup()}
              buttonText={"Cancel"}
            />
            <ButtonSolid
              disabled={selectedQR ? false : true}
              onClick={() => handleAssignQR()}
              buttonText={"Assign"}
            />
          </div>
        </Popup>
      )}
    </div>
  );
}

export default CustomerDetailForm;
