import { Link } from "react-router-dom";
import SkeletonLoading from "./SkeletonLoading";

function TemplateHeaders({
  templateModelDesc = [],
  machineOrder = false,
  modsTemplate = false,
  machineOrderTemp = false,
  loading,
}) {
  return (
    <>
      {loading ? (
        <SkeletonLoading />
      ) : (
        <div className="pr-4 mb-2 md:mb-0 ">
          <div>
            <Link
              className="text-gray-600 cursor-pointer"
              to={
                machineOrder
                  ? "/machine_orders"
                  : modsTemplate
                  ? "/mods_template"
                  : machineOrderTemp
                  ? "/machine_orders/mods_template"
                  : "#"
              }
            >
              {machineOrder
                ? "View All Machine Orders"
                : modsTemplate
                ? "View All Templates"
                : machineOrderTemp
                ? "Select MODs Template"
                : ""}
            </Link>
            <span className="px-2">{">"}</span>
            <Link className="text-gray-600 cursor-pointer">
              {templateModelDesc["name"]}
            </Link>
          </div>
          <h3 className="text-lg font-medium md:text-xl md:font-semibold max-w-[700px]">
            {templateModelDesc["model_description"]}
          </h3>
        </div>
      )}
    </>
  );
}

export default TemplateHeaders;
