import { FileUploadIcon } from './Icons'

function FileUploadDragDrop({label, file, handleDrop, accept='csv', errorMessage, handleUpload, className}) {

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    if (file) return (
        <div className='text-center mb-3 text-xl font-semibold text-gray-500'>{file.name}</div>
    )
  return (
    <>
        <div className="block text-gray-700 font-medium">
            {label}
        </div>
        <div className='mb-2'>
            <label 
                className={`w-full max-w-[660px] py-6 md:py-14 border-2 border-dashed flex flex-col justify-center items-center ${className}`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div className='block'>
                    <FileUploadIcon />
                </div>
                <p className='hidden md:block text-lg md:text-base mb-1 text-gray-600 text-center'>Select a file or drag and drop here</p>
                <p className='text-lg md:text-sm text-gray-400 mb-3'>CSV</p>
                <div className='text-sm border-b border-black'>Select File</div>
                <input className="hidden" accept={accept} type='file' onChange={handleUpload} />
            </label>
            <p className="text-red-600 text-sm min-h-5 text-center">{errorMessage || ''}</p>
        </div>
    </>
  )
}

export default FileUploadDragDrop