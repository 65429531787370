import { useState, useEffect, useContext, useRef } from 'react';
import ButtonSolid from '../Components/ButtonSolid';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseURL, frontendURL } from '../config';
import LoadingAnimation from '../Components/LoadingAnimation';
import PaginationComponent from '../Components/PaginationComponent';
import Searchbar from '../Components/Searchbar';
import PopupWithClose from '../Components/PopupWithClose';
import AuthContext from '../Context/AuthContext';
import toast from 'react-hot-toast';
import OverlayLoading from '../Components/OverlayLoading';
import { DeleteIcon, EditIcon, RightArrowIcon } from '../Components/Icons';
import LinkButtonSolid from '../Components/LinkButtonSolid';
import moment from 'moment';
import DeletePopUp from '../Components/DeletePopUp';
import CopyButton from '../Components/CopyButton';
import ToolTip from '../Components/ToolTip';
import { saveAs } from 'file-saver';

function MachineOrderListView() {
  const navigate = useNavigate()
  const [machineOrders, setMachineOrders] = useState([])
  const [fetchingMachineOrder, setFetchingMachineOrder] = useState(true);
  const [showQRpopup, setShowQRpopup] = useState(false);
  const { token, logout, limitValue } = useContext(AuthContext);
  const [refetch, setRefetch] = useState(false);
  const [fetchingQR, setFetchingQR] = useState(true);
  const [QRDetails, setQRDetails] = useState(true);
  const [deletingMachineOrders, setDeletingMachineOrders] = useState(false)
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const [deleteData, setDeleteData] = useState()
  const [downloadingPDF, setDownloadingPDF] = useState(false)
  const [totalMachineOrder, setTotalMachineOrder] = useState()
  const [totalPages, setTotalPages] = useState(1);
  const [machineOrdersearchInput, setMachineOrdersearchInput] = useState('')
  const [machineOrderParams, setMachineOrderParams] = useState({
    page: 1,
    limit: 10,
    search: ""
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingMachineOrder(true)
        const response = await axios.get(`${baseURL}/mods/getAllMods`, 
        {headers: {
          "ngrok-skip-browser-warning":"any",
          "x-access-token": token
        },
        params: machineOrderParams });
        // setMachineOrders(response.data?.data?.modsData)
        // if (response?.status === 204) {
        //     setMachineOrders('No Content');
        // }
        if (response?.status === 204) {
          setMachineOrders('No Content');
          setTotalPages(1)
        } else {
          setMachineOrders(response.data.data.modsData)
          setTotalPages(response.data.data.pagination.totalPages)
          setTotalMachineOrder(response.data.data.pagination.totalMods)
        }
      } catch (error) {
        if (error.response?.status === 401) {
          logout();
        } else{
            console.error('Error:', error);
        }
      } finally {
        setFetchingMachineOrder(false)
      }
    };
  
    fetchData();
  }, [limitValue, refetch, logout, token, machineOrderParams]);  
   

const DeleteMachineOrder = async (param) => {
  try {
    setDeletingMachineOrders(true)
    await axios.delete(`${baseURL}/mods/deleteModsById/${param}`, {
        headers: {
          "ngrok-skip-browser-warning": "any",
          "Content-Type": "multipart/form-data",
          "x-access-token": token
        },
        params: {
          mods_id: param,
        }
    });
    toast.success('Order Deleted successfully')
    setRefetch(prev => !prev)
  } catch (error) {
      console.error('Error:', error);
      if (error.response?.status === 401) {
          logout();
      }
  } finally {
    setDeletingMachineOrders(false)
    setDeleteData()
  }
}

const handleCurrentPage = (value) => {
  setMachineOrderParams(prevParams => ({
    ...prevParams,
    page: value
  }));
}

const handleLimitValue = (value) => {
  setMachineOrderParams(prevParams => ({
    ...prevParams,
    limit: value
  }));
}

const fetchQR = async (param) => {
  try {
      setFetchingQR(true)
      const response = await axios.get(`${baseURL}/mods/getQRByModsId/${param}`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "Content-Type": "multipart/form-data",
            "x-access-token": token
          },
          params: {
            mods_id: param,
          }
      });
      setQRDetails(response.data.data.qrData);
  } catch (error) {
      console.error('Error:', error);
      if (error.response?.status === 401) {
          logout();
      }
  } finally {
    setFetchingQR(false)
  }
};

const showQR = (qr_id) => {
  setQRDetails()
  setShowQRpopup(true)
  fetchQR(qr_id)
}


const useDebounce = (func, delay) => {
  const debounceRef = useRef(null);
  
  const debouncedFunc = (...args) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = window.setTimeout(() => {
      func(...args);
    }, delay);
  };

  debouncedFunc.cancel = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
  };

  return debouncedFunc;
};

const handleSetSearchFilter = useDebounce((value) => {
  setMachineOrderParams(prevParams => ({
    ...prevParams,
    search: value,
    page: 1
  }))
}, 800);

const handleSearchFilter = (input) => {
  setMachineOrdersearchInput(input)
  handleSetSearchFilter(input)
}

const downloadPDF = async(selectedQRID) => {
  try {
    setDownloadingPDF(true)
    const response = await axios.post(
      `${baseURL}/qr/exportUsingId`, 
      { qr_ids: [selectedQRID] },
      {headers: {
        "ngrok-skip-browser-warning":"any",
        "x-access-token":token
      },
      responseType: 'blob'
  });

    const filename = 'qr.pdf'
    const blob = await response.data;
    saveAs(blob, filename);
    toast.success('PDF Downloaded');
  } catch (error) {
    toast.error('Error Downloading PDF:', error);
  } finally {
    setDownloadingPDF(false)
  }
}

if ( machineOrders.length < 1 & fetchingMachineOrder === false ){
  return (
    <div className='py-3 md:py-6'>            
    <div className='bg-white rounded-lg w-full h-full shadow-md'>
        <div className='py-14'>
            <h3 className='text-center text-lg font-medium mb-6'>Machine Order Data Sheet</h3>
            <div className="mx-auto w-fit mb-8">
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M58.75 68.7502H16.25L13.75 30.7752C13.7327 30.5133 13.7698 30.2506 13.8591 30.0037C13.9484 29.7569 14.0879 29.5312 14.2688 29.3409C14.4496 29.1507 14.668 28.9999 14.91 28.8983C15.152 28.7966 15.4125 28.7462 15.675 28.7502H25C25.4308 28.7483 25.8561 28.8467 26.2423 29.0376C26.6285 29.2285 26.9649 29.5067 27.225 29.8502L30.275 33.7502H58.75V38.7502" stroke="#000A33" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M65.75 51.75L67.85 43.75H23.125L16.25 68.75H58.525C59.3083 68.748 60.0689 68.487 60.6884 68.0077C61.3078 67.5284 61.7514 66.8577 61.95 66.1L63.85 58.9" stroke="#000A33" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M41.25 61.2493C41.9149 60.5898 42.8135 60.2197 43.75 60.2197C44.6865 60.2197 45.5851 60.5898 46.25 61.2493" stroke="#000A33" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M33.75 53.75H36.25" stroke="#000A33" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M51.25 53.75H53.75" stroke="#000A33" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M51.55 9.02637C45.625 10.0014 41.25 13.4264 41.25 17.5014C41.2893 19.0854 41.9126 20.599 43 21.7514L41.25 28.7514L48.4 25.1764C50.5255 25.8971 52.7557 26.2603 55 26.2514C62.5 26.2514 68.75 22.3264 68.75 17.5014C68.75 13.5014 64.525 10.1264 58.75 9.07637" stroke="#000A33" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M53.125 15.625L56.875 19.375" stroke="#000A33" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M56.875 15.625L53.125 19.375" stroke="#000A33" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <p className='text-center font-medium text-gray-500 mb-9'>No MODS found</p>
            <LinkButtonSolid
            buttonText="Create MODS using Templates "
            className="block mx-auto"
            linkTo={'/machine_orders/mods_template'}
            />
        </div>
    </div>
  </div>
  );
}

  return (
    <>
      {deletingMachineOrders && (
        <OverlayLoading loadingText='Deleting Machine Order...'/>
      )}
    <div className='pb-6'>
      <div className='flex flex-wrap md:flex-nowrap justify-between items-center gap-4 py-8'>
        <p className='text-lg font-medium md:text-xl md:font-semibold pr-3'>All Machine Order Data Sheets</p>
      </div>
      <div className="p-3 bg-white shadow-md rounded-lg min-h-80">
        <div className='flex justify-between flex-wrap px-1 md:pt-3 md:pb-2 items-center gap-3 mb-4 md:mb-0'>
          <div className='pr-2 whitespace-nowrap text-xl font-medium'>Machine Order Data Sheets</div>
          <div className="flex flex-wrap gap-4">
            <Searchbar 
              value={machineOrdersearchInput}
              onChange={(e) => {handleSearchFilter(e.target.value)}}
              className={fetchingMachineOrder ? 'bg-gray-100' : ''}
            />
            <ButtonSolid
              buttonText='Create MODS'
              onClick={() => navigate("/machine_orders/mods_template")}
            />
          </div>
        </div>
        {fetchingMachineOrder ? 
          (<LoadingAnimation />):
          <div className='overflow-x-auto'>
            <table className="w-full text-sm mt-4 text-left rtl:text-right text-gray-500 py-3 border border-gray-300">
              <thead className="text-gray-500 uppercase text-md bg-slate-50 border-b border-gray-300">
              <tr>
                <th className="px-2 py-3 min-w-[120px] border-r border-gray-300">MODEL #</th>
                <th className="px-2 py-3 border-r border-gray-300">SALES ORDER #</th>
                <th className="px-2 py-3 text-nowrap border-r border-gray-300">CUSTOMER PO #</th>
                <th className="px-2 py-3 border-r border-gray-300">SERIAL #</th>
                <th className="px-2 py-3 min-w-[100px] border-r border-gray-300">QR CODE</th>
                <th className="px-2 py-3 border-r border-gray-300">ACCOUNT #</th>
                <th className="px-2 py-3 border-r border-gray-300">CREATED AT</th>
                <th className="px-2 py-3 border-r border-gray-300">LINK</th>
                <th className="px-2 py-3 text-center">ACTIONS</th>
              </tr>
              </thead>
              {(machineOrders === "No Content") ? 
              <tbody>
                <tr><td colSpan={3} className='p-4 md:text-lg'>{`No data found for '${machineOrderParams.search}'`}</td></tr>
              </tbody> :
              <tbody>
                {machineOrders?.map((data, index)=>(
                <tr
                  key={index}
                  className="bg-white text-sm md:text-base border-b hover:bg-slate-200 cursor-pointer text-gray-900 z-0"
                >
                  <td onClick={() => navigate(`/machine_orders/machine_orders_detail_view/${data?._id}`)} className="px-2 py-6 font-medium whitespace-nowrap border-r border-gray-300">{data?.model_number}</td>
                  <td onClick={() => navigate(`/machine_orders/machine_orders_detail_view/${data?._id}`)} className="px-2 py-6 font-medium uppercase border-r border-gray-300">{data?.sales_order_number}</td>
                  <td onClick={() => navigate(`/machine_orders/machine_orders_detail_view/${data?._id}`)} className="px-2 py-6 uppercase border-r border-gray-300">{data?.customer_po}</td>
                  <td onClick={() => navigate(`/machine_orders/machine_orders_detail_view/${data?._id}`)} className="px-2 py-6 border-r border-gray-300">{data?.serial_number}</td>
                  <td onClick={() => showQR(data?._id)} className="px-2 py-6 border-r border-gray-300"><span className='border-b border-gray-800'>View QR Code</span></td>
                  <td className="px-2 py-6 border-r border-gray-300">
                    <ToolTip text={[data?.customer['name'], data?.customer['company_name']]}>
                      <p className='text-blue-500 border-b border-blue-500 w-fit text-left'>{data?.customer['account_number'] ? data?.customer['account_number'] : 'N/A'}</p>
                    </ToolTip>
                  </td>
                  <td onClick={() => navigate(`/machine_orders/machine_orders_detail_view/${data?._id}`)} className="px-2 py-6 border-r border-gray-300">{moment(data?.created_at).format('ll')}</td>
                  <td className="px-2 py-6 border-r border-gray-300">
                    <div className='flex'>
                      <Link to={`${frontendURL}/eqid/${data?.serial_number}`} className='hover:border-b border-gray-600'>Service Landing Page</Link>
                      <CopyButton textToCopy={`${frontendURL}/eqid/${data?.serial_number}`} />
                    </div>
                  </td>                  
                  <td className="px-2 py-6 border-r border-gray-300">
                    <div className='flex mx-auto w-fit z-20'>
                      <p onClick={() => navigate(`/machine_orders/edit_mods_data_sheet/${data?._id}`)} className='px-4'> <EditIcon /> </p>
                      <p onClick={() => {setOpenDeletePopup(true); setDeleteData(data)}} className='px-4'> <DeleteIcon /> </p>
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>}
            </table>
            {(totalMachineOrder > 10) && 
            <PaginationComponent
              totalPages={totalPages} 
              currentPage={machineOrderParams.page} 
              totalData={totalMachineOrder}
              limitValue={machineOrderParams.limit}
              setLimitValue={handleLimitValue}
              setCurrentPage={handleCurrentPage} 
              siblings={2} 
            />}
          </div>
        }
      </div>
    </div>
    {showQRpopup && 
    <PopupWithClose closePopup={setShowQRpopup}>
      {fetchingQR ? <LoadingAnimation /> :
      <div>
        <img src={`data:image/png;base64,${QRDetails.base64_string}`} alt="QR Code 1" width="350px" height="350px" className="mx-auto"></img>
        <p className='text-center text-2xl font-bold'>{QRDetails.source_string}</p>
        <button
          disabled={downloadingPDF}
          onClick={() => downloadPDF(QRDetails._id)}
          className="flex ml-auto text-right text-blue-500 w-fit border-b border-blue-500 hover:text-blue-700"
        >
          {downloadingPDF ? (
            <LoadingAnimation />
          ) : (
            <>
              <span>Print PDF sticker</span>
              <span><RightArrowIcon /></span>
            </>
          )}
        </button>
      </div>}
    </PopupWithClose>}

    {openDeletePopup &&
      // <DeletePopUp onDelete={() => DeleteMachineOrder(deleteData._id)} dataName={`Machine Order of ${deleteData.customer['name']}`} setOpenDeletePopup={setOpenDeletePopup} />
      <DeletePopUp onDelete={() => DeleteMachineOrder(deleteData._id)} dataName={<>Machine Order: <span className='uppercase'>{deleteData.serial_number}</span></>} setOpenDeletePopup={setOpenDeletePopup} />
    }
    </>
  )
}

export default MachineOrderListView