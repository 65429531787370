import React, { useState } from "react";
import { ContactUsIcon } from "./Icons";
import { LPinputField, LPtextField } from "./LandingPageComponents";
import ButtonSolid from "./ButtonSolid";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import RadioButton from "./RadioButton";
import { LPcontactUsFormValidation } from "../utils/LPcontactUsFormValidator";
import axios from "axios";
import { baseURL, frontendURL } from "../config";

function LPcontactUsForm({eqid_link}) {
  const [contactFormData, setContactFormData] = useState({
    name: "",
    phone: "",
    email: "",
    query: "",
  });
  const [validateFields, setValidateFields] = useState({
    name: "",
    phone: "",
    email: "",
    query: "",
  });
  const [initialFormSubmit, setInitialFormSubmit] = useState(false);
  const [sendingData, setSendingData] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Parts");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Check if the name is 'query' and limit the length to 500
    const limitedValue = name === 'query' ? value.slice(0, 500) : value;
  
    setContactFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: limitedValue };  
      if (initialFormSubmit) {
        const [, validateFieldsCopy] = LPcontactUsFormValidation(updatedFormData, validateFields);
        setValidateFields(validateFieldsCopy);
      }
      return updatedFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInitialFormSubmit(true)
    const [isAnyEmptyFields, validateFieldsCopy] = LPcontactUsFormValidation(contactFormData, validateFields);
    setValidateFields(validateFieldsCopy)
    if (!isAnyEmptyFields) {
      try {
        setSendingData(true);
        await axios.post(`${baseURL}/mods/contactUs`, {
          name: contactFormData.name.trim(),
          phone: contactFormData.phone.trim(),
          email: contactFormData.email.trim(),
          query: contactFormData.query.trim(),
          more_info: selectedOption,
          eqid_number: eqid_link,
          eqid_link: `${frontendURL}/eqid/${eqid_link}`,
        });
        toast.success('Email Sent Successfully')
        setContactFormData ({
          name: "",
          phone: "",
          email: "",
          query: "",
        });
        setValidateFields ({
          name: "",
          phone: "",
          email: "",
          query: "",
        })
        setInitialFormSubmit(false)
      } catch (error) {
        toast.error("Something went wrong: Please try again later");
      } finally {
        setSendingData(false);
      }
    };
  }

  return (
  <section
  id="MOcontactForm"
  className="bg-[#000A33] text-white px-4 w-full"
  >
    <div className="flex flex-col md:flex-row max-w-[1600px] mx-auto">
      <div className="md:w-[15%] flex flex-row md:flex-col items-end cursor-default md:items-start min-w-[138px] md:min-w-[265px] pt-14 md:pt-20 md:border-r md:border-[#011C88]">
        <span>
          <ContactUsIcon />
        </span>
        <p className="font-bold pl-4 md:pl-0 text-base mt-2 pr-2">CONTACT US</p>
      </div>
      <div className="md:w-[85%]">
        <form
          onSubmit={handleSubmit}
          className="w-full py-6 md:py-20 md:max-w-[390px] md:pl-6 md:pr-2"
        >
          <LPinputField
            labelText={"NAME *"}
            placeholder={"Enter Name"}
            name={"name"}
            value={contactFormData.name}
            errorMessage={validateFields?.name}
            onChange={handleChange}
          />
          <LPinputField
            labelText={"PHONE *"}
            placeholder={"Enter Phone Number"}
            type={"number"}
            maxLength={10}
            name={"phone"}
            value={contactFormData.phone}
            errorMessage={validateFields?.phone}
            onChange={handleChange}
          />
          <LPinputField
            labelText={"EMAIL *"}
            placeholder={"Enter Email"}
            name={"email"}
            value={contactFormData.email}
            errorMessage={validateFields?.email}
            onChange={handleChange}
          />
          <p className="block text-sm md:text-base text-gray-400 mb-2">
            I WANT MORE INFORMATION ABOUT *
          </p>
          <div className="flex flex-col justify-center mb-4">
            <RadioButton
              label="Parts"
              value="Parts"
              checked={selectedOption === "Parts"}
              onChange={handleOptionChange}
              className={"text-gray-400"}
            />
            <RadioButton
              label="Service"
              value="Service"
              checked={selectedOption === "Service"}
              onChange={handleOptionChange}
              className={"text-gray-400"}
            />
            <RadioButton
              label="Additional Equipment"
              value="Additional Equipment"
              checked={selectedOption === "Additional Equipment"}
              onChange={handleOptionChange}
              className={"text-gray-400"}
            />
          </div>
          <div className="relative">
            <LPtextField
              labelText={"WHAT CAN WE HELP YOU WITH? *"}
              placeholder={"Enter Your Query"}
              name={"query"}
              value={contactFormData.query}
              errorMessage={validateFields?.query}
              onChange={handleChange}
            />
            <p className="absolute right-0 bottom-1 text-xs md:text-sm">{contactFormData.query.length}/500</p>
          </div>
          <ButtonSolid
            type="submit"
            isLoading={sendingData}
            buttonText="Send"
            className="w-full md:max-w-[390px] mt-3 mb-6 font-semibold"
          />
          <div className="font-semibold mb-4 cursor-default">
            Contact Us:
            <span className="hidden sm:inline ml-2 cursor-text">
              512-352-3694
            </span>
            <Link to="tel:512-352-3694" className="sm:hidden ml-2">
              512-352-3694
            </Link>
          </div>
        </form>
      </div>
    </div>
  </section>
  );
}

export default LPcontactUsForm;