import { useContext, useEffect, useState } from 'react'
import ButtonSolid from '../Components/ButtonSolid'
import MODsTemplateCard from '../Components/MODsTemplateCard'
import MODsTemplateUploadForm from '../Components/MODsTemplateUploadForm'
import Popup from '../Components/Popup'
import { CloseIcon } from '../Components/Icons'
import toast from 'react-hot-toast'
import axios from 'axios'
import { baseURL } from '../config'
import AuthContext from '../Context/AuthContext'
import LoadingAnimation from '../Components/LoadingAnimation'
import Searchbar from '../Components/Searchbar'
import SearchSelector from '../Components/SearchSelector'
import useDebounce from '../hooks/useDebounce'

function ModsTemplateListView() {
    const [uploadPopup, setUploadPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [templateData, setTemplateData] = useState([]);
    const [refresh, setRefresh] = useState(false)
    const { token, logout } = useContext(AuthContext);
    const [series, setSeries] = useState()
    const [seriesSelected, setSeriesSelected] = useState('')
    const [modsSearch, setModsSearch] = useState('')
    const [fetchingDataSeries, setFetchingDataSeries] = useState(true)
    const debouncedSearch = useDebounce(modsSearch)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setUploadPopup(false)
                setLoading(true)
                const response = await axios.get(`${baseURL}/template/getAllTemplates`, 
                {headers: {
                "ngrok-skip-browser-warning":"any",
                "x-access-token": token
                },params: {
                    filter: seriesSelected,
                    search: debouncedSearch
                }});
                if (response?.status === 204) {
                    setTemplateData('no data');
                  } else {
                    setTemplateData(response.data.data.templateData)
                  }
            } catch (error) {
                toast.error('Error:', error);
                logout();
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [refresh, debouncedSearch, seriesSelected, logout, token]);

    useEffect(() => {
        const fetchDataSeries = async () => {
            try {
                setFetchingDataSeries(true)
                const response = await axios.get(`${baseURL}/template/getSeries`, 
                {headers: {
                "ngrok-skip-browser-warning":"any",
                "x-access-token": token
                }});
                if (response?.status === 204) {
                    setSeries('');
                } else {
                    setSeries(response.data.data.series)
                }
            } catch (error) {
                logout();
            } finally {
                setFetchingDataSeries(false)
            }
        }

        fetchDataSeries()
    }, [refresh, logout, token])

    if ( templateData.length < 1 & loading === false ){
        return (
            <div className="py-3 md:py-6">
                <div className="w-full bg-white px-3 py-6 shadow-md rounded-lg">
                    <MODsTemplateUploadForm refresh={refresh} setRefresh={setRefresh} />
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className='flex items-center justify-between flex-wrap pt-5 pb-2 gap-4'>
                <h3 className='font-semibold text-2xl mx-3'>Machine Order Data Sheets Templates</h3>
                <div className='flex mx-3 gap-4 flex-wrap md:flex-nowrap'>
                    <Searchbar 
                        value={modsSearch}
                        onChange={(e) => {setModsSearch(e.target.value)}}
                        className={loading ? 'bg-gray-100' : ''}
                    />
                    <SearchSelector
                        options = {series}
                        selected = {seriesSelected}
                        setSelected = {setSeriesSelected}
                        placeholder = {'All MODs Templates'}
                        disabled = {loading}
                        isLoading = {fetchingDataSeries}
                    />
                    <ButtonSolid 
                        disabled={loading}
                        buttonText="Create MODs Template"
                        onClick={() => setUploadPopup(true)}
                    />
                </div>
            </div>
            {loading ?
            (<div className='p-4'>
                <LoadingAnimation />
            </div>):
            (templateData === 'no data') ? <p className='px-2'>No templates available for '{debouncedSearch}' {seriesSelected && ` in '${seriesSelected}'`}</p> :
            (<div className='flex flex-wrap'>
            {templateData?.map((data, index) => (
                <MODsTemplateCard key={index} data={data} refresh={refresh} setRefresh={setRefresh} MODPage={true}/>
            ))}
            </div>)}

            {uploadPopup && 
                <Popup>
                    <div className='sticky top-0 z-10'>
                        <div onClick={() => setUploadPopup(false)} className='p-2 border-2 bg-white border-gray-300 rounded-md mr-0 ml-auto mb-3 w-10 h-10 cursor-pointer'>
                            <CloseIcon size="w-5 h-5" />
                        </div>
                    </div>
                    <MODsTemplateUploadForm refresh={refresh} setRefresh={setRefresh} setUploadPopup={setUploadPopup}/>
                </Popup>
            }
        </div>
    )
}

export default ModsTemplateListView