import { useState } from 'react'
import InputField from '../Components/InputField'
import ButtonSolid from '../Components/ButtonSolid';
import LinkButtonOutline from '../Components/LinkButtonOutline';
import { baseURL } from '../config';
import axios from 'axios';
import toast from 'react-hot-toast';
import AuthLayout from '../Layouts/AuthLayout';
import { useNavigate } from 'react-router';

function AuthenticationEmailVerification() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [sending, setSending] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setSending(true)
      const response = await axios.post(`${baseURL}/auth/sendEmail`, { email });
      const message = response.data.message;
      toast.success(message);
      navigate('/login')
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        console.error('Error:', error);
      }
    } finally {
      setSending(false)
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <p className='lg:text-center text-2xl md:text-3xl font-medium text-gray-700 mb-4'>Forget Password</p>
        <div className='mb-5 md:mb-8'>
          <InputField 
            labelText = 'Registered Email Address'
            labelId = 'reset_email_address'
            type = 'email'
            value ={email}
            required = {true}
            onChange={(e) => {setEmail(e.target.value)}}
            errorMessage={error}
          />
        </div>

        <div>
          <ButtonSolid
            buttonText={sending ? 'Sending...' : 'Send Password Reset Link'}
            isLoading = {false}
            type='submit'
            className="w-full mb-3"
          />
          <LinkButtonOutline 
            linkTo="/login"
            buttonText='Cancel'
          />
        </div>
      </form>
    </AuthLayout>
  )
}

export default AuthenticationEmailVerification