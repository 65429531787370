import { createContext, useState } from 'react';

const ProductLibraryContext = createContext();

const ProductLibraryProvider = ({ children }) => {
  const [searchInput, setSearchInput] = useState('')
  const [filterShowValue, setFilterShowValue] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    filter: [],
    search: ""
  })

  return (
    <ProductLibraryContext.Provider value={{params, setParams, filterShowValue, setFilterShowValue, searchInput, setSearchInput}}>
      {children}
    </ProductLibraryContext.Provider>
  );
};

export { ProductLibraryProvider };

export default ProductLibraryContext