import { Link } from "react-router-dom";

const LinkButtonSolid = ({ buttonText = "Link Button", disabled = false, linkTo, className }) => {
    return (
        <Link
            disabled={disabled}
            to = {linkTo}
            className={`block text-center w-fit min-w-[150px] h-10 md:h-12 whitespace-nowrap bg-orange-400 px-2 md:px-4 hover:bg-orange-500 text-sm md:text-base font-medium py-2 md:py-3 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed leading-6 ${className}`}
        >
            {buttonText}
        </Link>
    );
};

export default LinkButtonSolid;