import '../App.css';
import { GearIcon } from './Icons';

const LoadingAnimation = ({loadingText = 'Loading'}) => {
    return(
        <div className="loader">
            <div className='w-6 h-6 mb-1'>
                <GearIcon />
            </div>
            <p className=' ml-1'>{loadingText}</p>
            <div className="loading-text">
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
            </div>
        </div>
    )
}

export default LoadingAnimation