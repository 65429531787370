import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { baseURL } from "../config";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import SkeletonLoading from "../Components/SkeletonLoading";
import AssetList from "../Components/AssetList";
import useBreadCrumbs from "../hooks/useBreadCrumbs";

function ProductLibraryDetailView() {
    const navigate = useNavigate()
    const { assembly_id } = useParams();
    const [currentPageId, setCurrentPageID] = useState()
    const [assemblyData, setAssemblyData] = useState([])
    const [fetchingAssemblyData, setFetchingAssemblyData] = useState(true)
    const [crumb, setCrumb] = useState()
    const [assets, setAssets] = useState()
    const [fetchingAssets, setFetchingAssets] = useState(true)
    const { token, logout } = useContext(AuthContext)
    const breadCrumbs = useBreadCrumbs(crumb)
    
    useEffect(() => {
        const fetchAssets = async (param) => {
            try {
                setFetchingAssets(true)
                const response = await axios.get(`${baseURL}/asset/getAssets`, {
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        "Content-Type": "multipart/form-data",
                        "x-access-token": token
                    },
                    params: {
                        asset_ids: param,
                    }
                });
                setAssets(response.data.data.assetData)
            } catch (error) {
                console.error('Error:', error);
                if (error.response?.status === 401) {
                    logout();
                }
            } finally {
                setFetchingAssets(false)
            }
        };
        
        const fetchData = async (param) => {
            try {
                setFetchingAssemblyData(true)
                const response = await axios.get(`${baseURL}/assembly/getAssembly/${param}`, {
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        "Content-Type": "multipart/form-data",
                        "x-access-token": token
                    },
                    params: {
                        assembly_id: param
                    }
                });
                setAssemblyData(response.data.data.assemblyData)
                setCurrentPageID(response.data.data.assemblyData._id)
                // We call fetch assets function if responce has assets
                if ((response.data.data.assemblyData.assets).length > 0) {
                    fetchAssets(response.data.data.assemblyData.assets)
                } else {
                    setAssets([])
                    setFetchingAssets(false)
                }
                const crumb = {
                    id: response.data.data.assemblyData._id,
                    label: response.data.data?.assemblyData.model_number || response.data.data.assemblyData?.assembly_number
                };
                setCrumb(crumb)
                
            } catch (error) {
                console.error('Error:', error);
                if (error.response?.status === 401) {
                    logout();
                }
            } finally {
                setFetchingAssemblyData(false)
            }
        };

        fetchData(assembly_id);
    }, [assembly_id, logout, token]);

    const page_navigate = (param) => {
        if (param !== currentPageId) {
            setAssemblyData([])
            setAssets()
            setFetchingAssets(true)
            navigate(`/assembly_detail/${param}`);
        }
    };

    return (
        <div className="py-3 ml:py-6">
            <div className='mb-2 font-medium text-base flex'>
                <Link to="/" className='text-gray-600 cursor-pointer'>All assembly</Link>
                {breadCrumbs && breadCrumbs.map((crumb, index) => (
                    <div key={index} onClick={() => page_navigate(crumb.id)} className='text-gray-600 cursor-pointer'>&nbsp; &#62; &nbsp; {crumb.label}</div>
                ))}
            </div>
            <h3 className='text-lg font-medium ml:text-xl ml:font-semibold mb-3 ml:mb-5'>{fetchingAssemblyData ? <SkeletonLoading max_width="max-w-52"/> : (assemblyData?.model_description ? assemblyData?.model_description : assemblyData?.assembly_description)}</h3>
            <div className='mb-4 ml:mb-8 min-h-20 bg-white shadow-md rounded-md'>
                {fetchingAssemblyData ? <div className="p-4"><SkeletonLoading /></div>
                : <div className="flex ml:flex-row flex-col justify-between py:2 ml:py-8">
                    <div className='w-full rounded-md px-4 border-b-2 ml:border-b-0 py-4 ml:py-0 ml:border-r-2'>
                        <h3 className='mb-2 text-gray-500 text-sm ml:text-base font-medium'>{assemblyData?.model_description ? "Model #" : "Assembly #"}</h3>
                        <div className='text-base ml:text-xl font-medium text-gray-600'>{assemblyData?.model_description ? assemblyData?.model_number : assemblyData?.assembly_number}</div>
                    </div>
                    {assemblyData?.model_description &&
                    <div className='w-full rounded-md px-4 border-b-2 ml:border-b-0 py-4 ml:py-0 ml:border-r-2'>
                        <h3 className='mb-2 text-gray-500 text-sm ml:text-base font-medium'>Model Description</h3>
                        <div className='text-base ml:text-xl font-medium text-gray-600'>{assemblyData.model_description}</div>
                    </div>}
                    <div className='w-full rounded-md px-4 py-4 ml:py-0'>
                        <h3 className='mb-2 text-gray-500 text-sm ml:text-base font-medium'>Assembly Description</h3>
                        <div className='text-base ml:text-xl font-medium text-gray-600'>{assemblyData?.assembly_description}</div>
                    </div>
                </div>}
            </div>
            <div className='w-full flex flex-col ml:flex-row gap-4'>
                <div className='ml:w-[60%] w-full bg-white shadow-md px-4 py-8 rounded-md mb-3 ml:mb-0'>
                    <AssetList data={assets} fetchingAssets={fetchingAssets} />
                </div>

                <div className='ml:w-[40%] w-full bg-white shadow-md px-4 py-2 ml:py-8 rounded-md'>
                    <h2 className='mb-1 text-xl text-gray-500 font-medium '>Embedded Assemblies</h2>
                    <p className='text-gray-400'>{assemblyData?.embedded_assemblies?.length} Files</p>
                    {fetchingAssemblyData ? <SkeletonLoading/> : assemblyData.embedded_assemblies?.map((item, index) => (
                        <div key={index} onClick={() => page_navigate(item._id)} className='flex items-center ml:px-4 py-4 border-b border-dashed border-gray-300 text-gray-500 last:border-none cursor-pointer hover:bg-slate-200'>
                            <div className='rounded-full bg-green-200 border h-14 w-14 min-h-14 min-w-14 ml:h-16 ml:min-w-16 ml:min-h-16 ml:w-16 text-green-600 border-green-600 text-center leading-[56px] ml:leading-[64px]'>E</div>
                            <div className='pl-3'>
                                <p className='font-medium'>{item.assembly_number ? item.assembly_number : item?.model_number }</p>
                                <p className='font-medium text-gray-400'>{item.assembly_description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ProductLibraryDetailView;
