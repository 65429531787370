import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { baseURL } from '../config'
import TreeView from './TreeView'
import { AccordionArrow } from './Icons'
import LoadingAnimation from './LoadingAnimation'

function Accordion({modsId, item, index}) {
    const [documentItems, setDocumentItems] = useState()
    const [fetchingDocumentItems, setFetchingDocumentItems] = useState()
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        const fetchDocumentItems = async () => {
            try {
                setFetchingDocumentItems(true)
                const response = await axios.get(`${baseURL}/mods/getAssetsBydocument/${modsId}`, {
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        "Content-Type": "multipart/form-data",
                    },
                    params: {
                        assetType: item
                    }
                });
                setDocumentItems(response.data.data.data)
            } catch (error) {
                console.error('fetchDocumentItems Error:', error);
            } finally {
                setFetchingDocumentItems(false)
            }
        };

        fetchDocumentItems()
    }, [item, modsId]); 

    return (
        <div className={`${!isOpen && 'md:hover:bg-gray-100'} w-full border-b-2`}>
            <div className='cursor-pointer flex justify-between py-8 md:py-12 md:px-4 items-center relative' onClick={() => setIsOpen(!isOpen)}>
                <h3 className='font-bold text-gray-500'>
                    <span className='mr-2'>{index}</span>
                    {item}
                </h3>
                <div className='mr-4'>
                    <span className={`absolute transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ease-in-out ${!isOpen && 'rotate-180'}`}>
                        <AccordionArrow />
                    </span>
                </div>
            </div>
            {isOpen && (
                fetchingDocumentItems ? (
                    <LoadingAnimation />
                ) : (
                    <div className='-mt-6 overflow-x-auto mb-6'>
                        <TreeView data={documentItems} item={item} />
                    </div>
                )
            )}
        </div>
    )
}

export default Accordion