import { CloseIcon } from './Icons'
import Popup from './Popup'

function PopupWithClose({children, closePopup, maxWidth}) {
  return (
    <Popup maxWidth={maxWidth} >
        <div className='sticky top-0 z-10'>
          <div onClick={(e) => {e.stopPropagation(); closePopup(false)}} className='p-2 border-2 bg-white border-gray-300 rounded-md mr-0 ml-auto mb-3 w-10 h-10 cursor-pointer'>
            <CloseIcon size="w-5 h-5"/>
          </div>
        </div>
        {children}
    </Popup>
  )
}

export default PopupWithClose