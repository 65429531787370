export const isValidEmail = (email) => {
  return /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
};

export const containsOnlyNumbers = (value) => {
  const regex = /^[0-9]+$/;
  return regex.test(value);
};

export const containsOnlyLetters = (value) => {
  const regex = /^[a-zA-Z\s]+$/;
  return regex.test(value);
};

export const hasMinCharactersExcludingSpaces = (str, minLength) => {
  const stringWithoutSpaces = str.replace(/\s/g, '');
  return stringWithoutSpaces.length >= minLength;
};

export const isValidNumberString = (str, minLen, maxLen) => {
  const regex = /^[0-9]+$/;
  return regex.test(str) && str.length >= minLen && str.length <= maxLen;
};

export const isFutureDate = (dateString) => {
  const date = new Date(dateString);
  const currentDate = new Date();
  return date > currentDate;
};

export const containsNoSpecialCharacters = (str) => {
  return /^[^\W_]+$/.test(str);
};

export const containsOnlyNumbersSignsAndBrackets = (str) => {
  return /^$|^[0-9+\-()]+$/.test(str);
};

export const containsOnlyNumbersLettersSlashAndMinus = (str) => {
  return /^$|^[0-9a-zA-Z/-]+$/.test(str);
};

export const containsOnlyNumbersLettersAndSlash = (str) => {
  return /^$|^[0-9a-zA-Z/]+$/.test(str);
};

export const containsOnlyNumbersSlashAndMinus = (str) => {
  return /^$|^[0-9/-]+$/.test(str);
};

export const containsOnlyTextSlashMinusAndBrackets = (str) => {
  return /^[a-zA-Z/()\-\s]+$/.test(str);
};