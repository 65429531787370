import { useEffect, useState } from "react";
import isEqual from 'lodash/isEqual';

function useBreadCrumbs(crumbData) {
    const [breadCrumbs, setBreadCrumbs] = useState([]);

    useEffect(() => {
        if (crumbData) {
            setBreadCrumbs(prevCrumbs => {
                const exists = prevCrumbs.some(crumb => isEqual(crumb, crumbData));
                if (exists) {
                    const index = prevCrumbs.findIndex(crumb => crumb.id === crumbData.id);
                    if (index !== -1) {
                        return prevCrumbs.slice(0, index + 1);
                    }
                } else {
                    return [...prevCrumbs, crumbData];
                }
                return prevCrumbs;
            });
        }
    }, [crumbData]);

    return breadCrumbs;
}

export default useBreadCrumbs;
