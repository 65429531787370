import Popup from './Popup'
import { DeleteIcon } from './Icons'
import ButtonSolid from './ButtonSolid'
import ButtonOutline from './ButtonOutline'

function DeletePopUp({onDelete, dataName, setOpenDeletePopup}) {

    const deleteAction = () => {
        onDelete()
        setOpenDeletePopup(false)
    }

  return (
    <Popup>
        <div className="px-2.5 pb-4 pt-5 sm:p-7 sm:pb-4">
            <div className="flex flex-row items-center mb-4">
                <DeleteIcon size='w-20 h-20'/>
                <p className="text-base md:text-lg font-semibold text-gray-600 pl-4">Are you sure you want to permanently delete {dataName} ?</p>
            </div>
            <div>
                <p className="text-sm border-dashed border-2 border-red-500 bg-red-100 p-2 rounded-md"><span className="text-red-600">Warning:</span> Deleting this item will permanently remove it from the data base. This action cannot be undone.</p>
            </div>
            <div className="flex flex-col md:flex-row-reverse mt-14 md:mt-6 gap-4">
                <ButtonSolid 
                buttonText="Delete" 
                onClick={() => deleteAction()} 
                className="w-full"
                />
                <ButtonOutline
                buttonText="Cancel" 
                onClick={(e) => {e.stopPropagation(); setOpenDeletePopup(false)}} 
                className="w-full"
                />
            </div>
        </div>
      </Popup>
  )
}

export default DeletePopUp