import { Link } from "react-router-dom";
import {
  DownloadIcon,
  FileIconBlue,
  SvgArrow,
  SvgArrow2,
  SvgArrowMobile,
  SvgArrowMobile2,
} from "./Icons";
import { useEffect, useState, useMemo } from "react";

const TreeNode = ({ node, item }) => {
  const assets = useMemo(() => {
    if (item === "Pneumatic Schematics")
      return node.pneumatic_schematic?.assets;
    if (item === "Electrical Schematics") {
      if (Array.isArray(node.electric_schematic)) {
        // Combine assets from all electric schematics
        return node.electric_schematic.flatMap(
          (schematic) => schematic.assets || []
        );
      } else {
        // Fallback in case electric_schematic is not an array
        return node.electric_schematic?.assets;
      }
    }
    return node.assets;
  }, [node, item]);

  return (
    <div className="bg-white pt-4 md:pt-6 last:-ml-[4px] last:pl-[4px] cursor-default">
      {assets?.map((data, index) => {
        const isFirst = index === 0;
        return (
          <div
            key={index}
            className="flex items-start -ml-[2px] first:pt-0 pt-4 md:pt-6"
          >
            <span className={`-mt-6 ${isFirst ? "hidden ml:block" : "hidden"}`}>
              <SvgArrow />
            </span>
            <span className={`-mt-6 ${isFirst ? "block ml:hidden" : "hidden"}`}>
              <SvgArrowMobile />
            </span>
            <span
              className={`-mt-[88px] ${
                !isFirst ? "hidden ml:block" : "hidden"
              }`}
            >
              <SvgArrow2 />
            </span>
            <span
              className={`-mt-[92px] ${
                !isFirst ? "block ml:hidden" : "hidden"
              }`}
            >
              <SvgArrowMobile2 />
            </span>
            <div className="p-2 text-sm z-10 md:text-base md:p-4 bg-[#ebefff] max-w-[760px] rounded-lg flex">
              <span className="mr-1 md:mr-3 text-xs md:text-base">
                <FileIconBlue />
              </span>
              <div className="flex justify-between items-center">
                <span>{data.name}</span>
                <Link
                  className="flex items-center ml-2 md:ml-4 cursor-pointer"
                  to={data?.source}
                  target="_blank"
                  download
                >
                  <DownloadIcon size={"h-4 w-4 md:h-6 md:w-6"} />
                </Link>
              </div>
            </div>
          </div>
        );
      })}
      <TreeView data={node?.embedded_assemblies} item={item} />
    </div>
  );
};

const TreeView = ({ data, item }) => {
  const [uniqueNodes, setUniqueNodes] = useState([]);
  useEffect(() => {
    const getUniqueNodes = () => {
      const nodeNamesSet = new Set();
      return data.filter((node) => {
        let names = [];

        if (item === "Pneumatic Schematics") {
          names =
            node.pneumatic_schematic?.assets?.map((asset) => asset.name) || [];
        } else if (item === "Electrical Schematics") {
          if (Array.isArray(node.electric_schematic)) {
            names = node.electric_schematic.flatMap(
              (schematic) => schematic.assets?.map((asset) => asset.name) || []
            );
          } else {
            names =
              node.electric_schematic?.assets?.map((asset) => asset.name) || [];
          }
        } else {
          names = node.assets?.map((asset) => asset.name) || [];
        }

        // Check if any of the names are not in the set
        const hasNewName = names.some((name) => !nodeNamesSet.has(name));

        if (hasNewName) {
          // Add all names to the set
          names.forEach((name) => nodeNamesSet.add(name));
          return true;
        }
        return false;
      });
    };

    setUniqueNodes(getUniqueNodes());
  }, [data, item]);

  return (
    <div className="ml-8 ml:ml-48 md:ml-20 first:ml-0 first:md:ml-8">
      <div className="border-l-2 border-[#515BA5]">
        {uniqueNodes.map((node) => (
          <TreeNode key={node._id} node={node} item={item} />
        ))}
      </div>
    </div>
  );
};

export default TreeView;
