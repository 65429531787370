import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL, frontendURL } from "../config";
// import { Link, useNavigate, useParams } from 'react-router-dom'
import { useNavigate, useParams } from "react-router-dom";
// import LinkButtonSolid from '../Components/LinkButtonSolid'
import LPcontactUsForm from "../Components/LPcontactUsForm";
// import LPviewByAssembly from '../Components/LPviewByAssembly'
import LPviewByDocument from "../Components/LPviewByDocument";
import { LPskeletonLoading } from "../Components/LandingPageComponents";
import ButtonSolid from "../Components/ButtonSolid";
import Popup from "../Components/Popup";
import ButtonOutline from "../Components/ButtonOutline";
import InputField from "../Components/InputField";
import { RightArrowIcon } from "../Components/Icons";
import { isValidEmail } from "../utils/validators";
import toast from "react-hot-toast";

function LandingPage() {
  // const [activeTab, setActiveTab] = useState('Document')
  const activeTab = "Document";
  const [headerData, setHeaderData] = useState("");
  const [fetchingHeaderData, setFetchingHeaderData] = useState(true);
  const [modsId, setModsId] = useState();
  const [sendDesktopLink, setSendDesktopLink] = useState(false);
  const [urlEmail, setUrlEmail] = useState("");
  const [error, setError] = useState("");
  const [sendingData, setSendingData] = useState(false)
  const [initialSubmit, setInitialSubmit] = useState(true)
  const navigate = useNavigate();
  const { qr_id } = useParams();
  
  useEffect(() => {
    const fetchHeaderData = async (param) => {
      try {
        setFetchingHeaderData(true);
        const response = await axios.get(
          `${baseURL}/mods/getLandingPageDetails/${param}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "any",
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setModsId(response.data.data.modsData.id);
        setHeaderData(response.data.data);
        // setUserEmail(response.data.data.customer_data.email);
      } catch (error) {
        console.error("Error:", error);
        navigate(`/eqid_notFound/${param}`);
      } finally {
        setFetchingHeaderData(false);
      }
    };

    fetchHeaderData(qr_id);
  }, [qr_id, navigate]);

  // const moveToContacts = () => {
  //   const MOcontactForm = document.getElementById("MOcontactForm");
  //   if (MOcontactForm) {
  //     MOcontactForm.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const handleChange = (input) => {
    setUrlEmail(input);
    if (!initialSubmit) {
      validateEmail(input);
    }
  };

  const validateEmail = (email) => {
    if (!email) {
      setError("Please add Email Address");
      return false;
    } else if (!isValidEmail(email)) {
      setError("Please add a valid Email Address");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const emailSubmit = async () => {
    setInitialSubmit(false);
    const validEmail = validateEmail(urlEmail);

    if (validEmail) {
      try {
        setSendingData(true);
        await axios.post(`${baseURL}/mods/continueToDesktop`, {
          email: urlEmail,
          eqid_link: `${frontendURL}/eqid/${qr_id}`,
        });
        toast.success("Email Sent Successfully");
        setUrlEmail("");
        setInitialSubmit(false);
        setSendDesktopLink(false)
      } catch (error) {
        toast.error("Something went wrong: Please try again later");
      } finally {
        setSendingData(false);
      }
    }
  };

  return (
    <main className="bg-white">
      <nav className="min-h-[76px] md:min-h-[65px] flex justify-center md:justify-start items-center pt-4 pb-2 px-2 md:px-4">
        <a
          href={"https://www.choicebagging.com/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="h-auto w-[160px] md:w-[135px]"
            src="/CHOICE-BAGGING-NEW-LOGO-300x93-corrected-2.png"
            alt="Company Logo"
          />
        </a>
      </nav>
      <section className="bg-[#000A33] text-white px-4 pt-14 pb-12 md:pt-20 md:pb-16 w-full relative">
        <div className="w-full max-w-[1600px] mx-auto">
          <div className="flex flex-col justify-center items-center md:flex-row md:justify-between">
            <div className="flex flex-col items-center md:items-start">
              <div className="flex flex-col md:flex-row">
                <p className="text-2xl text-center font-bold md:mr-4 items-end">
                  EQID
                </p>
              </div>
              <h3 className="text-base md:text-lg mb-8 text-white text-center">
                Choice Bagging <span className="font-bold">EQ</span>uipment <span className="font-bold">ID</span> Tool
              </h3>
              {fetchingHeaderData ? (
                <div className="mb-4 md:mb-6 w-full max-w-56 ">
                  <LPskeletonLoading max_width={"max-w-56"} />
                </div>
              ) : (
                <p className="text-2xl font-bold mb-4 md:mb-6 text-center md:text-left text-orange-400">
                  {headerData["assemblyData"]["model_description"]}
                </p>
              )}
              {fetchingHeaderData ? (
                <div className="mb-4 w-full">
                  <LPskeletonLoading max_width="max-w-72 mx-auto md:mx-0" />
                  <LPskeletonLoading max_width="max-w-56 mx-auto md:mx-0" />
                </div>
              ) : (
                <p className="max-w-lg text-base md:text-lg text-gray-400 font-semibold mb-6 text-center md:text-left">
                  {headerData["assemblyData"]["description"]}
                </p>
              )}
              <div className="border border-[#485aad] flex flex-col justify-between px-4 md:px-8 py-2 rounded-md bg-[#151d3d] text-center md:text-left mb-8">
                <p className="text-xs md:text-base pb-2 text-center text-gray-400">
                  SERIAL NO
                </p>
                {fetchingHeaderData ? (
                  <LPskeletonLoading />
                ) : (
                  <p className="font-semibold text-sm md:text-base text-center">
                    {headerData["modsData"]["serial_number"]}
                  </p>
                )}
              </div>
              <div className="md:hidden mx-auto">
                <div className="flex items-center bg-orange-400 px-4 py-2 mr-0 rounded-md w-fit">
                  <p
                    className="text-sm cursor-pointer font-semibold"
                    onClick={() => setSendDesktopLink(true)}
                  >
                    Continue This Session On My Computer
                  </p>
                  <span className="w-6 h-6"><RightArrowIcon /></span>
                </div>
              </div>
              </div>
            <div className="hidden md:block mb-6 md:mb-0">
              {fetchingHeaderData ? (
                <div className="rounded-lg bg-gray-300 overflow-hidden w-[150px] h-[150px] lg:w-[200px] lg:h-[200px]">
                  {" "}
                </div>
              ) : (
                <img
                  src={`data:image/png;base64,${headerData["qrData"]["base64_string"]}`}
                  alt="QR"
                  className="rounded-lg overflow-hidden w-[140px] h-[140px] lg:w-[200px] lg:h-[200px]"
                />
              )}
              {fetchingHeaderData ? (
                <span className="block w-fit mx-auto">
                  <LPskeletonLoading />{" "}
                </span>
              ) : (
                <p className="text-orange-500 font-bold text-sm md:text-lg text-center mt-3">
                  {headerData["modsData"]["serial_number"]}
                </p>
              )}
            </div>
          </div>
          {/* <button onClick={() => moveToContacts()} className='bg-blue-500 hidden md:block text-sm w-full max-w-80 md:text-base rounded-md px-6 py-3 font-medium'>
              Contact Us
          </button> */}
        </div>
      </section>
      {/* <section className='hidden md:block text-[#000A33] bg-white px-4'>
                <div className='w-full max-w-[1600px] mx-auto pt-10 pb-6 md:pt-16 md:px-4'>
                    <div className='flex justify-center gap-4 md:gap-6'>
                        <button onClick={() => setActiveTab('Document')} className={` ${activeTab === 'Document' ? 'border-orange-400 font-bold bg-orange-400 text-white': 'border-gray-600'} px-8 py-3 border md:min-w-56 rounded-full hover:border-orange-300 hover:font-bold hover:bg-orange-300 hover:text-white text-xs md:text-base`}>View by Document</button>
                        <button onClick={() => setActiveTab('Assembly')} className={` ${activeTab === 'Assembly' ? 'border-orange-400 font-bold bg-orange-400 text-white': 'border-gray-600'} px-8 py-3 border md:min-w-56 rounded-full hover:border-orange-300 hover:font-bold hover:bg-orange-300 hover:text-white text-xs md:text-base`}>View by Assembly</button>
                    </div>
                </div>
            </section> */}
      {/* {activeTab === 'Assembly' && (
            <LPviewByAssembly modsId={modsId} />
            )} */}
      {activeTab === "Document" && !fetchingHeaderData && (
        <LPviewByDocument modsId={modsId} />
      )}
      <LPcontactUsForm eqid_link={qr_id}/>
      {sendDesktopLink && (
        <Popup>
          <form className="pt-2 pb-6">
            <p className="text-lg mb-2">
              Please enter your email address to receive the EQID page URL.
            </p>
            <InputField
              className="w-full"
              placeholder={"Enter Email Address"}
              onChange={(e) => handleChange(e.target.value)}
              errorMessage={error}
              value={urlEmail}
            />
            <div className="flex gap-4 mt-3">
              <ButtonOutline
                className={"w-full"}
                onClick={() => {setSendDesktopLink(false); setError(""); setUrlEmail(""); setInitialSubmit(true)}}
                buttonText={"Cancel"}
                disabled={sendingData}
              />
              <ButtonSolid className={"w-full"} onClick={emailSubmit} disabled={sendingData} buttonText={sendingData ? "Sending..." : "Submit"} />
            </div>
          </form>
        </Popup>
      )}
    </main>
  );
}

export default LandingPage;
