import { useState } from "react";
import ButtonSolid from '../Components/ButtonSolid';
import InputField from '../Components/InputField';
import toast from "react-hot-toast";
import axios from "axios";
import { baseURL } from "../config";
import { CreateUserFormValidation } from "../utils/CreateUserFormValidation";

function AuthenticationCreateUser() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirm_password: ''
  });
  const [validateFields, setValidateFields] = useState({
    username: '',
    email: '',
    password: '',
    confirm_password: ''
  });
  const [initialFormSubmit, setInitialFormSubmit] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError('')
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value };
      if (initialFormSubmit) {
        const [, validateFieldsCopy] = CreateUserFormValidation(updatedFormData, validateFields);
        setValidateFields(validateFieldsCopy)
      }
      return updatedFormData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setInitialFormSubmit(true)
    const [isAnyEmptyFields, validateFieldsCopy] = CreateUserFormValidation(formData, validateFields);
    setValidateFields(validateFieldsCopy)
    if (!isAnyEmptyFields) {
      try {
        setLoading(true)
        await axios.post(`${baseURL}/auth/register`, formData);
        toast.success('User Created Successfully')
        setFormData({
          username: '',
          email: '',
          password: '',
          confirm_password: ''
        })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data && error.response.data.message) {
            setError(error.response.data.message);
          } else {
            setError('Error signing up: Invalid request');
          }
        } else {
          setError('Error signing up: ' + error.message);
        }
      } finally {
        setLoading(false)
      }
    }
  };


  return (          
    <form onSubmit={handleSubmit} className="md:px-2">
      <p className='lg:text-center text-3xl font-medium mb-3 text-gray-700'>Create An Admin User</p>

      <div className='mb-3'>
        <InputField 
            labelText = 'Name'
            labelId = 'signup_username'
            type = 'text'
            name="username"
            errorMessage={validateFields?.username}
            value={formData.username}
            onChange={handleChange}
        /> 
      </div>

      <div className='mb-3'>
        <InputField 
            labelText = 'Email address'
            labelId = 'signup_email_address'
            type = 'email'
            name="email"
            errorMessage={validateFields?.email}
            value={formData.email}
            onChange={handleChange}
        /> 
      </div>

      <div className='mb-3'>
        <InputField 
            labelText = 'Password'
            labelId = 'signup_password'
            type = 'text'
            name="password"
            errorMessage={validateFields?.password}
            value={formData.password}
            onChange={handleChange}
        /> 
      </div>

      <div className='mb-3'>
        <InputField 
            labelText = 'Confirm Password'
            labelId = 'signup_confirm_password'
            type = 'text'
            name="confirm_password"
            errorMessage={validateFields?.confirm_password}
            value={formData.confirm_password}
            onChange={handleChange}
        /> 
      </div>
      <div className="mb-4 md:mb-8">
        <div className="min-h-5 mb-4">
          <p className="text-red-600">{error}</p>
        </div>
        <ButtonSolid 
          isLoading={loading}
          buttonText={'Create User'}
          className="w-full"
          type="submit"
        />
      </div>
    </form>                                
  )
}

export default AuthenticationCreateUser