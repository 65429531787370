import { useState } from "react";
import LoadingAnimation from "./LoadingAnimation";
import { CloseIcon, DownArrowIcon, SearchIcon } from "./Icons";

const SearchSelector = ({label, options=[], selected, setSelected, placeholder = ' ', className, isLoading = false, disabled=false }) => {
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  return (
    <div className="w-full md:w-60">
      <div className='block font-medium text-gray-700'>{label}</div>
      <div className="relative" tabIndex={0}>
        {open &&
          (<div className="fixed inset-0 transition-opacity" onClick={() => setOpen(false)} />)
        }
        <div
          className={`w-full ${open ? 'border-2 border-gray-400': 'border border-gray-300'} ${disabled ? 'bg-gray-100 cursor-not-allowed': 'bg-white cursor-pointer'} text-gray-600 rounded-md relative h-10 md:h-12 justify-between font-medium text-sm text-center inline-flex items-center
          ${!selected && "text-gray-700"} ${className}`}
        >
          <div className="w-full pl-5 py-3 text-start text-base" onClick={() => setOpen(!open)}>
            {selected
              ? selected
              : <span className="text-gray-400">{placeholder}</span>}
          </div>
          <div>
            {selected
              ? <div className="pr-5 py-3" onClick={() => { setSelected(); setOpen(false); }}><CloseIcon size={'h-4 w-4'}/></div>
              : <div className="pr-5 py-3 pl-2" onClick={() => setOpen(!open)}>
                  <div className={`transform transition-all duration-300 ease-in-out ${open && 'rotate-180'}`}>
                    <DownArrowIcon />
                  </div>
                </div>}
          </div>
        </div>
        {!disabled &&
        <div
          className={`bg-white mt-2 overflow-y-auto absolute top-11 left-0 right-0 w-full md:max-w-72 shadow-lg overflow-x-hidden ${
            open ? "max-h-60 border" : "max-h-0 border-none"
          } `}
        >
          {(options?.length !== 0) ?
          (<div className="flex items-center px-2 sticky top-0 bg-white border-b">
            <SearchIcon />
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value?.toLowerCase())}
              placeholder="Search.."
              className="placeholder:text-gray-400 p-2 outline-none w-full"
            />
          </div>):
          (<div className="flex items-center p-2 sticky top-0 bg-white border-b">Nothing to choose from</div>)}
          {
            isLoading ?
            (<div className="p-5">
              <LoadingAnimation />
            </div>):
            (options && <ul className="text-gray-700 z-10 cursor-pointer">
              {options?.map((item) => (
              <li
                key={item}
                className={`p-2 text-sm hover:bg-gray-300
                ${
                  item?.toLowerCase() === selected?.toLowerCase() &&
                  "bg-gray-200"
                }
                ${
                  item?.toLowerCase().startsWith(inputValue)
                    ? "block"
                    : "hidden"
                }`}
                  onClick={() => {
                  if (item?.toLowerCase() !== selected?.toLowerCase()) {
                    setSelected(item);
                    setOpen(false);
                    setInputValue("");
                  }
                }}
              >
                {item}
              </li>
            ))}
            </ul>)
          }
        </div>}
      </div>
    </div>
  );
};

export default SearchSelector;