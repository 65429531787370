import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Components/InputField';
import ButtonSolid from '../Components/ButtonSolid';

const FindEQID = () => {
  const [manualSerialNoInput, setManualSerialNoInput] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const url = `/eqid/${serialNumber}`; 
    navigate(url);
  };

  const handleSerialNumber = (event) => {
    const userInput = event.target.value;
    const digitsOnly = userInput.replace(/\D/g, '');
    if (digitsOnly.length <= 6) {
      setManualSerialNoInput(digitsOnly);
      const newValue = digitsOnly.padStart(6, '0');
      setSerialNumber(newValue);
    }
  };

  return (
    <div>
      <nav className="min-h-[76px] md:min-h-[65px] flex justify-center items-center pt-4 pb-2 px-2 md:px-4">
        <a
          href={"https://www.choicebagging.com/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="h-auto w-[160px] md:w-[170px]"
            src="/CHOICE-BAGGING-NEW-LOGO-300x93-corrected-2.png"
            alt="Company Logo"
          />
        </a>
      </nav>
      <div className="w-full h-full min-h-[calc(100vh-76.69px)] flex items-center bg-[#000A33]">
        <div className='w-full flex justify-center px-6'>
          <div className="sm:mx-auto w-full sm:max-w-md">
            <div className="bg-white px-5 sm:px-10 pt-10 md:pt-20 pb-10 md:pb-[60px] rounded-3xl">
              <h3 className='text-center text-4xl mb-2 font-bold text-[#000A33]'>EQID</h3>
              <p className="text-2xl md:text-xl mb-8 text-center font-medium text-orange-400">
                Welcome to the Choice Bagging <span className="font-bold">EQ</span>uipment <span className="font-bold">ID</span> Tool
              </p>
              <form onSubmit={handleSubmit}>
                <div className='mb-1 md:mb-3'>
                  <p className='mb-2 text-gray-600'>Please enter your equipment serial number to get started</p>
                  <InputField 
                    type='text'
                    required={true}
                    value={manualSerialNoInput}
                    name='serial_number'
                    maxLength={6}
                    onChange={handleSerialNumber}
                    autoFocus={true}
                  />
                </div>
                <div>
                  <ButtonSolid
                    buttonText={'Show EQID page'}
                    className="w-full"
                    type="submit"
                  />
                </div>
              </form>
            </div>  
          </div>
        </div>
      </div>
    </div>
  );
}

export default FindEQID;
