import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import {baseURL} from '../config'
import PopupWithClose from '../Components/PopupWithClose';
import AuthenticationCreateUser from '../Pages/AuthenticationCreateUser';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reloading, setReLoading] = useState(true);
  const [error, setError] = useState(null);
  const [createUserPopup, setCreateUserPopup] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('x-access-token');
    if (storedToken) {
      setToken(storedToken);
      setIsLoggedIn(true)
    }
    setReLoading(false);
  }, []);


  const login = async (email, password) => {
    try {
      setError(null);
      setLoading(true)
      const response = await axios.post(`${baseURL}/auth/signin`, {email, password });
      const token = response.data.data;
      localStorage.setItem('x-access-token', token);
      setToken(token);
      setIsLoggedIn(true);
      navigate("/")
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setError('Email or password incorrect');
        } else {
          setError('Login failed. Please try again later.');
        }
      } else if (error.request) {
        setError('Network issue. Please check your internet connection.');
      } else {
        setError('An unexpected error occurred.');
      } 
    } finally {
      setLoading(false)
    }
  };

  const logout = () => {
    localStorage.removeItem('x-access-token');
    setToken(null);
    setIsLoggedIn(false)
    navigate("/login")
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, loading, error, isLoggedIn, setCreateUserPopup }}>
      {createUserPopup && 
      <PopupWithClose closePopup={setCreateUserPopup} maxWidth={'md:max-w-[500px]'}>
        <AuthenticationCreateUser />
      </PopupWithClose>}
      {reloading ? (<div></div>) : children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };

export default AuthContext