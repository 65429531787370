import React, { useEffect, useState } from 'react'
import Accordion from '../Components/Accordion'
import axios from 'axios'
import { baseURL } from '../config'
import LoadingAnimation from './LoadingAnimation'

function LPviewByDocument({modsId}) {
    const [accordianHeading, setAccordianHeading] = useState([])
    const [fetchingAccordianHeading, setFetchingAccordianHeading] = useState(true)

    useEffect(() => {
        const fetchAccordianHeading = async (id) => {
            try {
                setFetchingAccordianHeading(true)
                const response = await axios.get(`${baseURL}/mods/getAssetTypes/${id}`, {
                    headers: {
                        "ngrok-skip-browser-warning": "any",
                        "Content-Type": "multipart/form-data",
                    },
                });
                setAccordianHeading(response.data.data.assetTypes)
            } catch (error) {
                console.error('fetchAccordianHeading Error:', error);
            } finally {
                setFetchingAccordianHeading(false)
            }
        };
        
        fetchAccordianHeading(modsId)
    }, [modsId]); 

  return (
    <section className='w-full bg-white px-4 mt-4'>
        <p className='md:hidden text-2xl font-bold my-6'>Documents</p>
        <div className='w-full max-w-[1600px] pb-10 md:pb-20 mx-auto'>
        {fetchingAccordianHeading ?
            <LoadingAnimation /> :
            accordianHeading?.map((item, index) => (
            <div key={index} className='flex justify-center mx-auto'>
                <Accordion modsId={modsId} item={item} index={(index+1)>9 ? index+1 : `0${index+1}`}/>
            </div>
        ))}
        </div> 
    </section>
  )
}

export default LPviewByDocument