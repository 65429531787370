import { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { BurgerMenuIcon, CloseIcon, ContactEmailIcon, DocumentIcon, MachineOrder, QRcodeIcon, TemplateFolder } from '../Components/Icons';

function Sidebar() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState();
  const [sidebarShow, setSidebarShow] = useState(false);

  const handleMenuClick = () => {
    setSidebarShow(prev => !prev);
  };

  useEffect(() => {
    const path = location.pathname.split('/').filter(Boolean)[0];
    setActiveLink(path ? path : "product_library")
  }, [location.pathname])

  return (
    <div>
      {sidebarShow && <div onClick={handleMenuClick} className="block ml:hidden fixed z-[900] inset-0 w-full top-[65px] md:top-[80px] h-full bg-gray-500 bg-opacity-75 transition-opacity" />}
      <div
        className={`transition-all duration-300 ease-in-out ${sidebarShow && 'w-full md:w-60 fixed inset-0'} w-0 z-[1000] ml:static sidebar h-full min-h-screen ml:w-[10rem] overflow-y-auto text-center bg-gray-900`}
      >
        <div className="bg-white h-[65px] md:h-[80px] ml:pl-[15px] ml:pt-[20px]">
          <img
            className="h-auto w-[135px] hidden ml:block"
            src="/CHOICE-BAGGING-NEW-LOGO-300x93-corrected-2.png"
            alt="Company Logo"
          />
          <div className='z-20 flex ml:hidden absolute bg-white h-[65px] md:h-[80px] pl-[20px] md:pl-[30px] pt-[20px] md:pt-[30px]'
            onClick={handleMenuClick}
          >
            <div className='cursor-pointer'>
            {sidebarShow ? 
              (<CloseIcon />):(<BurgerMenuIcon />)
            }
            </div>
          </div>
        </div>
        <div className='mt-8 z-50 min-w-60 ml:min-w-0'>
          <Link to="/"
            className={`p-5 ${activeLink === "product_library" || activeLink === "assembly_detail" ? "text-gray-50 bg-blue-950" : "text-gray-500"} flex ml:flex-col md:gap-2 ml:gap-0 items-end md:items-center rounded-md px-4 duration-300 cursor-pointer mx-2 hover:text-gray-50`}
            onClick={() => {setActiveLink("product_library"); setSidebarShow(prev => !prev);}}
          >
            <DocumentIcon />
            <span className="text-[15px] ml:mt-3 text-inherit font-bold">Product Library</span>
          </Link>

          <Link to="/qr_list_view"
            className={`p-5 ${(activeLink === "qr_list_view") ? "text-gray-50 bg-blue-950" : "text-gray-500"} flex ml:flex-col md:gap-2 ml:gap-0 items-end md:items-center rounded-md px-4 duration-300 cursor-pointer mx-2 hover:text-gray-50`}
            onClick={() => {setActiveLink("qr_list_view"); setSidebarShow(prev => !prev);}}          
          >
            <QRcodeIcon />
            <span className="text-[15px] ml:mt-3 text-inherit font-bold">QR Codes</span>
          </Link>  

          <Link to="/mods_template"
            className={`p-5 ${(activeLink === "mods_template") ? "text-gray-50 bg-blue-950" : "text-gray-500"} flex ml:flex-col md:gap-2 ml:gap-0 items-end md:items-center rounded-md px-4 duration-300 cursor-pointer mx-2 hover:text-gray-50`}
            onClick={() => {setActiveLink("mods_template"); setSidebarShow(prev => !prev);}}
          >
            <TemplateFolder />
            <span className="text-[15px] ml:mt-3 text-inherit font-bold">MODS Template</span>
          </Link>

          <Link to="/machine_orders"
            className={`p-5 ${(activeLink === "machine_orders") ? "text-gray-50 bg-blue-950" : "text-gray-500"} flex ml:flex-col md:gap-2 ml:gap-0 items-end md:items-center rounded-md px-4 duration-300 cursor-pointer mx-2 hover:text-gray-50`}
            onClick={() => {setActiveLink("machine"); setSidebarShow(prev => !prev);}}
          >
            <MachineOrder />     
            <span className="text-[15px] ml:mt-3 text-inherit font-bold">Machine Orders</span>
          </Link>

          <Link to="/contact_email"
            className={`p-5 ${(activeLink === "contact_email") ? "text-gray-50 bg-blue-950" : "text-gray-500"} flex ml:flex-col md:gap-2 ml:gap-0 items-end md:items-center rounded-md px-4 duration-300 cursor-pointer mx-2 hover:text-gray-50`}
            onClick={() => {setActiveLink("settings"); setSidebarShow(prev => !prev);}}          
          >
            <ContactEmailIcon />
            <span className="text-[15px] ml:mt-3 text-inherit font-bold">Contact Email</span>
          </Link>                  
        </div>
      </div>
    </div>
  )
}

export default Sidebar