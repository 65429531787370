const InputField = ({labelId, labelText, className, errorMessage, ...props }) => {
    return(
        <>
            <label htmlFor={labelId} className="block text-gray-700 font-medium">
            {labelText}
            <input
            id={labelId}
            {...props}            
            className={`block appearance-none w-full h-10 md:h-12 rounded-md border border-gray-300 focus:outline-gray-400 py-2 px-3 md:px-4 text-gray-900 placeholder:text-gray-400 placeholder:capitalize sm:text-sm disabled:bg-gray-100 disabled:cursor-not-allowed read-only:cursor-default sm:leading-6 min-w-32 ${className}`}
            />
            </label>
            <p className="text-red-600 text-sm min-h-5">{errorMessage || ''}</p>
        </>
    )
}

export default InputField;