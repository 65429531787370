import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './Context/AuthContext';
import { Toaster } from 'react-hot-toast';
import { FileSyncProvider } from './Context/FileSyncContext';
import { ProductLibraryProvider } from './Context/ProductLibraryContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ProductLibraryProvider>
          <FileSyncProvider>
            <App />
            <Toaster position="top-center"/>
          </FileSyncProvider>
        </ProductLibraryProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
