export const LPinputField = ({labelText, placeholder, onChange, errorMessage, maxLength, ...props}) => {
    return(
        <>
            <label>
                <p className="block text-sm md:text-base text-gray-400 mb-2">{labelText}</p>
            <input
                placeholder={placeholder}
                onChange={onChange}
                maxLength={maxLength}
                {...props}
                className={`block appearance-none w-full h-10 md:h-12 rounded-md border border-gray-300 focus:outline-gray-400 py-2 px-3 md:px-5 text-gray-900 placeholder:text-gray-400 sm:text-sm disabled:bg-gray-100 disabled:cursor-not-allowed read-only:cursor-default sm:leading-6 md:max-w-[390px] mb-2`}
            />
            </label>
            <p className="text-red-600 text-sm min-h-5 mb-3">{errorMessage || ''}</p>
        </>
    )
}

export const LPtextField = ({labelText, labelId, placeholder, required, onChange, errorMessage, name, ...props}) => {
    return(
        <>
            <label>
                <p className="block text-sm md:text-base text-gray-400 mb-2">{labelText}</p>
            </label>
            <textarea
              id={labelId}
              required={required}
              placeholder={placeholder}
              onChange={onChange}
              name={name}
              {...props}
              rows="4"        
              className={`block w-full rounded-md border border-gray-300 focus:outline-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed py-2 px-3 md:px-5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 md:max-w-[390px] mb-2`}
            />
            <p className="text-red-600 text-sm min-h-5">{errorMessage || ''}</p>
        </>
    )
}

export const LPskeletonLoading = ({max_width}) => {
    return (
      <div className={`animate-pulse w-full ${max_width ? max_width :'min-w-20'}`}>
          <div className='h-4 w-full min-w-20 bg-gray-200 my-2 rounded'></div>
      </div>
    )
  }