import { useContext, useState, useEffect } from 'react'
import { baseURL } from '../config'
import axios from 'axios'
import AuthContext from '../Context/AuthContext'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router';
import { MachineOrderFormValidation } from '../utils/MachineOrderFormValidation'
import UseEditTemplate from '../Components/UseEditTemplate'

function MachineOrderUseTemplate() {
  const [fetchingTemplateDetails, setFetchingTemplateDetails] = useState(true)
  const [templateDetails, setTemplateDetails] = useState([])
  const [originalTemplateDetails, setOriginalTemplateDetails] = useState([])
  const { token } = useContext(AuthContext);
  const [templateModelDesc, setTemplateModelDesc] = useState();
  const [selectedAssembly, setSelectedAssembly] = useState([])
  const [originalSelectedAssembly, setOriginalSelectedAssembly] = useState([])
  const { assembly_id } = useParams();
  const [initialFormSubmit, setInitialFormSubmit] = useState(false)
  const [assignedQR, setAssignedQR] = useState()
  const [qr, setQR] = useState('')
  const [accountNumberOptions, setAccountNumberOptions] = useState([])
  const [savingMachineOrders, setSavingMachineOrders] = useState(false)
  const navigate = useNavigate()
  const originalCustomerData = {
    account_number: '',
    name: '',
    company_name: '',
    primary_contact: '',
    primary_email: '',
    accounting_contact: '',
    accounting_email: '',
    bill_to: '',
    ship_to: '',
    others: '',
    shipment_date: '',
  }
  const originalModsData = {
    sales_order_number: '',
    serial_number: '',
    customer_po: '',
  }

  const [mods_data, setMods_data] = useState({
    sales_order_number: '',
    serial_number: '',
    customer_po: '',
  });
  const [customer_data, setCustomer_data] = useState({
    account_number: '',
    name: '',
    company_name: '',
    primary_contact: '',
    primary_email: '',
    accounting_contact: '',
    accounting_email: '',
    bill_to: '',
    ship_to: '',
    others: '',
    shipment_date: '',
  });
  const [validateFields, setValidateFields] = useState({
    qr: '',
    sales_order_number: '',
    serial_number: '',
    customer_po: '',
    account_number: '',
    name: '',
    company_name: '',
    primary_contact: '',
    accounting_contact: '',
    primary_email: '',
    accounting_email: '',
    bill_to: '',
    ship_to: '',
    shipment_date: '',
    others: '',
  });
  
  const saveMachineOrder = () => {
    setInitialFormSubmit(true)
    const [isAnyEmptyFields, validateFieldsCopy] = MachineOrderFormValidation(qr, mods_data, customer_data, validateFields);
    setValidateFields(validateFieldsCopy)
    if (isAnyEmptyFields) {
      const tableElement = document.getElementById('OrderForn');
      tableElement.scrollIntoView({ behavior: "smooth" });
    } else {
      const createMODS = async () => {
        try {
          setSavingMachineOrders(true)
          const response = await axios.post(`${baseURL}/mods/addMods`,
            { qr: qr,
              mods_data: mods_data,
              customer_data: customer_data,
              template_id: assembly_id,
              assemblies: selectedAssembly
            },
            {headers: {
              "ngrok-skip-browser-warning":"any",
              "x-access-token": token
              }
            }
          );
          if (response.status === 201){
            toast.success('Machine Order Created Successfully')
            navigate('/machine_orders')
          }
        } catch (error) {
          toast.error('Something Went Wrong, Template Not Saved')
        } finally {
          setSavingMachineOrders(false)
        }
      }
      createMODS()
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingTemplateDetails(true);
        const response = await axios.get(`${baseURL}/template/getTemplateById/${assembly_id}`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "Content-Type": "multipart/form-data",
            "x-access-token": token
          },
          params: {
            assembly_id: assembly_id
          }
        });
        setTemplateModelDesc(response.data.data.templateDesc);
        let _assemblyData = response.data.data.templateData
        setOriginalTemplateDetails(_assemblyData);
        setTemplateDetails(_assemblyData);
        const ids = Object.keys(_assemblyData).flatMap(key => (_assemblyData[key]).map((item) => (item._id)));
        setSelectedAssembly([ids[0]])
        setOriginalSelectedAssembly([ids[0]])
      } catch (error) {
          console.error('Error:', error);
      } finally {
        setFetchingTemplateDetails(false);
      }
    };

    fetchData();
  }, [assembly_id, token]);

  useEffect(() => {
    if (initialFormSubmit) {
      setValidateFields(prev => {
        const result = MachineOrderFormValidation(qr, mods_data, customer_data, prev);
        return (result[1])
      })
    }
  }, [qr, mods_data, customer_data, initialFormSubmit]);

  return (
    <UseEditTemplate 
      savingMachineOrders = {savingMachineOrders}
      originalSelectedAssembly = {originalSelectedAssembly}
      templateModelDesc = {templateModelDesc}
      templateDetails={templateDetails}
      setTemplateDetails={setTemplateDetails}
      originalTemplateDetails = {originalTemplateDetails}
      fetchingTemplateDetails = {fetchingTemplateDetails}
      accountNumberOptions = {accountNumberOptions}
      setAccountNumberOptions = {setAccountNumberOptions}
      mods_data = {mods_data}
      setMods_data = {setMods_data}
      customer_data = {customer_data}
      setCustomer_data = {setCustomer_data}
      assignedQR = {assignedQR}
      setAssignedQR = {setAssignedQR}
      validateFields = {validateFields}
      saveChanges = {saveMachineOrder}
      selectedAssembly = {selectedAssembly}
      setSelectedAssembly = {setSelectedAssembly}
      originalCustomerData={originalCustomerData}
      originalModsData={originalModsData}
      qr = {qr}
      setQR = {setQR}
      editOrder = {false}
    />
  )
}

export default MachineOrderUseTemplate