import { SearchIcon } from './Icons'

function Searchbar({className="", fullWidth=false, label='', type='text', placeholder='Search...', ...props}) {
  return (
    <div className={`${fullWidth ? 'w-full' : 'w-full md:w-60'}`}>
      <div className='block font-medium text-gray-700'>{label}</div>
      <div className='relative w-full'>
        <input
            type={type}
            placeholder={placeholder}
            {...props}
            className={`block w-full h-10 md:h-12 focus:outline-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed rounded-md border border-gray-300 py-2 pl-10 pr-5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${className}`}
        />
        <div className='absolute top-0 left-0 p-2 md:p-3'>
            <SearchIcon />
        </div>
      </div>
    </div>
  )
}

export default Searchbar