import React from 'react'

function RadioButton({label, value, onChange, checked, className}) {
  return (
    <label className={`mb-3 text-sm md:text-base ${className}`}>
        <input
            type="radio"
            value={value}
            checked={checked}
            onChange={onChange}
            className='h-4 w-4 mr-2 accent-orange-400 align-sub'
        />
        {label}
    </label>
  )
}

export default RadioButton