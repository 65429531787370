export const CloseIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
    )
}

export const BurgerMenuIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
    )
}

export const NotificationIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
        </svg>
    )
}

export const ToolsIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-inherit mr-3 md:mr-0"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
        </svg>
    )
}

export const DocumentIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-inherit mr-3 md:mr-0"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
        </svg>
    )
}

export const CustomerIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-inherit mr-3 md:mr-0"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
        </svg>
    )
}

export const GearIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-inherit mr-3 md:mr-0"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>
    )
}

export const EditIcon = ({size}) => {
    return (
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : 'w-6 h-6'}>
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
            </svg>
        </span>
    )
}

export const DeleteIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : 'w-6 h-6'}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
    )
}

export const WarningIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-red-500"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
        </svg>
    )
}

export const BackArrowIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-5 h-5 inline-block mb-[2px] mr-1"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
    )
}

export const SearchIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-gray-400"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
    )
}

export const DownArrowIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-4 ml-auto"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
        </svg>
    )
}

export const RightArrowIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 pl-2"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
        </svg>
    )
}

export const UpRightArrowIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-4 h-4"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
        </svg>

    )
}

export const FileUploadIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-14 h-14 mb-4 text-gray-200"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
        </svg>
    )
}

export const FileIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
        </svg>
    )
}

export const QRcodeIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-inherit mr-3 md:mr-0"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z" />
        </svg>
    )
}

export const QRscanner = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={size ? `${size}` : "w-6 h-6"}>
            <path d="M6 3a3 3 0 0 0-3 3v1.5a.75.75 0 0 0 1.5 0V6A1.5 1.5 0 0 1 6 4.5h1.5a.75.75 0 0 0 0-1.5H6ZM16.5 3a.75.75 0 0 0 0 1.5H18A1.5 1.5 0 0 1 19.5 6v1.5a.75.75 0 0 0 1.5 0V6a3 3 0 0 0-3-3h-1.5ZM12 8.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5ZM4.5 16.5a.75.75 0 0 0-1.5 0V18a3 3 0 0 0 3 3h1.5a.75.75 0 0 0 0-1.5H6A1.5 1.5 0 0 1 4.5 18v-1.5ZM21 16.5a.75.75 0 0 0-1.5 0V18a1.5 1.5 0 0 1-1.5 1.5h-1.5a.75.75 0 0 0 0 1.5H18a3 3 0 0 0 3-3v-1.5Z" />
        </svg>
    )
}
export const TemplateFolder = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-inherit mr-3 md:mr-0"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776" />
        </svg>
    )
}

export const MachineOrder = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={size ? `${size}` : "w-6 h-6 text-inherit mr-3 md:mr-0"}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
        </svg>

    )
}

export const Assigned = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={size ? `${size}` : "w-6 h-6 text-yellow-500"}>
            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
        </svg>
    )
}

export const PartsPage = ({size}) => {
    return (
        <svg className={size ? `${size}` : "w-20 h-20 mx-auto mb-4"} viewBox="0 0 63 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.6 0.5C55.05 0.5 52.95 2.6 52.95 5.15C52.95 6.275 53.4 7.325 54.075 8.15L49.05 13.175C48.225 14 47.775 15.125 47.775 16.25V23.225C47.325 23.225 46.8 23.3 46.35 23.6L41.1 26.6V10.25C43.275 9.875 45 8 45 5.675C45 3.125 42.9 1.025 40.35 1.025C37.8 1.025 35.7 3.125 35.7 5.675C35.7 8 37.425 9.875 39.6 10.25V26.675C39.225 26.525 38.85 26.375 38.4 26.225C38.1 26.075 37.875 25.85 37.725 25.475L36.075 19.475C35.775 18.35 34.725 17.525 33.6 17.525H29.325C28.125 17.525 27.15 18.35 26.85 19.475L25.275 25.475C25.2 25.85 24.9 26.075 24.6 26.225L23.475 26.675V10.25C25.65 9.875 27.375 8 27.375 5.675C27.375 3.125 25.275 
            1.025 22.725 1.025C20.175 1.025 18.075 3.125 18.075 5.675C18.075 8 19.725 9.875 21.975 10.25V26.675L16.725 23.675C16.275 23.45 15.75 23.3 15.3 23.375V16.25C15.3 15.05 14.85 14 14.025 13.175L9 8.15C9.675 7.325 10.125 6.275 10.125 5.15C10.125 2.6 8.025 0.5 5.475 0.5C2.85 0.5 0.75 2.6 0.75 5.15C0.75 7.7 2.85 9.8 5.4 9.8C6.3 9.8 7.125 9.575 7.8 9.125L12.9 14.225C13.5 14.75 13.8 15.5 13.8 16.25V23.825C13.725 23.9 13.65 23.9 13.575 23.975L10.575 26.975C9.75 27.8 9.6 29.075 10.125 30.125L13.275 35.525C13.425 35.825 13.425 36.2 13.275 36.575C13.05 37.025 12.9 37.475 12.675 38C12.525 38.375 12.3 38.6 11.925 38.75L5.925 40.325C4.8
            40.625 3.975 41.675 3.975 42.8V47.075C3.975 48.275 4.8 49.25 5.925 49.55L11.925 51.125C12.3 51.2 12.525 51.5 12.675 51.875C12.825 52.325 13.05 52.775 13.275 53.3C13.425 53.675 13.425 54.05 13.275 54.35L10.125 59.75C9.525 60.8 9.75 62.075 10.575 62.9L13.575 65.9C14.4 66.725 15.675 66.875 16.725 66.275L22.125 63.125C22.425 62.975 22.8 62.975 23.175 63.125C23.625 63.35 24.075 63.5 24.6 63.725C24.975 63.875 25.2 64.1 25.275 64.475L26.85 70.475C27.15 71.6 28.2 72.425 29.325 72.425H33.6C34.8 72.425 35.775 71.6 36.075 70.475L37.725 64.475C37.8 64.1 38.025 63.875 38.4 63.725C38.925 63.5 39.45 63.35 39.9 63.125C40.2 62.975 40.575
            62.975 40.875 63.2L46.275 66.35C47.325 66.95 48.6 66.8 49.425 65.975L52.425 62.975C53.25 62.15 53.4 60.875 52.8 59.825L49.65 54.425C49.5 54.125 49.5 53.75 49.65 53.375C49.875 52.925 50.025 52.475 50.25 51.95C50.4 51.575 50.7 51.35 51 51.275L57 49.625C58.125 49.325 58.95 48.275 58.95 47.15V42.875C58.95 41.675 58.2 40.7 57 40.4L51 38.825C50.625 38.75 50.4 38.45 50.25 38.075C50.1 37.625 49.875 37.175 49.65 36.65C49.5 36.275 49.5 35.9 49.65 35.6L52.8 30.2C53.4 29.225 53.25 27.875 52.425 27.05L49.425 24.05C49.35 23.975 49.275 23.9 49.2 23.9V16.25C49.2 15.5 49.5 14.75 50.025 14.225L55.125 9.125C55.8 9.575 56.625 9.8 57.525 9.8C60.075
            9.8 62.175 7.7 62.175 5.15C62.25 2.6 60.15 0.5 57.6 0.5ZM31.5 54.575C26.25 54.575 21.9 50.3 21.9 44.975C21.9 39.725 26.175 35.375 31.5 35.375C36.75 35.375 41.1 39.65 41.1 44.975C41.1 50.3 36.75 54.575 31.5 54.575Z" fill="#181C32"/>
        </svg>
    )
}

export const WrittenManual = ({size}) => {
    return (
        <svg className={size ? `${size}` : "w-20 h-20 mx-auto mb-4"} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M54.4398 63.1098C54.6558 62.8883 54.7754 62.5905 54.7727 62.2812H54.7832V1.34375C54.7832 1.03295 54.6597 0.734878 54.44 0.515109C54.2202 0.29534 53.9221 0.171875 53.6113 0.171875H2.04883C1.73803 0.171875 1.43996 0.29534 1.22019 0.515109C1.00042 0.734878 0.876953 1.03295 0.876953 1.34375V66.9688C0.878814 68.5222 1.49674 70.0115 2.59518 71.1099C3.69362 72.2083 5.1829 72.8263 6.73633 72.8281H53.6113C53.8431 72.8281 54.0696 72.7593 54.2623 72.6305C54.4549 72.5018 54.6051 72.3188 54.6938 72.1047C54.7824 71.8906 54.8056 71.655 54.7604 71.4277C54.7153 71.2004 54.6037 70.9916 54.4398 70.8277L51.7527 68.1406C51.4422 67.8297 51.2677
            67.4082 51.2677 66.9688C51.2677 66.5293 51.4422 66.1078 51.7527 65.7969L54.4398 63.1098ZM38.377 2.51562H45.4082V10.2336L42.7211 7.54648C42.5013 7.32679 42.2033 7.20338 41.8926 7.20338C41.5818 7.20338 41.2838 7.32679 41.0641 7.54648L38.377 10.2336V2.51562ZM10.252 2.51562V61.1094H6.73633C5.46429 61.1137 4.22897 61.5362 3.2207 62.3117V2.51562H10.252ZM49.1172 65.7969H9.08008V68.1406H49.1172C49.3022 68.765 49.6383 69.3341 50.0957 69.7977L50.7824 70.4844H6.73633C5.80393 70.4844 4.90971 70.114 4.25041 69.4547C3.5911 68.7954 3.2207 67.9012 3.2207 66.9688C3.2207 66.0363 3.5911 65.1421 4.25041 64.4828C4.90971 63.8235 5.80393 63.4531 6.73633 
            63.4531H50.7824L50.0957 64.1398C49.6383 64.6034 49.3022 65.1725 49.1172 65.7969ZM12.5957 61.1094V2.51562H36.0332V13.0625C36.0333 13.2942 36.102 13.5208 36.2308 13.7134C36.3595 13.9061 36.5426 14.0563 36.7567 14.1449C36.9708 14.2336 37.2063 14.2568 37.4336 14.2116C37.6609 14.1664 37.8697 14.0549 38.0336 13.891L41.8926 10.032L45.7516 13.891C45.9154 14.0549 46.1242 14.1664 46.3515 14.2116C46.5788 14.2568 46.8144 14.2336 47.0285 14.1449C47.2426 14.0563 47.4256 13.9061 47.5544 13.7134C47.6831 13.5208 47.7519 13.2942 47.752 13.0625V2.51562H52.4395V61.1094H12.5957Z" fill="#181C32"/>
            <path d="M20.7988 32.9844H22.5824C22.6895 33.2867 22.809 33.5828 22.941 33.8727L21.1422 35.6715C20.9225 35.8912 20.7991 36.1893 20.7991 36.5C20.7991 36.8107 20.9225 37.1088 21.1422 37.3285L24.6578 40.8441C24.8776 41.0638 25.1756 41.1872 25.4863 41.1872C25.7971 41.1872 26.0951 41.0638 26.3148 40.8441L28.1137 39.0453C28.4027 39.1773 28.6988 39.2969 29.002 39.4039V41.1875C29.002 41.4983 29.1254 41.7964 29.3452 42.0161C29.565 42.2359 29.863 42.3594 30.1738 42.3594H34.8613C35.1721 42.3594 35.4702 42.2359 35.69 42.0161C35.9097 41.7964 36.0332 41.4983 36.0332 41.1875V39.4039C36.3355 39.2969 36.6316 39.1773 36.9215 39.0453L38.7203 
            40.8441C38.9401 41.0638 39.2381 41.1872 39.5488 41.1872C39.8596 41.1872 40.1576 41.0638 40.3773 40.8441L43.893 37.3285C44.1127 37.1088 44.2361 36.8107 44.2361 36.5C44.2361 36.1893 44.1127 35.8912 43.893 35.6715L42.0941 33.8727C42.2262 33.5836 42.3457 33.2875 42.4527 32.9844H44.2363C44.5471 32.9844 44.8452 32.8609 45.065 32.6411C45.2847 32.4214 45.4082 32.1233 45.4082 31.8125V27.125C45.4082 26.8142 45.2847 26.5161 45.065 26.2964C44.8452 26.0766 44.5471 25.9531 44.2363 25.9531H42.4527C42.3457 25.6508 42.2262 25.3547 42.0941 25.0648L43.893 23.266C44.1127 23.0463 44.2361 22.7482 44.2361 22.4375C44.2361 22.1268 44.1127 21.8287 
            43.893 21.609L40.3773 18.0934C40.1576 17.8737 39.8596 17.7502 39.5488 17.7502C39.2381 17.7502 38.9401 17.8737 38.7203 18.0934L36.9215 19.8922C36.6324 19.7602 36.3363 19.6406 36.0332 19.5336V17.75C36.0332 17.4392 35.9097 17.1411 35.69 16.9214C35.4702 16.7016 35.1721 16.5781 34.8613 16.5781H30.1738C29.863 16.5781 29.565 16.7016 29.3452 16.9214C29.1254 17.1411 29.002 17.4392 29.002 17.75V19.5336C28.6996 19.6406 28.4035 19.7602 28.1137 19.8922L26.3148 18.0934C26.0951 17.8737 25.7971 17.7502 25.4863 17.7502C25.1756 17.7502 24.8776 17.8737 24.6578 18.0934L21.1422 21.609C20.9225 21.8287 20.7991 22.1268 20.7991 22.4375C20.7991 22.7482 
            20.9225 23.0463 21.1422 23.266L22.941 25.0648C22.809 25.3539 22.6895 25.65 22.5824 25.9531H20.7988C20.488 25.9531 20.19 26.0766 19.9702 26.2964C19.7504 26.5161 19.627 26.8142 19.627 27.125V31.8125C19.627 32.1233 19.7504 32.4214 19.9702 32.6411C20.19 32.8609 20.488 32.9844 20.7988 32.9844ZM21.9707 28.2969H23.4496C23.7094 28.2968 23.9617 28.2105 24.1671 28.0514C24.3724 27.8923 24.519 27.6695 24.584 27.418C24.7635 26.7212 25.0346 26.0513 25.3902 25.4258C25.5167 25.2029 25.5666 24.9446 25.5324 24.6907C25.4981 24.4367 25.3815 24.2009 25.2004 24.0195L23.6277 22.4375L25.4863 20.5789L27.059 22.1516C27.2404 22.3326 27.4761 22.4492 27.7301 
            22.4835C27.9841 22.5178 28.2423 22.4679 28.4652 22.3414C29.0907 21.9858 29.7606 21.7147 30.4574 21.5352C30.7089 21.4702 30.9317 21.3236 31.0908 21.1182C31.2499 20.9129 31.3363 20.6605 31.3363 20.4008V18.9219H33.6801V20.4008C33.6801 20.6605 33.7665 20.9129 33.9256 21.1182C34.0847 21.3236 34.3075 21.4702 34.559 21.5352C35.2558 21.7147 35.9257 21.9858 36.5512 22.3414C36.7741 22.4679 37.0323 22.5178 37.2863 22.4835C37.5403 22.4492 37.776 22.3326 37.9574 22.1516L39.5488 20.5789L41.4074 22.4375L39.8348 24.0102C39.6537 24.1915 39.5371 24.4273 39.5028 24.6813C39.4685 24.9353 39.5184 25.1935 39.6449 25.4164C40.0005 26.0419 40.2717 26.7118 
            40.4512 27.4086C40.5161 27.6601 40.6628 27.8829 40.8681 28.042C41.0734 28.2011 41.3258 28.2874 41.5855 28.2875H43.0645V30.6313H41.5855C41.3258 30.6313 41.0734 30.7177 40.8681 30.8768C40.6628 31.0359 40.5161 31.2587 40.4512 31.5102C40.2717 32.2069 40.0005 32.8768 39.6449 33.5023C39.5184 33.7253 39.4685 33.9835 39.5028 34.2375C39.5371 34.4915 39.6537 34.7272 39.8348 34.9086L41.4074 36.5L39.5488 38.3586L37.9762 36.7859C37.7948 36.6049 37.559 36.4883 37.305 36.454C37.0511 36.4197 36.7928 36.4696 36.5699 36.5961C35.9444 36.9517 35.2745 37.2228 34.5777 37.4023C34.3262 37.4673 34.1034 37.6139 33.9443 37.8193C33.7853 38.0246 33.6989 38.277 
            33.6988 38.5367V40.0156H31.3457V38.5367C31.3456 38.277 31.2593 38.0246 31.1002 37.8193C30.9411 37.6139 30.7183 37.4673 30.4668 37.4023C29.77 37.2228 29.1001 36.9517 28.4746 36.5961C28.2517 36.4696 27.9935 36.4197 27.7395 36.454C27.4855 36.4883 27.2497 36.6049 27.0684 36.7859L25.4863 38.3586L23.6277 36.5L25.2004 34.9273C25.3815 34.746 25.4981 34.5102 25.5324 34.2562C25.5666 34.0022 25.5167 33.744 25.3902 33.5211C25.0346 32.8956 24.7635 32.2257 24.584 31.5289C24.519 31.2774 24.3724 31.0546 24.1671 30.8955C23.9617 30.7364 23.7094 30.6501 23.4496 30.65H21.9707V28.2969Z" fill="#181C32"/>
            <path d="M32.5176 35.3281C33.6765 35.3281 34.8093 34.9845 35.7729 34.3406C36.7364 33.6968 37.4875 32.7817 37.9309 31.711C38.3744 30.6404 38.4905 29.4622 38.2644 28.3256C38.0383 27.189 37.4802 26.145 36.6608 25.3255C35.8413 24.5061 34.7973 23.948 33.6607 23.722C32.5241 23.4959 31.346 23.6119 30.2753 24.0554C29.2046 24.4989 28.2895 25.2499 27.6457 26.2135C27.0018 27.177 26.6582 28.3099 26.6582 29.4688C26.6601 31.0222 27.278 32.5115 28.3764 33.6099C29.4749 34.7083 30.9641 35.3263 32.5176 35.3281ZM32.5176 25.9531C33.2129 25.9531 33.8926 26.1593 34.4708 26.5456C35.0489 26.9319 35.4995 27.481 35.7656 28.1234C36.0317 28.7658 36.1013 29.4727 
            35.9657 30.1546C35.83 30.8366 35.4952 31.463 35.0035 31.9547C34.5118 32.4463 33.8854 32.7812 33.2034 32.9168C32.5215 33.0525 31.8146 32.9829 31.1722 32.7168C30.5298 32.4507 29.9807 32.0001 29.5944 31.4219C29.2081 30.8438 29.002 30.1641 29.002 29.4688C29.002 28.5363 29.3724 27.6421 30.0317 26.9828C30.691 26.3235 31.5852 25.9531 32.5176 25.9531ZM48.9238 44.7031H16.1113C15.8005 44.7031 15.5025 44.8266 15.2827 45.0464C15.0629 45.2661 14.9395 45.5642 14.9395 45.875V55.25C14.9395 55.5608 15.0629 55.8589 15.2827 56.0786C15.5025 56.2984 15.8005 56.4219 16.1113 56.4219H48.9238C49.2346 56.4219 49.5327 56.2984 49.7525 56.0786C49.9722 55.8589 
            50.0957 55.5608 50.0957 55.25V45.875C50.0957 45.5642 49.9722 45.2661 49.7525 45.0464C49.5327 44.8266 49.2346 44.7031 48.9238 44.7031ZM47.752 54.0781H17.2832V47.0469H47.752V54.0781Z" fill="#181C32"/>
            <path d="M19.627 49.3906H45.4082V51.7344H19.627V49.3906Z" fill="#181C32"/>
        </svg>
    )
}

export const PneumaticSchematic = ({size}) => {
    return (
        <svg className={size ? `${size}` : "w-20 h-20 mx-auto mb-4"} viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.375 16.4062C13.2583 16.4062 16.4062 13.2583 16.4062 9.375C16.4062 5.49175 13.2583 2.34375 9.375 2.34375C5.49175 2.34375 2.34375 5.49175 2.34375 9.375C2.34375 13.2583 5.49175 16.4062 9.375 16.4062Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 16.4062C39.0395 16.4062 42.1875 13.2583 42.1875 9.375C42.1875 5.49175 39.0395 2.34375 35.1562 2.34375C31.273 2.34375 28.125 5.49175 28.125 9.375C28.125 13.2583 31.273 16.4062 35.1562 16.4062Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M9.375 44.5312C13.2583 44.5312 16.4062 41.3833 16.4062 37.5C16.4062 33.6167 13.2583 30.4688 9.375 30.4688C5.49175 30.4688 2.34375 33.6167 2.34375 37.5C2.34375 41.3833 5.49175 44.5312 9.375 44.5312Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 44.5312C39.0395 44.5312 42.1875 41.3833 42.1875 37.5C42.1875 33.6167 39.0395 30.4688 35.1562 30.4688C31.273 30.4688 28.125 33.6167 28.125 37.5C28.125 41.3833 31.273 44.5312 35.1562 44.5312Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 72.6562C39.0395 72.6562 42.1875 69.5083 42.1875 65.625C42.1875 61.7417 39.0395 58.5938 35.1562 58.5938C31.273 58.5938 28.125 61.7417 28.125 65.625C28.125 69.5083 31.273 72.6562 35.1562 72.6562Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M9.375 72.6562C13.2583 72.6562 16.4062 69.5083 16.4062 65.625C16.4062 61.7417 13.2583 58.5938 9.375 58.5938C5.49175 58.5938 2.34375 61.7417 2.34375 65.625C2.34375 69.5083 5.49175 72.6562 9.375 72.6562Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M16.4062 9.375H28.125" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M16.4062 37.5H28.125" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M16.4062 65.625H28.125" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M9.375 16.4062V30.4688" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 16.4062V30.4688" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 44.5312V58.5938" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M9.375 44.5312V58.5938" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M49.2188 52.7344H56.25V26.9531H49.2188V52.7344Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M56.25 52.7344H63.2812V29.2969H56.25V52.7344Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M63.2812 52.7344H72.6562V22.2656H63.2812V52.7344Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M63.2812 45.7031H72.6562" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M49.2188 45.7031H56.25" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M56.25 33.9844H63.2812" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M45.7031 9.375H60.9375V18.75" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M45.7031 65.625H60.9375V56.25" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
        </svg>
    )
}

export const ElectricalSchematic = ({size}) => {
    return (
        <svg className={size ? `${size}` : "w-20 h-20 mx-auto mb-4"} viewBox="0 0 68 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.12891 66.9687L12.6289 59.4688C14.8942 60.7926 17.535 61.3268 20.1368 60.9875C22.7385 60.6481 25.154 59.4544 27.0039 57.5937L35.4414 49.125L32.3164 46L39.4727 38.8438L35.0664 34.4375L27.9102 41.5938L26.0664 39.75L33.2227 32.5938L28.8164 28.1875L21.6602 35.3438L18.5352 
            32.2188L10.0664 40.6562C8.21982 42.5146 7.03583 44.9288 6.69699 47.5265C6.35815 50.1243 6.88328 52.7614 8.19141 55.0312L0.691406 62.5312L5.12891 66.9687ZM14.5039 45.0938L18.5352 41.0312L26.6289 49.125L22.5664 53.1562C21.4931 54.2295 20.0374 54.8325 18.5195 54.8325C17.0017 54.8325 
            15.546 54.2295 14.4727 53.1562C13.3994 52.083 12.7964 50.6273 12.7964 49.1094C12.7964 47.5915 13.3994 46.1358 14.4727 45.0625L14.5039 45.0938ZM55.6914 7.53125C53.4216 6.22312 50.7845 5.69799 48.1867 6.03683C45.5889 6.37567 43.1747 7.55967 41.3164 9.40625L32.8789 17.875L49.7852 
            34.7812L58.2539 26.3125C60.1005 24.4542 61.2845 22.04 61.6233 19.4422C61.9622 16.8444 61.437 14.2073 60.1289 11.9375L67.6289 4.4375L63.1914 0.03125L55.6914 7.53125ZM53.8164 21.9062L49.7852 25.9688L41.6914 17.875L45.7227 13.8438C46.796 12.7705 48.2517 12.1675 49.7695 12.1675C51.2874 
            12.1675 52.7431 12.7705 53.8164 13.8438C54.8897 14.917 55.4927 16.3728 55.4927 17.8906C55.4927 19.4085 54.8897 20.8642 53.8164 21.9375V21.9062Z" fill="#181C32"/>
        </svg>
    )
}

export const VideoTutorial = ({size}) => {
    return (
        <svg className={size ? `${size}` : "w-20 h-20 mx-auto mb-4"} viewBox="0 0 76 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.37062 0C1.60913 0 0.330078 1.27905 0.330078 3.04054V61.8243C0.330078 63.5858 1.60913 64.8649 3.37062 64.8649H72.2895C74.051 64.8649 75.3301 63.5858 75.3301 61.8243V3.04054C75.3301 1.27905 74.051 0 72.2895 0H3.37062ZM2.35711 61.8243V3.04054C2.35711 2.40608 2.73616 2.02703 
            3.37062 2.02703H14.5193V62.8378H3.37062C2.73616 62.8378 2.35711 62.4588 2.35711 61.8243ZM16.5463 62.8378V2.02703H59.1139V62.8378H16.5463ZM73.3031 3.04054V61.8243C73.3031 62.4588 72.924 62.8378 72.2895 62.8378H61.1409V2.02703H72.2895C72.924 2.02703 73.3031 2.40608 73.3031 3.04054Z" fill="#181C32"/>
            <path d="M30.2835 20.4405C30.1305 20.3389 29.9529 20.2805 29.7695 20.2715C29.5861 20.2626 29.4036 20.3035 29.2416 20.3899C29.0805 20.4771 28.9459 20.6062 28.8521 20.7635C28.7582 20.9208 28.7086 21.1006 28.7085 21.2838V45.6081C28.7086 45.7914 28.7584 45.9713 28.8526 46.1286C28.9468 
            46.2858 29.082 46.4146 29.2436 46.5011C29.4052 46.5876 29.5873 46.6287 29.7704 46.6199C29.9535 46.6112 30.1308 46.5529 30.2835 46.4514L48.5267 34.2892C48.6651 34.1963 48.7785 34.0708 48.8569 33.9238C48.9353 33.7767 48.9763 33.6126 48.9763 33.4459C48.9763 33.2793 48.9353 33.1152 48.8569 
            32.9681C48.7785 32.8211 48.6651 32.6956 48.5267 32.6027L30.2835 20.4405ZM30.7355 43.7149V23.177L46.1389 33.4459L30.7355 43.7149ZM10.4652 16.2162H6.41116C6.14236 16.2162 5.88457 16.323 5.6945 16.5131C5.50443 16.7031 5.39764 16.9609 5.39764 17.2297C5.39764 17.4985 5.50443 17.7563 5.6945 
            17.9464C5.88457 18.1365 6.14236 18.2432 6.41116 18.2432H10.4652C10.734 18.2432 10.9918 18.1365 11.1819 17.9464C11.3719 17.7563 11.4787 17.4985 11.4787 17.2297C11.4787 16.9609 11.3719 16.7031 11.1819 16.5131C10.9918 16.323 10.734 16.2162 10.4652 16.2162ZM6.41116 10.1351H10.4652C10.734 
            10.1351 10.9918 10.0284 11.1819 9.83828C11.3719 9.64821 11.4787 9.39042 11.4787 9.12162C11.4787 8.85282 11.3719 8.59503 11.1819 8.40496C10.9918 8.21489 10.734 8.10811 10.4652 8.10811H6.41116C6.14236 8.10811 5.88457 8.21489 5.6945 8.40496C5.50443 8.59503 5.39764 8.85282 5.39764 9.12162C5.39764 
            9.39042 5.50443 9.64821 5.6945 9.83828C5.88457 10.0284 6.14236 10.1351 6.41116 10.1351ZM10.4652 24.3243H6.41116C6.14236 24.3243 5.88457 24.4311 5.6945 24.6212C5.50443 24.8112 5.39764 25.069 5.39764 25.3378C5.39764 25.6066 5.50443 25.8644 5.6945 26.0545C5.88457 26.2446 6.14236 26.3514 6.41116 
            26.3514H10.4652C10.734 26.3514 10.9918 26.2446 11.1819 26.0545C11.3719 25.8644 11.4787 25.6066 11.4787 25.3378C11.4787 25.069 11.3719 24.8112 11.1819 24.6212C10.9918 24.4311 10.734 24.3243 10.4652 24.3243ZM10.4652 32.4324H6.41116C6.14236 32.4324 5.88457 32.5392 5.6945 32.7293C5.50443 32.9194 
            5.39764 33.1771 5.39764 33.4459C5.39764 33.7147 5.50443 33.9725 5.6945 34.1626C5.88457 34.3527 6.14236 34.4595 6.41116 34.4595H10.4652C10.734 34.4595 10.9918 34.3527 11.1819 34.1626C11.3719 33.9725 11.4787 33.7147 11.4787 33.4459C11.4787 33.1771 11.3719 32.9194 11.1819 32.7293C10.9918 32.5392 
            10.734 32.4324 10.4652 32.4324ZM10.4652 40.5405H6.41116C6.14236 40.5405 5.88457 40.6473 5.6945 40.8374C5.50443 41.0275 5.39764 41.2852 5.39764 41.5541C5.39764 41.8229 5.50443 42.0806 5.6945 42.2707C5.88457 42.4608 6.14236 42.5676 6.41116 42.5676H10.4652C10.734 42.5676 10.9918 42.4608 11.1819 
            42.2707C11.3719 42.0806 11.4787 41.8229 11.4787 41.5541C11.4787 41.2852 11.3719 41.0275 11.1819 40.8374C10.9918 40.6473 10.734 40.5405 10.4652 40.5405ZM10.4652 48.6486H6.41116C6.14236 48.6486 5.88457 48.7554 5.6945 48.9455C5.50443 49.1356 5.39764 49.3934 5.39764 49.6622C5.39764 49.931 5.50443 
            50.1888 5.6945 50.3788C5.88457 50.5689 6.14236 50.6757 6.41116 50.6757H10.4652C10.734 50.6757 10.9918 50.5689 11.1819 50.3788C11.3719 50.1888 11.4787 49.931 11.4787 49.6622C11.4787 49.3934 11.3719 49.1356 11.1819 48.9455C10.9918 48.7554 10.734 48.6486 10.4652 48.6486ZM10.4652 56.7568H6.41116C6.14236 
            56.7568 5.88457 56.8635 5.6945 57.0536C5.50443 57.2437 5.39764 57.5015 5.39764 57.7703C5.39764 58.0391 5.50443 58.2969 5.6945 58.4869C5.88457 58.677 6.14236 58.7838 6.41116 58.7838H10.4652C10.734 58.7838 10.9918 58.677 11.1819 58.4869C11.3719 58.2969 11.4787 58.0391 11.4787 57.7703C11.4787 57.5015 
            11.3719 57.2437 11.1819 57.0536C10.9918 56.8635 10.734 56.7568 10.4652 56.7568ZM65.1949 18.2432H69.249C69.5178 18.2432 69.7756 18.1365 69.9657 17.9464C70.1557 17.7563 70.2625 17.4985 70.2625 17.2297C70.2625 16.9609 70.1557 16.7031 69.9657 16.5131C69.7756 16.323 69.5178 16.2162 69.249 
            16.2162H65.1949C64.9261 16.2162 64.6683 16.323 64.4783 16.5131C64.2882 16.7031 64.1814 16.9609 64.1814 17.2297C64.1814 17.4985 64.2882 17.7563 64.4783 17.9464C64.6683 18.1365 64.9261 18.2432 65.1949 18.2432ZM65.1949 10.1351H69.249C69.5178 10.1351 69.7756 10.0284 69.9657 9.83828C70.1557 9.64821 
            70.2625 9.39042 70.2625 9.12162C70.2625 8.85282 70.1557 8.59503 69.9657 8.40496C69.7756 8.21489 69.5178 8.10811 69.249 8.10811H65.1949C64.9261 8.10811 64.6683 8.21489 64.4783 8.40496C64.2882 8.59503 64.1814 8.85282 64.1814 9.12162C64.1814 9.39042 64.2882 9.64821 64.4783 9.83828C64.6683 10.0284 
            64.9261 10.1351 65.1949 10.1351ZM65.1949 26.3514H69.249C69.5178 26.3514 69.7756 26.2446 69.9657 26.0545C70.1557 25.8644 70.2625 25.6066 70.2625 25.3378C70.2625 25.069 70.1557 24.8112 69.9657 24.6212C69.7756 24.4311 69.5178 24.3243 69.249 24.3243H65.1949C64.9261 24.3243 64.6683 24.4311 64.4783 
            24.6212C64.2882 24.8112 64.1814 25.069 64.1814 25.3378C64.1814 25.6066 64.2882 25.8644 64.4783 26.0545C64.6683 26.2446 64.9261 26.3514 65.1949 26.3514ZM65.1949 34.4595H69.249C69.5178 34.4595 69.7756 34.3527 69.9657 34.1626C70.1557 33.9725 70.2625 33.7147 70.2625 33.4459C70.2625 33.1771 70.1557 
            32.9194 69.9657 32.7293C69.7756 32.5392 69.5178 32.4324 69.249 32.4324H65.1949C64.9261 32.4324 64.6683 32.5392 64.4783 32.7293C64.2882 32.9194 64.1814 33.1771 64.1814 33.4459C64.1814 33.7147 64.2882 33.9725 64.4783 34.1626C64.6683 34.3527 64.9261 34.4595 65.1949 34.4595ZM65.1949 42.5676H69.249C69.5178 
            42.5676 69.7756 42.4608 69.9657 42.2707C70.1557 42.0806 70.2625 41.8229 70.2625 41.5541C70.2625 41.2852 70.1557 41.0275 69.9657 40.8374C69.7756 40.6473 69.5178 40.5405 69.249 40.5405H65.1949C64.9261 40.5405 64.6683 40.6473 64.4783 40.8374C64.2882 41.0275 64.1814 41.2852 64.1814 41.5541C64.1814 41.8229 
            64.2882 42.0806 64.4783 42.2707C64.6683 42.4608 64.9261 42.5676 65.1949 42.5676ZM65.1949 50.6757H69.249C69.5178 50.6757 69.7756 50.5689 69.9657 50.3788C70.1557 50.1888 70.2625 49.931 70.2625 49.6622C70.2625 49.3934 70.1557 49.1356 69.9657 48.9455C69.7756 48.7554 69.5178 48.6486 69.249 
            48.6486H65.1949C64.9261 48.6486 64.6683 48.7554 64.4783 48.9455C64.2882 49.1356 64.1814 49.3934 64.1814 49.6622C64.1814 49.931 64.2882 50.1888 64.4783 50.3788C64.6683 50.5689 64.9261 50.6757 65.1949 50.6757ZM69.249 56.7568H65.1949C64.9261 56.7568 64.6683 56.8635 64.4783 57.0536C64.2882 57.2437 64.1814 
            57.5015 64.1814 57.7703C64.1814 58.0391 64.2882 58.2969 64.4783 58.4869C64.6683 58.677 64.9261 58.7838 65.1949 58.7838H69.249C69.5178 58.7838 69.7756 58.677 69.9657 58.4869C70.1557 58.2969 70.2625 58.0391 70.2625 57.7703C70.2625 57.5015 70.1557 57.2437 69.9657 57.0536C69.7756 56.8635 69.5178 56.7568 
            69.249 56.7568Z" fill="#181C32"/>
        </svg>
    )
}

export const ControlFiller = ({size}) => {
    return (
        <svg className={size ? `${size}` : "w-20 h-20 mx-auto mb-4"} viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83849 49.0391C5.83849 50.0045 6.65532 50.8213 7.62067 50.8213C8.58601 50.8213 9.40285 50.0045 9.40285 49.0391V13.4698C12.2989 12.7272 14.4524 10.054 14.4524 6.93516C14.4524 3.22229 11.4078 0.177734 7.69493 0.177734C3.98205 0.177734 0.9375 3.22229 0.9375 6.93516C0.9375 10.054 3.01671 12.653 
            5.83849 13.4698V49.0391ZM4.4276 6.93516C4.4276 5.15298 5.83849 3.74209 7.62067 3.74209C9.40285 3.74209 10.8137 5.15298 10.8137 6.93516C10.8137 8.71734 9.40285 10.1282 7.62067 10.1282C5.83849 10.1282 4.4276 8.71734 4.4276 6.93516ZM31.1603 0.177734C30.1949 0.177734 29.3781 0.994566 29.3781 1.95991V20.3758C26.5563 
            21.1926 24.4771 23.7916 24.4771 26.9104C24.4771 30.0292 26.5563 32.6282 29.3781 33.4451V49.0391C29.3781 50.0045 30.1949 50.8213 31.1603 50.8213C32.1256 50.8213 32.9425 50.0045 32.9425 49.0391V33.4451C35.8385 32.7025 37.992 30.0292 37.992 26.9104C37.992 23.7916 35.8385 21.1183 32.9425 20.3758V1.95991C32.9425 
            0.994566 32.1256 0.177734 31.1603 0.177734ZM34.4276 26.9104C34.4276 28.6926 33.0167 30.1035 31.2345 30.1035C29.4524 30.1035 28.0415 28.6926 28.0415 26.9104C28.0415 25.1282 29.4524 23.7173 31.2345 23.7173C32.9425 23.7173 34.4276 25.1282 34.4276 26.9104ZM56.4078 37.5292V1.95991C56.4078 0.994566 55.591 0.177734 
            54.6256 0.177734C53.6603 0.177734 52.8434 0.994566 52.8434 1.95991V37.5292C49.9474 38.3461 47.8682 40.9451 47.8682 44.0639C47.8682 47.7767 50.9127 50.8213 54.6256 50.8213C58.3385 50.8213 61.383 47.7767 61.383 44.0639C61.4573 40.9451 59.3038 38.2718 56.4078 37.5292ZM54.6256 47.2569C52.8434 47.2569 51.4326 45.8461 
            51.4326 44.0639C51.4326 42.2817 52.8434 40.8708 54.6256 40.8708C56.4078 40.8708 57.8187 42.2817 57.8187 44.0639C57.8929 45.7718 56.4078 47.2569 54.6256 47.2569Z" fill="#181C32"/>
        </svg>
    )
}


export const ControlPackages = ({size}) => {
    return (
        <svg className={size ? `${size}` : "w-20 h-20 mx-auto mb-4"}  viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.375 16.4062C13.2583 16.4062 16.4062 13.2583 16.4062 9.375C16.4062 5.49175 13.2583 2.34375 9.375 2.34375C5.49175 2.34375 2.34375 5.49175 2.34375 9.375C2.34375 13.2583 5.49175 16.4062 9.375 16.4062Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 16.4062C39.0395 16.4062 42.1875 13.2583 42.1875 9.375C42.1875 5.49175 39.0395 2.34375 35.1562 2.34375C31.273 2.34375 28.125 5.49175 28.125 9.375C28.125 13.2583 31.273 16.4062 35.1562 16.4062Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M9.375 44.5312C13.2583 44.5312 16.4062 41.3833 16.4062 37.5C16.4062 33.6167 13.2583 30.4688 9.375 30.4688C5.49175 30.4688 2.34375 33.6167 2.34375 37.5C2.34375 41.3833 5.49175 44.5312 9.375 44.5312Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 44.5312C39.0395 44.5312 42.1875 41.3833 42.1875 37.5C42.1875 33.6167 39.0395 30.4688 35.1562 30.4688C31.273 30.4688 28.125 33.6167 28.125 37.5C28.125 41.3833 31.273 44.5312 35.1562 44.5312Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 72.6562C39.0395 72.6562 42.1875 69.5083 42.1875 65.625C42.1875 61.7417 39.0395 58.5938 35.1562 58.5938C31.273 58.5938 28.125 61.7417 28.125 65.625C28.125 69.5083 31.273 72.6562 35.1562 72.6562Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M9.375 72.6562C13.2583 72.6562 16.4062 69.5083 16.4062 65.625C16.4062 61.7417 13.2583 58.5938 9.375 58.5938C5.49175 58.5938 2.34375 61.7417 2.34375 65.625C2.34375 69.5083 5.49175 72.6562 9.375 72.6562Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M16.4062 9.375H28.125" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M16.4062 37.5H28.125" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M16.4062 65.625H28.125" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M9.375 16.4062V30.4688" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 16.4062V30.4688" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M35.1562 44.5312V58.5938" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M9.375 44.5312V58.5938" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M49.2188 52.7344H56.25V26.9531H49.2188V52.7344Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M56.25 52.7344H63.2812V29.2969H56.25V52.7344Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M63.2812 52.7344H72.6562V22.2656H63.2812V52.7344Z" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M63.2812 45.7031H72.6562" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M49.2188 45.7031H56.25" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M56.25 33.9844H63.2812" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M45.7031 9.375H60.9375V18.75" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
            <path d="M45.7031 65.625H60.9375V56.25" stroke="#181C32" strokeWidth="2.34375" strokeLinejoin="round"/>
        </svg>
    )
}

export const SvgArrow = () => {
    return (
        <svg width="135" height="54" viewBox="0 0 135 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M135 48L125 53.7735V42.2265L135 48ZM126 49H9V47H126V49ZM-5.96046e-06 40V0H1.99999V40H-5.96046e-06ZM9 49C4.02943 49 -5.96046e-06 44.9706 -5.96046e-06 40H1.99999C1.99999 43.866 5.134 47 9 47V49Z" fill="#515BA5"/>
        </svg>
    )
}
export const SvgArrow2 = () => {
    return (
        <svg width="135" height="117" viewBox="0 0 135 117" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M135 111L125 116.774V105.227L135 111ZM126 112H9V110H126V112ZM-5.96046e-06 103V0H1.99999V103H-5.96046e-06ZM9 112C4.02943 112 -5.96046e-06 107.971 -5.96046e-06 103H1.99999C1.99999 106.866 5.134 110 9 110V112Z" fill="#515BA5"/>
        </svg>
    )
}

export const SvgArrowMobile = () => {
    return (
        <svg width="17" height="40" viewBox="0 0 17 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 34L7 39.7735V28.2265L17 34ZM-7.89762e-07 26V0H2V26H-7.89762e-07ZM9 35C4.02944 35 -7.89762e-07 30.9706 -7.89762e-07 26H2C2 29.866 5.13401 33 9 33V35Z" fill="#515BA5"/>
        </svg>

    )
}

export const SvgArrowMobile2 = () => {
    return (
        <svg width="17" height="108" viewBox="0 0 17 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 102L7 107.774V96.2265L17 102ZM-7.89762e-07 94V0H2V94H-7.89762e-07ZM9 103C4.02944 103 -7.89762e-07 98.9706 -7.89762e-07 94H2C2 97.866 5.13401 101 9 101V103Z" fill="#515BA5"/>
        </svg>
    )
}

export const FileIconBlue = () => {
    return (
        <svg width="18" height="22" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66683 0.666687H2.00016C1.07933 0.666687 0.34183 1.41252 0.34183 2.33335L0.333496 15.6667C0.333496 16.5875 1.071 17.3334 1.99183 17.3334H12.0002C12.921 17.3334 13.6668 16.5875 13.6668 15.6667V5.66669L8.66683 0.666687ZM10.3335 14H3.66683V12.3334H10.3335V14ZM10.3335 10.6667H3.66683V9.00002H10.3335V10.6667ZM7.8335 6.50002V1.91669L12.4168 6.50002H7.8335Z" fill="#375EFF"/>
        </svg>
    )
}

export const DownloadIcon = ({size}) => {
    return (
        <svg className={size ? `${size}` : "w-6 h-6"} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5674 7.5L8.06738 12L3.56738 7.5" stroke="#000A33" strokeMiterlimit="10"/>
            <path d="M8.06738 0V12" stroke="#000A33" strokeMiterlimit="10"/>
            <path d="M3.5 15.25H12.5" stroke="#000A33" strokeMiterlimit="10"/>
        </svg>
    )
}

export const CloseCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 text-gray-400">
            <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8 5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06Z" clipRule="evenodd" />
        </svg>
    )
}

export const AccordionArrow = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2959 11.776C14.1039 11.968 13.8159 11.968 13.6239 11.776L10.0959 8.248L6.37588 11.968C6.18388 12.16 5.89588 12.16 5.70388 11.968C5.51188 11.776 5.51188 11.488 5.70388 11.296L9.75988 7.24C9.95188 7.048 10.2639 7.048 10.4319 7.24L14.2959 11.104C14.4879 11.296 14.4879 11.584 14.2959 11.776ZM19.1199 10C19.1199 15.04 15.0399 19.12 9.99988 19.12C4.95988 19.12 0.879883 15.04 0.879883 10C0.879883 4.96 4.95988 0.880005 9.99988 0.880005C15.0399 0.880005 19.1199 4.96 19.1199 10ZM18.1599 10C18.1599 5.48801 14.4879 1.84 9.99988 1.84C5.48788 1.84 1.83988 5.48801 1.83988 10C1.83988 14.512 5.48788 18.16 9.99988 18.16C14.4879 18.16 18.1599 14.512 18.1599 10Z" fill="#000A33"/>
        </svg>
    )
}

export const ContactUsIcon = () => {
    return (
        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46.2225 0.999952C53.9498 1.07395 60.9238 7.77262 60.9998 15.7773C61.0325 25.958 61.0952 36.1386 60.9985 46.318C60.8772 53.9726 54.2272 60.924 46.2225 61C36.0418 61.0326 25.8612 61.0953 15.6818 60.9986C8.02849 60.8773 1.07649 54.2273 0.999824 46.2226C0.967824 36.042 0.90449 25.8613 1.00116 15.682C1.12249 8.02729 7.77249 1.07662 15.7772 0.999952C25.9258 0.967952 36.0738 0.967952 46.2225 0.999952ZM15.8765 4.99995C10.2365 5.01795 5.05649 9.85729 4.99982 15.8026C4.96782 25.958 4.90382 36.1133 5.00049 46.2673C5.08916 51.856 9.94382 56.944 15.8025 57C25.9578 57.0326 36.1132 57.0953 46.2672 56.9993C51.8592 56.91 56.9438 52.056 56.9998 46.1973C57.0325 36.042 57.0952 25.8866 56.9992 15.7326C56.9098 10.1413 52.0632 5.05595 46.1972 4.99995C36.0905 4.96795 25.9832 4.99995 15.8765 4.99995Z" fill="#375EFF"/>
            <path d="M31.1659 9.27663C38.4519 9.41463 44.7805 16.5233 43.7419 23.9386C42.8919 30.0026 37.1605 35.0086 30.9999 35.0086C23.5872 35.0086 16.9632 27.5333 18.3345 19.8673C19.3719 14.066 24.7319 9.39263 30.8339 9.27663C30.9999 9.2753 30.9999 9.2753 31.1659 9.27663ZM30.8852 13.276C25.0259 13.3873 20.2645 20.2946 23.0072 25.9826C25.4105 30.9686 32.9679 32.514 37.1152 28.5593C41.9632 23.9373 39.3999 13.434 31.1145 13.276C30.9999 13.2753 30.9999 13.2753 30.8852 13.276ZM31.1985 37.676C39.0845 37.7846 46.6832 42.4746 50.1959 49.6973C50.9352 51.2173 49.4392 53.262 47.7312 52.5833C46.4639 52.0793 46.1199 50.412 45.3072 49.2266C40.4012 42.0693 29.7719 39.5546 22.1359 44.1133C19.1985 45.8666 16.8165 48.5033 15.3319 51.5913C15.3319 51.5913 14.6505 52.6733 13.6252 52.7226C12.0572 52.7973 11.0619 51.068 12.1425 49.0313C15.7665 42.198 23.2512 37.64 31.1985 37.676Z" fill="#375EFF"/>
        </svg>
    )
}

export const CopyLink = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.00044 6.12787L6 15.75C6 17.7382 7.5473 19.3651 9.50344 19.492L9.75 19.5L16.3716 19.5011C16.0624 20.3744 15.2293 21 14.25 21H9C6.51472 21 4.5 18.9853 4.5 16.5V8.25C4.5 7.27017 5.12631 6.43663 6.00044 6.12787ZM17.25 3C18.4926 3 19.5 4.00736 19.5 5.25V15.75C19.5 16.9926 18.4926 18 17.25 18H9.75C8.50736 18 7.5 16.9926 7.5 15.75V5.25C7.5 4.00736 8.50736 3 9.75 3H17.25Z" fill="#7E8299"/>
        </svg>
    )
}

export const CheckCircle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>

    )
}

export const ProfileCircle = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        </svg>
    )
}

export const ContactEmailIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-3 md:mr-0">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
        </svg>

    )
}
// FileIcon.propTypes = {}

