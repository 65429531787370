import { useState } from 'react'
import { DownArrowIcon } from './Icons';

function Dropdown({value=[], selectedValue, setSelectedValue, disabled=false, placeholder = '', label=''}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='relative rounded-lg w-full md:w-60'>
      <div className='block font-medium text-gray-700'>{label}</div>
      <button 
        onClick={() => setIsOpen(prev => !prev)} 
        onBlur={() => {setIsOpen(false)}}
        disabled={disabled}
        className={`bg-white text-gray-500 w-full flex justify-between items-center ${isOpen ? 'border-2 border-gray-400': 'border border-gray-300'} disabled:bg-gray-100 disabled:cursor-not-allowed rounded-lg h-10 md:h-12 px-4`}>
        {isOpen && 
        <ul className='bg-white absolute top-full mt-3 left-0 border max-h-60 overflow-y-auto w-full md:max-w-60 z-10'>
          {value.map((item, i) => (
          <li 
            key={i} 
            onClick={() => setSelectedValue(item)} 
            className='hover:bg-gray-200 cursor-pointer text-start px-4 py-2'>{item}
          </li>
        ))}
        </ul>}
        {selectedValue ? selectedValue : placeholder}
        <div className='ml-auto pl-2'>
          <div className={`transform transition-all duration-300 ease-in-out ${isOpen && 'rotate-180'}`}>
            <DownArrowIcon />
          </div>
        </div>
      </button>
    </div>
  )
}

export default Dropdown;