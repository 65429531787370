import { useState } from 'react'
import _ from 'lodash'
import { DownArrowIcon } from './Icons';

export const SetLimit = ({setCurrentPage, limitValue, setLimitValue}) => {
  const [isOpen, setIsOpen] = useState(false)
  const limitOption=[50, 30, 20, 10]

  const handleSetLimitValue = (item) => {
    setLimitValue(item)
    setCurrentPage(1)
  }
  return (
    <div className='relative flex items-center md:px-5 rounded-lg'>
        <span>Rows per page</span>
        <button tabIndex={0} onClick={() => setIsOpen(!isOpen)} onBlur={() => {setIsOpen(false)}} className='bg-white h-10 pl-2'>
        {limitValue}
          {isOpen && 
          <ul className='bg-white absolute bottom-12 border border-orange-200'>
          {limitOption.map((item, i) => (
              <li key={i} onClick={() => handleSetLimitValue(item)} className='w-full hover:bg-gray-200 cursor-pointer p-4'>{item}</li>
          ))}
          </ul>}
          <span className='inline-block align-middle pl-1'>
            <div className={`transform transition-all duration-300 ease-in-out ${isOpen ?'' : '-rotate-180'}`}>
              <DownArrowIcon />
            </div>
          </span>
        </button>
    </div>
  )
}

export const Pagination = ({totalPages, currentPage, siblings, onPageChange}) => {
    const returnPaginationRange = () => {
        let totalPageNoArray = 7 + siblings;
        if (totalPageNoArray >= totalPages) {
            return _.range(1, totalPages + 1);
        }
        let leftSiblingsIndex = Math.max(currentPage - siblings, 1);
        let rightSiblingsIndex = Math.min(currentPage + siblings, totalPages);
    
        let showLeftDots = leftSiblingsIndex > 2;
        let showRightDots = rightSiblingsIndex < totalPages - 2;
    
        if(!showLeftDots && showRightDots) {
            let leftItemsCount = 3 + 2 * siblings;
            let leftRange = _.range(1, leftItemsCount + 1);
            return [...leftRange, " ...", totalPages];
        } else if (showLeftDots && !showRightDots) {
            let rightItemsCount = 3 + 2 * siblings;
            let rightRange = _.range(totalPages - rightItemsCount + 1, totalPages + 1);
            return [1, "... ", ...rightRange];
        } else {
            let middleRange = _.range(leftSiblingsIndex, rightSiblingsIndex + 1);
            return [1, "... ", ...middleRange, " ...", totalPages]
        }
    }
    let array = returnPaginationRange()
    return (
        <div>
            <ul className='flex justify-center md:pl-5'>
                {/* <li onClick={() => onPageChange("&laquo;")} className='px-4 py-2 border-2'>&laquo;</li> */}
                <li onClick={() => onPageChange("previous")}>
                    <button 
                        className="px-2 md:px-4 py-2 disabled:cursor-not-allowed disabled:text-gray-400"
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                </li>
                {array.map(value => (
                    <li key={value}>
                        <button onClick={() => onPageChange(value)} className={`px-4 py-2 font-semibold ${(currentPage === value) && 'text-white bg-orange-400 rounded-md'} `}>{value}</button>
                    </li>
                ))}
                <li onClick={() => onPageChange("next")}>
                    <button 
                        className="px-2 md:px-4 py-2 disabled:cursor-not-allowed disabled:text-gray-400"
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </li>
                {/* <li onClick={() => onPageChange("&raquo;")} className='px-4 py-2 border-2'>&raquo;</li> */}
            </ul>
        </div>
    )
}

export const PageRange = ({totalPages=1, currentPage, totalData }) => {
    return (
      <div className='px-2 md:px-5'>{currentPage}-{totalPages} Of {totalData}</div>
    )
}


function PaginationComponent({totalPages, currentPage, totalData, setCurrentPage, limitValue, setLimitValue }) {
    let pageNo;
    if (currentPage <= totalPages){
      pageNo = currentPage
    } else {
      setCurrentPage(totalPages);
      pageNo = currentPage
    }
  
    const handlePageChange = (value) => {
      if (value === "... ") {
        setCurrentPage(1);
      } else if (value === "previous") {
        if (currentPage !== 1) {
          setCurrentPage(currentPage - 1)
        }
      } else if (value === "next") {
        if (currentPage !== totalPages) {
          setCurrentPage(currentPage + 1)
        }
      } else if (value === " ...") {
        setCurrentPage(totalPages)
      } else {
        setCurrentPage(value);
      }
    }

  return (
    <div className='flex text-gray-600 justify-end items-center min-w-[830px] cursor-default pt-5 pb-2'>
      <SetLimit totalData={totalData} setCurrentPage={setCurrentPage} limitValue={limitValue} setLimitValue={setLimitValue}/>
      <PageRange totalPages={totalPages} currentPage={currentPage} totalData={totalData} />
      {!(totalPages === 1) &&
      <Pagination totalPages={totalPages} currentPage={pageNo} limitValue={limitValue} siblings={2} onPageChange={handlePageChange}/>}
    </div>
  )
}

export default PaginationComponent;