import { useContext, useEffect, useState } from 'react'
import ButtonSolid from '../Components/ButtonSolid'
import { baseURL } from '../config'
import { useNavigate, useParams } from "react-router";
import axios from 'axios'
import AuthContext from '../Context/AuthContext'
import TemplateHeaders from '../Components/TemplateHeaders'
import AssembliesTable from '../Components/AssembliesTable';

function ModsTemplateDetailView() {
  const [fetchingTemplateDetails, setFetchingTemplateDetails] = useState(true)
  const [templateDetails, setTemplateDetails] = useState([])
  const [templateModelDesc, setTemplateModelDesc] = useState();
  const { assembly_id } = useParams();
  const { token, logout } = useContext(AuthContext);
  const navigate = useNavigate()

  const editMODStemplate = () => {
      navigate(`/mods_template/edit_mods_template_detail/${templateModelDesc["id"]}`)
  }


  useEffect(() => {
    const fetchModsByID = async (param) => {
      try {
        setFetchingTemplateDetails(true);
        const response = await axios.get(`${baseURL}/template/getTemplateById/${param}`, {
            headers: {
                "ngrok-skip-browser-warning": "any",
                "Content-Type": "multipart/form-data",
                "x-access-token": token
            },
            params: {
                assembly_id: assembly_id
            }
        });
        setTemplateModelDesc(response.data.data.templateDesc);
        setTemplateDetails(response.data.data.templateData);
      } catch (error) {
        console.error('Error:', error);
        if (error.response?.status === 401) {
          logout();
        }
      } finally {
        setFetchingTemplateDetails(false);
      }
    };

    fetchModsByID(assembly_id);

  }, [assembly_id, logout, token]);

  return (
    <div>
        <div className='w-full'>
          <div className='flex flex-wrap items-start md:items-center justify-between pt-3 md:pt-5'>
            <TemplateHeaders templateModelDesc={templateModelDesc} loading={fetchingTemplateDetails} modsTemplate={true} />
            <div className='mr-0 ml-auto'> 
              <ButtonSolid 
                buttonText={"Edit Template"}
                disabled={fetchingTemplateDetails}
                onClick={() => editMODStemplate(templateModelDesc["id"])}
              />
            </div>
          </div>

          <AssembliesTable fetchingTemplateDetails={fetchingTemplateDetails} templateDetails={templateDetails}/>  
        </div>
    </div>
  )
}

export default ModsTemplateDetailView