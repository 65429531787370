import { useContext } from 'react';
import MainLayout from './Layouts/MainLayout';
import MainRoutes from './Routes/MainRoutes';
import AuthContext from './Context/AuthContext';
import AuthRoutes from './Routes/AuthRoutes';

function App() {

  const{isLoggedIn} = useContext(AuthContext)
   return (
    <div className='font-abc'>
        {isLoggedIn ?
          (<MainLayout>
            <MainRoutes />
          </MainLayout>)
          :
          (<AuthRoutes />)
        }
    </div>
  );
}

export default App;
