import { useContext, useState } from 'react'
import Popup from './Popup'
import InputField from './InputField'
import ButtonOutline from './ButtonOutline'
import ButtonSolid from './ButtonSolid'
import axios from 'axios'
import { baseURL } from '../config'
import toast from 'react-hot-toast'
import AuthContext from '../Context/AuthContext'
import OverlayLoading from './OverlayLoading'
import { containsOnlyNumbers } from '../utils/validators'

function GenerateQRpopup({setPopupQRform,  resetParams}) {
    const [loading, setLoading] = useState(false);
    const [numberOfQR, setNumberOfQR] = useState()
    const [errorMessage, setErrorMessage] = useState()
    
    const {token} = useContext(AuthContext)

    const generateQR = async (e) => {
        e.preventDefault();

        const status = validateInput(numberOfQR)

        if (status) {
            try {
                setLoading(true)
                const response = await axios.post(
                    `${baseURL}/qr/generateQR`,
                    { 'count': numberOfQR },
                    {
                        headers: {
                            "x-access-token": token
                        }
                    }
                );
                if (response.status === 200) {
                    toast.success(`QR (${numberOfQR}) Generated Successfully`)
                }
            } catch (error) { 
                toast.error("Error", error)
            } finally {
                setLoading(false)
                setPopupQRform(false)
                resetParams();
            }
        }
    };

    const handleSetNumberOfQR = (input) => {
        if (input.length > 3) {
            return
        }
        setNumberOfQR(input)
    }

    const validateInput = (value) => {
        let isValid = true;
        
        if (!value) {
            setErrorMessage('Input Field can\'t be empty');
            isValid = false;
        } else if (!containsOnlyNumbers(value)) { 
            setErrorMessage('Input should not contain special characters');
            isValid = false;
        } else if (value.startsWith('0')) { 
            setErrorMessage("Input value can't start with zero or be zero");
            isValid = false;
        } else if (parseInt(value) > 100) {
            setErrorMessage("Input value can't be greater than 100");
            isValid = false;
        } else {
            setNumberOfQR(parseInt(value));
            setErrorMessage('');
        }
    
        return isValid;
    };

  return (
    <>
        {loading && (
            <OverlayLoading />
        )}
        <Popup>
            <div className='px-2'>
                <h3 className='text-lg font-medium mb-4'>QR Code Details</h3>
                <form onSubmit={generateQR}>
                    <InputField
                    labelText="Number of QR code"
                    labelId="no_QR"
                    value={numberOfQR}
                    type="number"
                    maxLength={3}
                    pattern="[0-9]+"
                    onChange={(e) => handleSetNumberOfQR(e.target.value)}
                    errorMessage={errorMessage}
                    />
                    <div className='my-1 md:my-3 flex gap-4 justify-center items-center'>
                        <ButtonOutline
                            buttonText="Cancel"
                            onClick={() => setPopupQRform(false)}
                        />
                        <ButtonSolid
                            isLoading={loading}
                            buttonText="Generate"
                            type="submit"
                        />
                    </div>
                </form>
            </div>
        </Popup>
    </>
  )
}

export default GenerateQRpopup