import { isValidEmail } from '../utils/validators';

export const CreateUserFormValidation = (formData, validateFields) => {
  const validateFieldsCopy = { ...validateFields };
  let isAnyEmptyFields = false;

  if (!formData?.username?.trim()) {
    validateFieldsCopy.username = 'Please add User Name';
    isAnyEmptyFields = true;
  } else {
    validateFieldsCopy.username = '';
  }

  if (!formData?.email?.trim()) {
    validateFieldsCopy.email = 'Please add Email Address';
    isAnyEmptyFields = true;
  } else if (!isValidEmail(formData?.email)) {
    validateFieldsCopy.email = 'Please add valid Email Address';
    isAnyEmptyFields = true;
  } else {
    validateFieldsCopy.email = '';
  }

  if (!formData?.password?.trim()) {
    validateFieldsCopy.password = 'Please add Password';
    isAnyEmptyFields = true;
  } else {
    validateFieldsCopy.password = '';
  }

  if (!formData?.confirm_password?.trim()) {
    validateFieldsCopy.confirm_password = 'Please add Confirm Password';
    isAnyEmptyFields = true;
  } else if (formData?.confirm_password?.trim() !== formData?.password?.trim()) {
    validateFieldsCopy.confirm_password = 'Passwords do not match';
    isAnyEmptyFields = true;
  } else {
    validateFieldsCopy.confirm_password = '';
  }

  return [isAnyEmptyFields, validateFieldsCopy];
};
