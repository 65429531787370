import { useEffect, useState } from "react";

function useDebounce(value) {
    const [debounceValue, setDebounceValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(value);
        }, 800)

        return () => {
            clearTimeout(handler);
        };
    }, [value])

    return debounceValue;
}

export default useDebounce;