import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ButtonSolid from '../Components/ButtonSolid';
import InputField from '../Components/InputField';
import AuthContext from "../Context/AuthContext";
import AuthLayout from "../Layouts/AuthLayout";

function AuthenticationLogin() {
  const { login, loading, error } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');


  const handleSubmit = async e => {
    e.preventDefault();
    await login(email, password);
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <p className='lg:text-center text-3xl font-medium mb-3 text-gray-700'>Sign In</p>
        <div className='mb-1 md:mb-3'>
          <InputField 
              labelText = 'Email address'
              labelId = 'login_email_address'
              type = 'email'
              name="email"
              required = {true}
              value={email}
              autoFocus={true}
              onChange={e => setEmail(e.target.value)}
          /> 
        </div>

        <div className='mb-4 md:mb-8'>
          <InputField 
              labelText = 'Password'
              labelId = 'login_password'
              type = 'password'
              name="password"
              required = {true}
              value={password}
              onChange={e => setPassword(e.target.value)}
              errorMessage={error}
          /> 
          <div className="text-sm text-right mt-1">
              <Link to="/email_verification" tabIndex="-1" className="font-semibold text-orange-400 hover:text-orange-500">
                Forgot password?
              </Link>
            </div>
        </div>
        <div className="mb-4">
          <ButtonSolid 
            isLoading={loading}
            buttonText={'Login'}
            className="w-full"
            type="submit"
          />
        </div>
      </form>
    </AuthLayout>                                           
  )
}

export default AuthenticationLogin