const Popup = ({children, maxWidth}) => {
  return (
      <>
        <div className="fixed flex flex-col justify-end md:justify-center items-center z-30 inset-0 w-full h-full bg-gray-500 bg-opacity-75 transition-opacity cursor-not-allowed">
          <div className={`w-full max-h-[90%] overflow-y-auto cursor-default z-40 ${maxWidth ? maxWidth : 'md:max-w-[700px]'} mx-auto overflow-x-hidden md:rounded-lg bg-white text-left shadow-xl p-4`}>
            {children}
          </div>
        </div>
      </>
  )
}

export default Popup;