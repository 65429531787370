const TextField = ({labelText, labelId, rows = '4' , className, errorMessage, ...props }) => {
    return(
        <>
            <label htmlFor={labelId} className="block text-gray-700 font-medium">
            {labelText}
            <textarea
              id={labelId}
              rows={rows}
              {...props}       
              className={`block w-full rounded-md border border-gray-300 focus:outline-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed py-2 px-3 md:px-4 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${className}`}
            />
            </label>
            <p className="text-red-600 text-sm min-h-5">{errorMessage || ''}</p>
        </>
    )
}

export default TextField;