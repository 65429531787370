function OverlayLoading({loadingText='Loading...'}) {
  return (
    <div className="fixed inset-0 z-[1000] bg-gray-500 bg-opacity-75 transition-opacity cursor-not-allowed" >
        <div className="overlay_loader-container">
            <div className="overlay_loader"></div>
            <div className="overlay_loader-text">{loadingText}</div>
        </div>
    </div>
  )
}

export default OverlayLoading