import { useContext, useEffect, useState } from 'react';
import LoadingAnimation from '../Components/LoadingAnimation';
import PaginationComponent from '../Components/PaginationComponent';
import AuthContext from '../Context/AuthContext';
import axios from 'axios';
import { baseURL } from '../config';

function ContactEmail() {
    const [fetchingData, setFetchingData] = useState(false);
    const [contactData, setContactData] = useState([]);
    const { token, logout } = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limitValue, setLimitValue] = useState(10);
    const [totalContacts, setTotalContacts] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setFetchingData(true)
                const response = await axios.get(`${baseURL}/mods/getLeads`, 
                {headers: {
                    "ngrok-skip-browser-warning":"any",
                    "x-access-token": token
                },
                params: {
                    page: currentPage,
                    limit: limitValue,
               }});
               if (response?.status === 204) {
                    setContactData('no data');
                    setTotalPages(1)
               } else {
                    setContactData(response.data.data.leadsData)
                    setTotalPages(response.data.data.pagination.totalPages)
                    setTotalContacts(response.data.data.pagination.totalLeads)
               }
            } catch (error) {
                if (error.response?.status === 401) {
                    logout();
                } else{
                     console.error('Error:', error);
                }
            } finally {
                setFetchingData(false)
            }
        };

        fetchData()
    }, [limitValue, currentPage, logout, token])

    return (
        <div className='pb-6'>
            <div className='flex flex-wrap md:flex-nowrap justify-between items-center gap-4 py-5'>
                <p className='text-lg font-medium md:text-xl md:font-semibold pr-3'>Contact Email</p>
            </div>
            <div className="p-3 bg-white shadow-md rounded-lg min-h-80">
                {fetchingData ? 
                (<div className='p-4'>
                    <LoadingAnimation />
                </div>):
                ((contactData !== 'no data') && (contactData.length === 0)) ? 
                <p className='p-6 text-lg'>No email queries from customers.</p>
                :<div className='overflow-x-auto'>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 border border-gray-300">
                    <thead className="text-gray-500 uppercase text-md bg-slate-50 border-b border-gray-300">
                    <tr>
                        <th className="px-4 py-3 w-full max-w-[10%] border-r border-gray-300">Name</th>
                        <th className="px-4 py-3 w-full max-w-[10%] border-r border-gray-300">Phone</th>
                        <th className="px-4 py-3 w-full max-w-[10%] border-r border-gray-300">Email</th>
                        <th className="px-4 py-3 w-full max-w-[10%] border-r border-gray-300">Info on</th>
                        <th className="px-4 py-3 w-full max-w-[60%] text-center" >Query</th>
                    </tr>
                    </thead>
                    {(contactData === 'no data') ? 
                    <tbody>
                        <tr><td colSpan={3} className='p-4'>No Data found</td></tr>
                    </tbody> :
                    <tbody>
                        {contactData.map((data, index)=>(
                        <tr
                            key={index}
                            className="bg-white border-b hover:bg-slate-100 text-sm md:text-base cursor-default text-gray-900"
                        >
                            <td className="px-4 py-6 w-full max-w-[10%] align-top border-r border-gray-300">{data.contact_name}</td>
                            <td className="px-4 py-6 w-full max-w-[10%] align-top border-r border-gray-300">{data.contact_phone}</td>
                            <td className="px-4 py-6 w-full max-w-[10%] align-top border-r border-gray-300">{data.contact_email}</td>
                            <td className="px-4 py-6 w-full max-w-[10%] align-top border-r border-gray-300">{data.more_info}</td>
                            <td className="px-4 py-6 w-full max-w-[60%] align-top min-w-80">{data.query}</td>
                        </tr>
                        ))}
                    </tbody>}
                    </table>
                    {(totalContacts > 10) && 
                    <PaginationComponent
                        totalData={totalContacts}
                        totalPages={totalPages} 
                        currentPage={currentPage}
                        limitValue={limitValue}
                        setLimitValue={setLimitValue}
                        setCurrentPage={setCurrentPage} 
                        siblings={2} 
                    />}
                </div>}
            </div>
        </div>
  )
}

export default ContactEmail