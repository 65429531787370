// ------------------Development------------------
// export const baseURL = 'https://b3ee-2405-201-e019-27d-a5e4-c6d8-90ae-82bb.ngrok-free.app';
// export const frontendURL = 'http://localhost:3000';

// ------------------Staging------------------
// export const baseURL = 'http://3.89.220.191:3000'
// export const frontendURL = 'http://3.89.220.191:3001';

// ------------------Production------------------
export const baseURL = 'https://backend.choicebagging.com/api';
export const frontendURL = 'https://app.choicebagging.com';