import { isValidEmail, containsOnlyNumbersSignsAndBrackets, containsOnlyNumbersLettersSlashAndMinus, containsOnlyNumbersLettersAndSlash, containsOnlyNumbersSlashAndMinus } from '../utils/validators';


export const MachineOrderFormValidation = (qr, mods_data, customer_data, validateFields, accountNumberError) => {
    const validateFieldsCopy = {...validateFields};
    let isAnyEmptyFields = false;
    if (!qr) {
      validateFieldsCopy.qr = 'Please Select QR';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.qr = '';
    }

    if (!containsOnlyNumbersLettersAndSlash(mods_data?.sales_order_number)) {
      validateFieldsCopy.sales_order_number = "No special characters or space allowed except '/'";
    } else {
      validateFieldsCopy.sales_order_number = '';
    }

    if (!containsOnlyNumbersLettersSlashAndMinus(mods_data?.customer_po)) {
      validateFieldsCopy.customer_po = "No special characters or space allowed except '-'";
    } else {
      validateFieldsCopy.customer_po = '';
    }

    // Check customer detail fields
    if (customer_data?.account_number?.trim() && !containsOnlyNumbersSlashAndMinus(customer_data?.account_number)) {
      validateFieldsCopy.account_number = "No special characters or space allowed except '/' , '-' ";
    } else if (accountNumberError) {
      validateFieldsCopy.account_number = 'Cannot Edit or Remove Account Number';
      isAnyEmptyFields = true;
    } else {
      validateFieldsCopy.account_number = '';
    }

    // if (!customer_data?.name?.trim()) {
    //   validateFieldsCopy.name = 'Please add Customer Name';
    //   isAnyEmptyFields = true;
    // } else if (!containsOnlyTextSlashMinusAndBrackets(customer_data?.name)) {
    //   validateFieldsCopy.name = "No special characters or number allowed except '/', '-', '(', ')'";
    //   isAnyEmptyFields = true;
    // } else {
    //   validateFieldsCopy.name = '';
    // }

    // if (!customer_data?.company_name?.trim()) {
    //   validateFieldsCopy.company_name = 'Please add Company Name';
    //   isAnyEmptyFields = true;
    // } else {
    //   validateFieldsCopy.company_name = '';
    // }

    if (!containsOnlyNumbersSignsAndBrackets(customer_data?.primary_contact)) {
      validateFieldsCopy.primary_contact = 'Please add Valid Contact Number';
    } else {
      validateFieldsCopy.primary_contact = '';
    }

    if (!containsOnlyNumbersSignsAndBrackets(customer_data?.accounting_contact)) {
      validateFieldsCopy.accounting_contact = 'Please add Valid Contact Number';
    } else {
      validateFieldsCopy.accounting_contact = '';
    }

    if (!isValidEmail(customer_data?.primary_email)) {
      validateFieldsCopy.primary_email = 'Please add valid Email Address';
    } else {
      validateFieldsCopy.primary_email = '';
    }

    if (!isValidEmail(customer_data?.accounting_email)) {
      validateFieldsCopy.accounting_email = 'Please add valid Email Address';
    } else {
      validateFieldsCopy.accounting_email = '';
    }
    
    return [isAnyEmptyFields, validateFieldsCopy];
  };