import { Routes, Route, Navigate } from "react-router-dom";

import ProductLibraryListView from "../Pages/ProductLibraryListView";
import ProductLibraryDetailView from "../Pages/ProductLibraryDetailView";

import QRcodeListView from "../Pages/QRcodeListView";

import ModsTemplateDetailView from "../Pages/ModsTemplateDetailView";
import ModsTemplateListView from "../Pages/ModsTemplateListView";
import ModsTemplateEdit from "../Pages/ModsTemplateEdit";

import MachineOrderListView from "../Pages/MachineOrderListView";
import MachineOrderDetailView from "../Pages/MachineOrderDetailView";
import MachineOrderEdit from "../Pages/MachineOrderEdit";

// import Settings from "../Pages/Settings";
// import PageNotFound from "../Pages/PageNotFound";
import LandingPage from "../Pages/LandingPage";
import MachineOrderTemplateList from "../Pages/MachineOrderTemplateList";
import MachineOrderViewTemplate from "../Pages/MachineOrderViewTemplate";
import MachineOrderUseTemplate from "../Pages/MachineOrderUseTemplate";
import LandingPage404 from "../Pages/LandingPage404";
import ContactEmail from "../Pages/ContactEmail";
import FindEQID from "../Pages/FindEQID";

function MainRoutes() {
  
  return (
    <Routes>
      <Route path="/" element= {<ProductLibraryListView />} />
      <Route path="/assembly_detail/:assembly_id" element= {<ProductLibraryDetailView />} />
      
      <Route path="/machine_orders" element= {<MachineOrderListView />} />
      <Route path="/machine_orders/machine_orders_detail_view/:assembly_id" element= {<MachineOrderDetailView />} />
      <Route path="/machine_orders/edit_mods_data_sheet/:assembly_id" element= {<MachineOrderEdit />} />
      <Route path="/machine_orders/mods_template" element= {<MachineOrderTemplateList />} />
      <Route path="/machine_orders/create_mods_data_sheet/:assembly_id" element= {<MachineOrderUseTemplate />} />
      <Route path="/machine_orders/mods_template_detail/:assembly_id" element= {<MachineOrderViewTemplate />} />

      <Route path="/mods_template" element= {<ModsTemplateListView />} />
      <Route path="/mods_template/mods_template_detail/:assembly_id" element= {<ModsTemplateDetailView />} />
      <Route path="/mods_template/edit_mods_template_detail/:assembly_id" element= {<ModsTemplateEdit />} />

      <Route path="/contact_email" element= {<ContactEmail />} />

      {/* <Route path="/settings" element= {<Settings />} /> */}
      <Route path="/qr_list_view" element= {<QRcodeListView />} />

      <Route path="/find_eqid" element= {<FindEQID />} />
      <Route path="/eqid/:qr_id" element= {<LandingPage />} />
      <Route path="/eqid_notFound/:qr_id" element= {<LandingPage404 />} />
      
      {/* <Route path="*" element={<PageNotFound />} /> */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default MainRoutes;
