import { Link } from 'react-router-dom';
import { ControlFiller, ControlPackages, ElectricalSchematic, PartsPage, PneumaticSchematic, RightArrowIcon, VideoTutorial, WrittenManual } from './Icons';
import SkeletonLoading from './SkeletonLoading';
// import moment from 'moment';

function AssetList({ data, fetchingAssets }) {
    return (
        <>
            {fetchingAssets 
            ? <SkeletonLoading />
            : (<div>
                    {/* <p className="pl-2">Last Update: {data && ( moment(data.updated_at).endOf('hour').fromNow())}</p> */}
                    <p className='italic pl-2 text-sm'>{"( "} If you can't find your updated assets, try syncing assets on the
                        <Link to={"/"} className='text-blue-500 italic pl-2'>{"Product Library"}</Link>{" page. )"}
                    </p>
                    <div className='flex flex-wrap pt-5'>
                        {data && data.map((item, index) => (
                            <Link key={index} target="blank" to={item.source} className='relative group w-full min-w-10 max-w-[50%] md:max-w-[33.33%] p-1'>
                                <div className='border border-slate-100 p-2 bg-slate-50 hover:bg-slate-200 rounded-md h-full'>
                                    <div className='bg-gradient-to-b from-cyan-200 w-full min-h-36 flex justify-start items-end p-1 md:p-3 mb-2 rounded-md relative'>
                                        {item.type === "Parts Pages" && <PartsPage />}
                                        {item.type === "Manuals" && <WrittenManual />}
                                        {item.type === "Electrical Schematics" && <ElectricalSchematic />}
                                        {item.type === "Pneumatic Schematics" && <PneumaticSchematic />}
                                        {item.type === "Tutorial Videos" && <VideoTutorial />}
                                        {item.type === "Filler Controller Manuals" && <ControlFiller />}
                                        {item.type === "Control Package" && <ControlPackages />}
                                    </div>
                                    <p className='font-medium text-sm md:text-base'>{item.type}</p>
                                    <p className='text-sm md:text-base mb-8'>{item.name}</p>
                                    <p className='flex absolute bottom-3 right-3 text-orange-500 border-b border-b-transparent group-hover:border-b-orange-500 w-fit'>
                                        <span>View Asset</span>
                                        <RightArrowIcon />
                                    </p>
                                </div>
                            </Link>
                        ))}
                        {data?.length === 0 && (
                            <>
                                <p className='w-full'> - No Files Available -</p>
                            </>
                        )}
                        {!data && <SkeletonLoading />}
                    </div>
                </div>)
            }
        </>
    );
}

export default AssetList;
