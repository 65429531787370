import { Routes, Route, Navigate } from "react-router-dom";
import AuthenticationEmailVerification from "../Pages/AuthenticationEmailVerification";
import AuthenticationLogin from "../Pages/AuthenticationLogin";
import AuthenticationResetPassword from "../Pages/AuthenticationResetPassword";
import LandingPage from "../Pages/LandingPage";
import LandingPage404 from "../Pages/LandingPage404";
import FindEQID from "../Pages/FindEQID";

function AuthRoutes() {

  return (
    <Routes>
      <Route path="/login" element={<AuthenticationLogin />} />
      <Route path="/reset_password" element={<AuthenticationResetPassword />} />
      <Route path="/email_verification" element={<AuthenticationEmailVerification />} />
      <Route path="/find_eqid" element= {<FindEQID />} />
      <Route path="/eqid/:qr_id" element= {<LandingPage />} />
      <Route path="/eqid_notFound/:qr_id" element= {<LandingPage404 />} />

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default AuthRoutes;