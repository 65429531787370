import React from 'react'
import '../App.css'

function ToolTip({children, text}) {
  return (
    <div className='relative'>
      <div className='peer'>
      {children}
      </div>
      <div className='absolute bottom-full left-0 p-4 bg-white z-20 shadow-xl rounded-md border hidden peer-hover:block'>
        <div className='flex'>
          <div className='inline-block'>
            <p className='whitespace-nowrap font-medium mr-4'>Customer Name</p>
            <p className='whitespace-nowrap font-medium mr-4'>Company Name</p>
          </div>
          <div className='inline-block'>
            <p className='whitespace-nowrap'>{text[0]}</p>
            <p className='whitespace-nowrap'>{text[1]}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolTip