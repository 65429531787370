import { useContext, useRef, useState } from 'react';
import { DownArrowIcon, SearchIcon } from './Icons';
import ProductLibraryContext from '../Context/ProductLibraryContext';

function MultiSelectDropdown({ options=[], setValue, label, placeholder, disabled=false }) {
    const [isOpen, setIsOpen] = useState(false);
    const { filterShowValue, setFilterShowValue } = useContext(ProductLibraryContext);
    const [valueChanged, setValueChanged] = useState(false)
    const [cancelLoading, setCancelLoading] = useState(false)
    const [searchInput, setSearchInput] = useState("");
    const containerRef = useRef(null);

    const scrollRight = () => {
        if (containerRef.current) {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth - containerRef.current.clientWidth + 100;
        }
    };

    const filteredOption = (opt, searchInput) => {
        if (!searchInput) {
            return opt;
        }
        return opt.filter(item => item?.toLowerCase().startsWith(searchInput?.toLowerCase()));
    };

    const optionList = filteredOption(options, searchInput);
    
    const handleOpen = (e) => {
        e.stopPropagation()
        if (cancelLoading) {
            return
        }
        if (valueChanged === true) {
            setValue(filterShowValue)
        }
        setIsOpen(prev => !prev);
        setValueChanged(false)
    }
    
    const setValueFunc = (i) => {
        setFilterShowValue(prevShowValue => {
          const newFilterShowValue = prevShowValue.includes(i) ? prevShowValue.filter(o => o !== i) : [...prevShowValue, i];
          setValue(newFilterShowValue);
          if (!prevShowValue.includes(i)) {
            scrollRight();
          }
          return newFilterShowValue;
        });
      
        setIsOpen(false);
        setValueChanged(false);
      };

    const setCheckValueFunc = (i) => {
        if (filterShowValue?.includes(i)) {
            setFilterShowValue(prevShowValue => prevShowValue?.filter(o => o !== i));
        } else {
            setFilterShowValue(prevShowValue => [...prevShowValue, i]);
            scrollRight()
        }
        setValueChanged(true)
    }

    const handleOnBlur = (e) => {
        e.stopPropagation()
        setIsOpen(false);
        if (valueChanged === true) {
            setValue(filterShowValue)
        }
        setValueChanged(false)
    }

    const useDebounce = (func, delay) => {
        const debounceRef = useRef(null);
        
        const debouncedFunc = (...args) => {
          if (debounceRef.current) {
            clearTimeout(debounceRef.current);
          }
          debounceRef.current = window.setTimeout(() => {
            func(...args);
          }, delay);
        };
      
        debouncedFunc.cancel = () => {
          if (debounceRef.current) {
            clearTimeout(debounceRef.current);
          }
        };
      
        return debouncedFunc;
    };
    
    const cancelSelected = useDebounce((value) => {
        setValue(value)
        setCancelLoading(false)
    }, 2000);
    
    const handleOnCancel = (e, sel) => {
        e.stopPropagation();
        if (disabled) {
            setCancelLoading(false)
            return;
        }
        setCancelLoading(true)
        let value;
        if (filterShowValue?.includes(sel)) {
            value = filterShowValue.filter(o => o !== sel)
            setFilterShowValue(value);
        } else {
            value = [...filterShowValue, sel]
            setFilterShowValue(value);
            scrollRight();
        }
        if (value.length >= 1) {
            cancelSelected(value);
        } else {
            cancelSelected.cancel();
            setValue([]);
            setCancelLoading(false);
        }
    };
    
    return (
        <button
            onClick={(e) => handleOpen(e)}
            disabled = {disabled}
            className='w-full md:w-[500px]'
        >
            <div className='block font-medium text-gray-700'>{label}</div>
            {((isOpen && !disabled) || cancelLoading) && <div className={`fixed ${cancelLoading ? 'cursor-wait' : 'cursor-auto'} inset-0 z-10`} onClick={(e) => handleOnBlur(e)} />}
            <div 
                className={`relative z-10 ${isOpen ? 'border-2 border-gray-400': 'border border-gray-300'} ${disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white cursor-pointer'}  w-full flex items-center pl-3 md:pl-4 pr-7 rounded-md outline-none min-h-10 md:min-h-12`}
            >
                <div ref={containerRef} className={`flex items-center gap-2 overflow-x-auto md:hover:overflow-x-auto ${isOpen ? 'md:overflow-x-auto' : 'md:overflow-x-hidden'} thin-scrollbar`}>
                    {(filterShowValue.length > 0) ? filterShowValue.map(sel => (
                        <div
                            className='flex rounded-md whitespace-nowrap text-nowrap border border-gray-500'
                            key={sel}
                        >
                            <span className='block pl-2 md:py-1'>{sel}</span>
                            <span onClick={(e) => { handleOnCancel(e, sel); }} className={`block px-2 md:py-1 w-8 hover:font-bold hover:text-gray-600 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>&times;</span>
                        </div>
                    )) : <span className='text-gray-400 text-sm md:text-base text-nowrap'>{placeholder}</span>}
                </div>
                <div className='absolute right-2 py-2'>
                    <div className={`transform transition-all duration-300 ease-in-out ${isOpen && 'rotate-180'}`}>
                        <DownArrowIcon />
                    </div>
                </div>
                {!disabled && 
                <div className={`${isOpen ? 'block' : 'hidden'} absolute top-full left-0 right-0 mt-2 z-10 border bg-white max-h-60 shadow-lg overflow-y-auto`}>
                    <div className="flex items-center px-2 py-2 bg-white md:py-0 sticky top-0 border-b border-gray-300">
                        <SearchIcon />
                        <input
                            type="text"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            placeholder="Search.."
                            className="p-2 outline-none w-full placeholder:text-base"
                        />
                    </div>  
                    <ul className="z-10 cursor-pointer text-start">
                    {(optionList.length >= 1) ? 
                        optionList.map((option) => 
                            (<li key={option}  
                                className={`${filterShowValue.includes(option) ? 'bg-gray-200': ''} cursor-pointer hover:bg-gray-300 flex items-center max-w-2xl border-orange-500`} >
                                <div onClick={e => {e.stopPropagation(); setCheckValueFunc(option)}}>
                                    <input type="checkbox" readOnly={true} checked={filterShowValue.includes(option)} className='pointer-events-none h-6 w-6 text-white indeterminate:bg-gray-300 rounded-md accent-orange-600 m-3'/>
                                </div>
                                <span onClick={e => {e.stopPropagation(); setValueFunc(option)}} className='p-2 pl-0 inline-block w-full'>{option}</span>
                            </li>)) :
                            <li className='p-2 inline-block w-full'>No options found</li>}
                    </ul> 
                </div>}
            </div>
        </button>
    );
}

export default MultiSelectDropdown;
