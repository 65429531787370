import { useState, useEffect } from 'react';
import ButtonSolid from '../Components/ButtonSolid';
import InputField from '../Components/InputField';
import axios from 'axios';
import { baseURL } from '../config';
import toast from 'react-hot-toast';
import AuthLayout from '../Layouts/AuthLayout';
import { useNavigate } from 'react-router';

function AuthenticationResetPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, []);

  const handleSubmit = async(e) => {
    setError('')
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("'Password' and 'Confirm Password' do not match");
      return;
    }

    try {
      setProcessing(true)
      const response = await axios.put(`${baseURL}/auth/resetpassword`, {
        email: email,
        password: password,
        confirm_password: confirmPassword
      });
      const message = response.data.message;
      toast.success(message);
      navigate('/login')
    } catch (error) {
      toast.error('Error:', error);
    } finally {
      setProcessing(false)
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <p className='lg:text-center text-3xl font-medium mb-3 text-gray-700'>Reset password</p>
        <div className='mb-3'>
          <InputField 
              labelText = 'New Password'
              labelId = 'new_reset_password'
              type = 'password'
              required = {true}
              value={password}
              onChange={(e) => {setPassword(e.target.value)}}
          /> 
        </div>

        <div className='mb-8'>
          <InputField 
              labelText = 'Confirm Password'
              labelId = 'new_conform_password'
              type = 'password'
              required = {true}
              value={confirmPassword}
              onChange={(e) => {setConfirmPassword(e.target.value)}}
              errorMessage={error}
          /> 
        </div>
        <div>
          <ButtonSolid 
            type="submit"
            buttonText={processing ? 'Processing' : 'Confirm'}
            className="w-full"
          />
        </div>
      </form>
    </AuthLayout>
  );
}

export default AuthenticationResetPassword;
