import { useContext, useEffect, useState, useRef } from "react";
import Searchbar from "../Components/Searchbar";
import { CloseIcon } from "../Components/Icons";
import { baseURL } from "../config";
import axios from "axios";
import AuthContext from "../Context/AuthContext";
import LoadingAnimation from "../Components/LoadingAnimation";

function MODsAssemblyList({
  fetchingTemplateDetails,
  addAssemblyData,
  toggleSearchPopup,
  setToggleSearchPopup,
}) {
  const [fetchingAssemblyData, setFetchingAssemblyData] = useState(true);
  const { token, logout } = useContext(AuthContext);
  const [assemblyData, setAssemblyData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [searchInput, setSearchInput] = useState("");
  const observer = useRef();
  const [params, setParams] = useState({
    page: 1,
    limit: 15,
    search: "",
  });

  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    const debouncedFunc = (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = window.setTimeout(() => {
        func(...args);
      }, delay);
    };

    debouncedFunc.cancel = () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };

    return debouncedFunc;
  };

  const handleSetSearchFilter = useDebounce((value) => {
    setAssemblyData([]);
    setParams((prevParams) => ({
      ...prevParams,
      search: value,
      page: 1,
    }));
  }, 800);

  const handleAssemblySearch = (input) => {
    setSearchInput(input);
    handleSetSearchFilter(input);
  };

  const assemblyDataInfiniteScroll = (node) => {
    if (fetchingAssemblyData) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (params.page < totalPages) {
          setParams((prevParams) => ({
            ...prevParams,
            page: prevParams.page + 1,
          }));
        }
      }
    });
    if (node) observer.current.observe(node);
  };

  useEffect(() => {
    const fetchAssemblyData = async () => {
      try {
        setFetchingAssemblyData(true);
        const response = await axios.get(
          `${baseURL}/assembly/getAllAssemblies`,
          {
            headers: {
              "ngrok-skip-browser-warning": "any",
              "x-access-token": token,
            },
            params: params,
          }
        );
        if (response?.status === 204) {
          setAssemblyData(["no data"]);
          setTotalPages(1);
        } else {
          // setAssemblyData(prev => [...prev, ...response.data.data.assemblyData]);
          setAssemblyData((prev) => [
            ...new Set([...prev, ...response.data.data.assemblyData]),
          ]);
          setTotalPages(response.data.data?.pagination.totalPages);
        }
      } catch (error) {
        console.error("Error:", error);
        if (error.response?.status === 401) {
          logout();
        }
      } finally {
        setFetchingAssemblyData(false);
      }
    };

    fetchAssemblyData();
  }, [params, logout, token]);

  return (
    <>
      {toggleSearchPopup && (
        <div className="fixed lg:hidden inset-0 bg-gray-500 bg-opacity-75 transition-opacity cursor-not-allowed z-30" />
      )}

      <div
        className={`${
          toggleSearchPopup
            ? "block lg:static fixed top-16 bottom-0 left-1/2 transform -translate-x-1/2 md:h-[85%] md:max-w-[600px] md:rounded-lg lg:transform-none z-40"
            : "hidden lg:block ml-2"
        } lg:max-w-[30%] w-full bg-white px-5 overflow-y-auto pb-4`}
      >
        <div className="sticky top-0 py-5 bg-white z-10">
          <div
            className="lg:hidden cursor-pointer"
            onClick={() => setToggleSearchPopup(false)}
          >
            <CloseIcon size="h-10 w-10 p-2 border-2 border-gray-300 rounded-md mr-0 ml-auto mb-3" />
          </div>
          <Searchbar
            value={searchInput}
            onChange={(e) => handleAssemblySearch(e.target.value)}
            fullWidth={true}
            disabled={fetchingTemplateDetails}
          />
        </div>
        {!fetchingAssemblyData &&
        (assemblyData.length === 0 || assemblyData[0] === "no data") ? (
          <p>{`No data found for '${params.search}'`}</p>
        ) : (
          assemblyData?.map((item, index) => {
            if (assemblyData.length === index + 1) {
              return (
                <div
                  key={index}
                  ref={assemblyDataInfiniteScroll}
                  className="border-b py-4 px-2 mb-2 relative cursor-default"
                >
                  <div className="flex text-lg font-medium mb-1 flex-wrap pr-10">
                    {`Assembly# - ${item.assembly_number}`}
                  </div>
                  <p className="text-gray-600 mb-2">
                    {item?.assembly_description}
                  </p>
                  <div className="px-4 border border-gray-500 w-fit rounded-full text-gray-500">
                    {item.assembly_type}
                  </div>
                  <button
                    disabled={fetchingTemplateDetails}
                    onClick={() => {
                      addAssemblyData(item);
                    }}
                    className="absolute right-2 top-2 text-orange-400 font-semibold disabled:text-gray-500 disabled:cursor-wait"
                  >
                    {" "}
                    + Add
                  </button>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  className="border-b py-4 px-2 mb-2 relative cursor-default"
                >
                  <div className="flex text-lg font-medium mb-1 flex-wrap pr-10">
                    {`Assembly# - ${item.assembly_number}`}
                  </div>
                  <p className="text-gray-600 mb-2">
                    {item?.assembly_description}
                  </p>
                  <div className="px-4 border border-gray-500 w-fit rounded-full text-gray-500">
                    {item.assembly_type}
                  </div>
                  <button
                    disabled={fetchingTemplateDetails}
                    onClick={() => {
                      addAssemblyData(item);
                    }}
                    className="absolute right-2 top-2 text-orange-400 font-semibold disabled:text-gray-500 disabled:cursor-wait"
                  >
                    {" "}
                    + Add
                  </button>
                </div>
              );
            }
          })
        )}
        {fetchingAssemblyData && <LoadingAnimation />}
      </div>
    </>
  );
}

export default MODsAssemblyList;
