import { useContext, useState, useEffect } from 'react'
import ButtonSolid from '../Components/ButtonSolid'
import { baseURL } from '../config'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import AuthContext from '../Context/AuthContext'
import TemplateHeaders from '../Components/TemplateHeaders'
import { useParams } from 'react-router';
import CustomerDetailShow from '../Components/CustomerDetailShow'
import AssembliesTable from '../Components/AssembliesTable';

function MachineOrderDetailView() {
  const [fetchingTemplateDetails, setFetchingTemplateDetails] = useState(true)
  const { token } = useContext(AuthContext);
  const [templateModelDesc, setTemplateModelDesc] = useState();
  const { assembly_id } = useParams();
  const [qr, setQR] = useState()
  const [assemblyData, setAssemblyData] = useState([])
  const navigate = useNavigate()
  const [mods_data, setMods_data] = useState({
    sales_order_number: '',
    serial_number: '',
    customer_po: '',
  });
  const [customer_data, setCustomer_data] = useState({
    account_number: '',
    name: '',
    company_name: '',
    primary_contact: '',
    primary_email: '',
    accounting_contact: '',
    accounting_email: '',
    bill_to: '',
    ship_to: '',
    others: '',
    shipment_date: '',
  });

  useEffect(() => {
    const fetchData = async (param) => {
      try {
        setFetchingTemplateDetails(true);
        const response = await axios.get(`${baseURL}/mods/getModsById/${param}`, {
          headers: {
            "ngrok-skip-browser-warning": "any",
            "Content-Type": "multipart/form-data",
            "x-access-token": token
          },
          params: {
            mods_id: assembly_id
          }
        });
        setCustomer_data(response.data.data.customerData);
        setMods_data(response.data.data.modsData);
        setQR(response.data.data.qrData);
        setAssemblyData(response.data.data.assemblyData);
        setTemplateModelDesc(response.data.data.templateData);
      } catch (error) {
          console.error('Error:', error);
      } finally {
        setFetchingTemplateDetails(false);
      }
    };

    fetchData(assembly_id);
  }, [assembly_id, token]);


  return (
    <>
      <div className='flex w-full'>
        <div className='w-full lg:pr-3 overflow-y-auto'>
          <div className='flex flex-wrap items-start md:items-center justify-between py-3 md:py-5 gap-2'>
            <TemplateHeaders templateModelDesc={templateModelDesc} machineOrder={true} loading={fetchingTemplateDetails} />
            <div className='mr-0 ml-auto'> 
              <ButtonSolid 
                buttonText={"Edit Machine Order"}
                disabled={fetchingTemplateDetails}
                onClick={() => navigate(`/machine_orders/edit_mods_data_sheet/${assembly_id}`)}
                />
            </div>
          </div>
          <CustomerDetailShow 
            loadingData = {fetchingTemplateDetails}
            mods_data={mods_data}
            customer_data={customer_data}
            qr={qr} 
          />
          <AssembliesTable fetchingTemplateDetails={fetchingTemplateDetails} templateDetails={assemblyData} />
        </div>
      </div>
    </>
  )
}

export default MachineOrderDetailView