import { isValidEmail } from '../utils/validators';

export const LPcontactUsFormValidation = (formData, validateFields) => {
  const validateFieldsCopy = { ...validateFields };
  let isAnyEmptyFields = false;

    if (!formData?.name?.trim()) {
        validateFieldsCopy.name = 'Please add Name';
        isAnyEmptyFields = true;
    } else {
        validateFieldsCopy.name = '';
    }

    if (!formData?.phone?.trim()) {
        validateFieldsCopy.phone = 'Please add Phone Number';
        isAnyEmptyFields = true;
    } else if (formData?.phone?.trim().length <= 8) {
        validateFieldsCopy.phone = 'Please add a valid Phone Number';
        isAnyEmptyFields = true;
    } else if (formData?.phone?.trim().length >= 16) {
        validateFieldsCopy.phone = 'Please add a valid Phone Number';
        isAnyEmptyFields = true;
    } else {
        validateFieldsCopy.phone = '';
    }

    if (!formData?.email?.trim()) {
        validateFieldsCopy.email = 'Please add Email Address';
        isAnyEmptyFields = true;
    } else if (!isValidEmail(formData?.email)) {
        validateFieldsCopy.email = 'Please add valid Email Address';
        isAnyEmptyFields = true;
    } else {
        validateFieldsCopy.email = '';
    }

    if (!formData?.query?.trim()) {
        validateFieldsCopy.query = 'Please enter your query';
        isAnyEmptyFields = true;
    } else {
        validateFieldsCopy.query = '';
    }

  return [isAnyEmptyFields, validateFieldsCopy];
};
