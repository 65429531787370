import { Link } from "react-router-dom";

const LinkButtonOutline = ({ buttonText = "Link Button", disabled = false, linkTo, className }) => {
    return (
        <Link
            disabled={disabled}
            to = {linkTo}
            className={`block text-center w-auto min-w-[150px] text-sm md:text-base h-10 md:h-12 px-2 md:px-4 py-2 md:py-3 rounded-md font-medium text-orange-400 ring-1 ring-inset ring-orange-400 hover:bg-gray-50 disabled:bg-gray-200 disabled:cursor-not-allowed ${className}`}
        >
            {buttonText}
        </Link>
    );
};

export default LinkButtonOutline;