import InputField from "../Components/InputField";
import TextField from "../Components/TextField";

function CustomerDetailShow({ loadingData, mods_data, customer_data, qr }) {
  return (
    <div>
      <form className="bg-white relative shadow-md rounded-lg mb-5 w-full overflow-hidden pt-6 px-2 md:pl-6 md:pr-4 pb-3">
        <div className="flex items-center w-full">
          <div className="flex flex-col mb-3">
            <div className="min-w-[171px] min-h-[171px] lg:min-w-[271px] lg:min-h-[271px] border border-gray-300 bg-gray-100 rounded-lg p-2 flex flex-col justify-center items-center">
              <img
                src={`data:image/png;base64,${qr?.base64_string}`}
                alt="QR"
                className="w-[150px] h-[150px] lg:w-[250px] lg:h-[250px]"
              />
            </div>
          </div>
          <div className="pl-2 md:pl-0 w-full">
            <p className="md:pl-6 font-medium text-lg mb-3">MODs Details</p>
            <div className="md:pl-4">
              <div className="sm:flex w-full mb-3 border-b border-dashed">
                <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
                  <InputField
                    disabled={loadingData}
                    readOnly={true}
                    labelText="Serial #"
                    name="serial"
                    value={mods_data?.serial_number}
                  />
                </div>
                <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
                  <InputField
                    disabled={loadingData}
                    readOnly={true}
                    className={"uppercase"}
                    labelText="Sales Order #"
                    name="sales_order_number"
                    value={mods_data?.sales_order_number}
                  />
                </div>
                <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
                  <InputField
                    disabled={loadingData}
                    readOnly={true}
                    labelText="Customer PO #"
                    className={"uppercase"}
                    name="customer_po"
                    value={mods_data?.customer_po}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-0 ml:ml-[287px]">
          <p className="pl-2 font-medium text-lg md:-mt-6 mb-3 text-center ml:text-left">
            Customer Details
          </p>
          <div className="sm:flex w-full">
            <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Customer Name"
                name="customer_name"
                value={customer_data?.name}
              />
            </div>
            <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Company Name"
                name="customer_name"
                value={customer_data?.company_name}
              />
            </div>
            <div className="w-full md:w-1/2 ml:w-1/3 px-1 md:px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Account Number"
                name="account_number"
                value={customer_data?.account_number}
              />
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Primary Phone #"
                name="primary_contact"
                value={customer_data?.primary_contact}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Accounting Phone #"
                name="accounting_contact"
                value={customer_data?.accounting_contact}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Primary Email"
                name="primary_email"
                value={customer_data?.primary_email}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Accounting Email"
                name="accounting_email"
                value={customer_data?.accounting_email}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <TextField
                disabled={loadingData}
                readOnly={true}
                labelText="Bill To"
                name="bill_to"
                value={customer_data?.bill_to}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <TextField
                disabled={loadingData}
                readOnly={true}
                labelText="Ship To"
                name="ship_to"
                value={customer_data?.ship_to}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Date of Shipment"
                type="date"
                name="shipment_date"
                value={customer_data?.shipment_date?.split("T")[0]}
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <InputField
                disabled={loadingData}
                readOnly={true}
                labelText="Others"
                name="others"
                value={customer_data?.others}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CustomerDetailShow;
