import { createContext, useState, useContext } from 'react';
import axios from 'axios';
import {baseURL} from '../config'
import toast from 'react-hot-toast';
import AuthContext from './AuthContext';
import PopupWithClose from '../Components/PopupWithClose';

const FileSyncContext = createContext();

const FileSyncProvider = ({ children }) => {
  const { token } = useContext(AuthContext);
  const [checkLocalStorage, setCheckLocalStorage] = useState(true);
  const [failedData, setFailedData] = useState([]);

  const setClosePopup = () => {
    setFailedData([])
  }
  const checkSyncControlPackageStatus = async () => {
    try {
      const response = await axios.get(`${baseURL}/asset/checkCPMergeStatus`, {
        headers: {
          "ngrok-skip-browser-warning": "any",
          "x-access-token": token,
        },
      });
      const status = response.data.data.status.status;
  
      if (status === "completed") {
        sessionStorage.setItem('syncCPLoading', false);
        // sessionStorage.setItem('disableSyncAssets', false);
        toast.success("Sucessfully Synced Control Packages");
        setFailedData(response.data.data.status.failed_data)
        setCheckLocalStorage(!checkLocalStorage)
      } else if (status === "running") {
        setTimeout(checkSyncControlPackageStatus, 60000); // Retry after 1min
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
      setCheckLocalStorage(!checkLocalStorage)
    }
  };
  
  const checkSyncAssetsStatus = async () => {
    try {
      const response = await axios.get(`${baseURL}/asset/checkAssetMergeStatus`, {
        headers: {
          "ngrok-skip-browser-warning": "any",
          "x-access-token": token,
        },
      });
      const status = response.data.data.status.status;
      // toast.success(response.data.message)
      if (status === "completed") {
        sessionStorage.setItem('syncAssetsLoading', false);
        // sessionStorage.setItem('disableSyncAssets', true);
        toast.success("Sucessfully Synced Assets");
        setCheckLocalStorage(!checkLocalStorage)
      } else if (status === "running") {
        setTimeout(checkSyncAssetsStatus, 30000); // Retry after 30sec
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error);
      sessionStorage.setItem('syncAssetsLoading', false);
      setCheckLocalStorage(!checkLocalStorage)
    }
  };
  
  return (
    <FileSyncContext.Provider value={{ checkSyncControlPackageStatus, checkLocalStorage, checkSyncAssetsStatus}}>
      
      {failedData?.length > 0 &&
        <PopupWithClose closePopup={setClosePopup}>
          <p className='text-xl font-semibold'>Data failed to sync into control {failedData?.length > 1 ? 'packages' : 'package'}</p>
          <p className='text-sm text-gray-600 font-sm mb-6' >{`( Failed to sync due to missing asset files for electric/pneumatic schematics. )`}</p>
          <ul className='flex flex-wrap text-nowrap gap-4'>
            {failedData.map((model_no, index) => (
              <li key={index}>{model_no}</li>
            ))}
          </ul>
        </PopupWithClose>
      }
      {children}
    </FileSyncContext.Provider>
  );
};

export { FileSyncProvider };

export default FileSyncContext